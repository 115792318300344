import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import { VueCookieNext } from "vue-cookie-next";
import VueGtag from "vue-gtag";

import FontAwesomeIcon from "./fontawesome-icons";

try
{
    console.log(`App version - ${process.env.VUE_APP_VERSION}`);
    console.log(`Build - ${process.env.VUE_APP_BUILD_NUMBER}`);
}
catch (e)
{
    console.error(e);
}

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .use(store)
    .use(VueCookieNext)
    .use(VueGtag, {
        config: {
            id: "G-FXNC85Y18K",
            params: {
                anonymize_ip: true
            }
        },
        enabled: false
    }, router);

app.provide('gtag', app.config.globalProperties.$gtag);

app.mount('#app')