export default class PointAndClickScene
{
    constructor(options)
    {
        options = options ?? {};

        this.id = options.id;
        this.startLocation = options.startLocation || Object.keys(options.locations)[0];
        //Sometimes you'll want to start a scene in the same location the previous scene ended 
        //(like going from collect_items to find_recipient)
        this.startInPreviousSceneLocation = options.startInPreviousSceneLocation || false;
        this.showPhoneButton = options.showPhoneButton || false;
        this.locations = options.locations;
        this.type = options.type;
    }
}