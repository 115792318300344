import CaseFile from "@/models/CaseFile";
import GameScore from "@/models/GameScore";
import Constants from "@/config/constants";

import {
    Andreas, Carlos,
    Zoro003Amina, Zoro003An,
    Zoro003Andreas, Zoro003Bas,
    Zoro003Carlos, Zoro003Christien,
    Zoro003Erica, Zoro003Esther, Zoro003Francesco,
    Zoro003Jack,
    Zoro003Josefien
} from "@/assets/data/people/people";

import {
    FrontDoorSticker, Hometrainer,
    MagicTable, MeditationHeadband, Radio,
    RobotCat, RobotFlowerPot, SleepHeadset, SmartSpeaker, VRGlasses
} from "@/assets/data/technological_aids/technological_aids";
import { Zoro003MeetingRoom } from "@/assets/data/pointandclick/scenes/ZORO-003/ZORO-003-meeting_room";
import { Zoro003InitialHome } from "@/assets/data/pointandclick/scenes/ZORO-003/ZORO-003-initial_home";
import { Zoro003IntroFirstTalkRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-003/intro/ZORO-003-intro-first_talk_recipient";
import { Zoro003IntroRoomTalkRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-003/intro/ZORO-003-intro-room_talk_recipient";
import { Zoro003IntroCollectItemsScene } from "@/assets/data/pointandclick/scenes/ZORO-003/intro/ZORO-003-intro-collect_items";
import { Zoro003IntroFindRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-003/intro/ZORO-003-intro-find_recipient";
import { Zoro003GoalSeekerScene } from "@/assets/data/pointandclick/scenes/ZORO-003/intro/ZORO-003-goal_seeker";
import {
    CareTeamEthicalCollectionItem, CatRobotEthicalCollectionItem,
    FlowersGiftCollectionItem,
    MiningLampCollectionItem,
    PhotoCatCollectionItem,
    PhotoMinersCollectionItem,
    PosterIsolationEthicalCollectionItem,
    PosterPlannerCollectionItem,
    StickerDoorCollectionItem
} from "@/assets/data/items/collection_items-ZORO-003";
import { Zoro003FollowupFirstTalkRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-003/followup/ZORO-003-followup-first_talk_recipient";
import { Zoro003FollowupRoomTalkRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-003/followup/ZORO-003-followup-room_talk_recipient";
import { Zoro003FollowupCollectItemsScene } from "@/assets/data/pointandclick/scenes/ZORO-003/followup/ZORO-003-followup-collect_items";
import { Zoro003FollowupFindRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-003/followup/ZORO-003-followup-find_recipient";
import { Zoro003FollowupRoomTalkRecipientCarlosScene } from "@/assets/data/pointandclick/scenes/ZORO-003/followup/ZORO-003-followup-room_talk_recipient_carlos";

export const casefile = new CaseFile({
// export default new CaseFile({
    id: "ZORO-003",
    enabled: true,
    version: "0.4.0",
    caseInfo: "Mijn naam is Carlos, 86 jaar. Ik woon sinds 4 jaar in het woonzorgcentrum na een diagnose van dementie. Sinds de dood van mijn vrouw ontfermt mijn kleinzoon, Andreas, zich over mij. Ik ervaar de laatste tijd veel onrust.",
    caseSetting: "Woonzorgcentrum",
    recipient: {
        id: "carlos",
        firstname: "Carlos",
        lastname: "",
        age: 86,
        backstory: "Carlos is 86 jaar, heeft 5 kinderen, 14 kleinkinderen en 4 achterkleinkinderen. Carlos woont sinds 4 jaar in het woonzorgcentrum. Hij maakte de keuze van verhuizen samen met zijn vrouw en in overleg met zijn kinderen. Tijdens de vier jaar dat Carlos in woonzorgcentrum ‘Zorgborg’ woont, werd dementie geconstateerd. De zorgverleners schatten in dat Carlos zich in de fase van matige dementie bevindt op dit moment.\n" +
            "\n" +
            "Anderhalf jaar geleden is zijn vrouw gestorven. Hij ziet zijn kinderen en kleinkinderen minderen dan vroeger, maar toch blijft zijn familie de grootste trots in zijn leven. Hoewel Carlos perfect Nederlands praat, is hij in Spanje geboren. In de jaren ‘60 kwam Carlos naar hier om als jongeman in de mijnen te werken.\n" +
            "In de weinige momenten dat Carlos nog ietwat helder is en praat, vertelt hij over het mooie Spanje, zijn familie en zijn kat Fernando... Soms vervalt hij in zijn moedertaal, Spaans, als de zorgverlener hem tijdens de ochtendzorg een vraag stelt.\n" +
            "\n" +
            "Carlos is sinds het overlijden van zijn vrouw erg onrustig geworden. Na het overlijden van zijn vrouw ontfermde Andreas, één van de kleinzonen zich om Carlos. Andreas geeft zelf les aan de opleiding Zorgkundige/Verzorgende en voelt zich nauw betrokken bij het woon- en leefproces van zijn grootvader. Als casemanager wil je graag op zoek naar een manier om hem meer tot rust te brengen.",
        misc: {
            profession: "Gepensioneerd mijnwerker",
            partner: {
                name: "Weduwnaar"
            },
            childCount: 4,
            grandchildCount: 14
        }
    },
    team: [
        Zoro003Carlos,
        Zoro003Bas,
        Zoro003Esther,
        Zoro003Francesco,
        Zoro003Josefien,
        Zoro003Amina,
        Zoro003Andreas,
        Zoro003Christien,
        Zoro003An,
        Zoro003Erica,
        Zoro003Jack
    ],
    technologicalAids: [
        RobotCat,
        MagicTable,
        RobotFlowerPot,
        SleepHeadset,
        MeditationHeadband,
        SmartSpeaker,
        FrontDoorSticker,
        Radio,
        Hometrainer,
        VRGlasses
    ],
    steps: [
        {
            id: "intro",
            name: "Kennismaking en zorgvraag cliënt",
            includeTodo: true,
            documents: [],
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Je kan de zorgvrager bezoeken via jouw afspraak in de 'Agenda' app of via jouw 'Contacten'",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "agenda",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_calendar.png"),
                                title: "Agenda"
                            },
                            body: {
                                title: "Afspraak vandaag",
                                description: "Kennismaking met Carlos en zorgvraag bepalen."
                            },
                            callToAction: {
                                type: "location",
                                text: "Bezoek Carlos",
                                action: "visit_recipient"
                            },
                            idleTime: 10000,
                            autoDisplay: 20000
                        }
                    },
                    actions: [
                        {
                            action: "visit_recipient",
                            phase: "heading_to_recipient"
                        }
                    ],
                    triggers: []
                },
                {
                    id: "heading_to_recipient",
                    view: "gps",
                    title: "GPS",
                    properties: {},
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "initial_home"
                        }
                    ]
                },
                {
                    id: "initial_home",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ],
                            hints: [
                                {
                                    text: "Je bent  aangekomen bij je cliënt. Klop op de deur.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        scene: Zoro003InitialHome,
                    },
                    actions: [
                        {
                            action: "knock_room_door",
                            phase: "initial_home_waiting"
                        }
                    ]
                },
                {
                    id: "initial_home_waiting",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        scene: Zoro003InitialHome,
                    },
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "first_talk_recipient"
                        }
                    ]
                },
                {
                    id: "first_talk_recipient",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ],
                            hints: [
                                {
                                    text: "Praat met Andreas.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        scene: Zoro003IntroFirstTalkRecipientScene
                    },
                    actions: [
                        {
                            action: "talk_with_recipient",
                            phase: "secondary_talk_recipient"
                        }
                    ]
                },
                {
                    id: "secondary_talk_recipient",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Hallo Carlos en Andreas! Ik heb vandaag een afspraak met jullie. Ik ben <player.firstname>, de casemanager van Carlos. Ik kom eens luisteren wat ik voor jou kan betekenen."
                            },
                            {
                                speaker: Andreas.id,
                                text: "Welkom <player.firstname>, kom binnen. Wij gaan even koffie halen. Kijk maar even rond, dan leer je mijn grootvader al beter kennen."
                            }
                        ],
                        scene: Zoro003IntroRoomTalkRecipientScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "collect_items"
                        }
                    ]
                },
                {
                    id: "collect_items",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        scene: Zoro003IntroCollectItemsScene
                    },
                    actions: [
                        {
                            action: "collect_item"
                        },
                        {
                            action: "move_location"
                        }
                    ],
                    triggers: [
                        {
                            trigger: "all_items_collected",
                            phase: "find_recipient"
                        }
                    ]
                },
                {
                    id: "find_recipient",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ],
                            hints: [
                                {
                                    text: "Praat met Andreas.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "sms",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_sms.png"),
                                title: "SMS"
                            },
                            body: {
                                title: "Andreas:",
                                description: "We zijn terug op de kamer."
                            },
                            callToAction: {
                                text: "OK"
                            }
                        },
                        scene: Zoro003IntroFindRecipientScene
                    },
                    actions: [
                        {
                            action: "talk_with_recipient",
                            phase: "goal_seeker_prep_talk"
                        }
                    ]
                },
                {
                    id: "goal_seeker_prep_talk",
                    phase: "dialog",
                    view: 'free_roam',
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Bedankt dat ik even mocht rondkijken. We gaan nu een doelzoeker opstellen. Via deze methode leer ik Carlos beter kennen en ontdekken we wat op dit moment Carlos’ levensdoelen zijn."
                            }
                        ],
                        scene: Zoro003IntroRoomTalkRecipientScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "goal_seeker"
                        }
                    ]
                },
                {
                    id: "goal_seeker",
                    phase: "goal_seeker",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        scene: Zoro003GoalSeekerScene
                    },
                    actions: [
                        {
                            action: "click_past",
                            phase: "goal_seeker_past"
                        },
                        {
                            action: "click_present",
                            phase: "goal_seeker_present"
                        },
                        {
                            action: "click_future",
                            phase: "goal_seeker_future"
                        }
                    ],
                    triggers: [
                        {
                            trigger: "goal_seeker_completed",
                            phase: "goal_seeker_wrap_up_talk"
                        }
                    ]
                },
                {
                    id: "goal_seeker_past",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Welke dingen waren bepalend in het leven van Carlos?"
                            },
                            {
                                speaker: Andreas.id,
                                text: "Carlos kwam als jongeman van Spanje naar hier om een nieuw leven te starten. Hij werkte hard in de mijnen om een toekomst uit te bouwen voor zijn toen nog jonge gezin. Carlos was en is een echte dierenliefhebber. In het woonzorgcentrum zijn katten niet toegelaten."
                            }
                        ],
                        scene: Zoro003GoalSeekerScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "goal_seeker"
                        }
                    ]
                },
                {
                    id: "goal_seeker_present",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Hoe verloopt zijn verblijf in het woonzorgcentrum?"
                            },
                            {
                                speaker: Andreas.id,
                                text: "Dankzij de goede zorgen van het zorgteam en mantelzorgers voelt Carlos zich thuis in het woonzorgcentrum. Door zijn dementie ervaart hij vaak onrust. Een zorgplan op maat is nodig."
                            }
                        ],
                        scene: Zoro003GoalSeekerScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "goal_seeker"
                        }
                    ]
                },
                {
                    id: "goal_seeker_future",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Welke waarden en normen zijn belangrijk in het leven van Carlos?"
                            },
                            {
                                speaker: Andreas.id,
                                text: "Carlos is heel trots op zijn familie en uit zijn dankbaarheid voor de goede zorgen regelmatig."
                            }
                        ],
                        scene: Zoro003GoalSeekerScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "goal_seeker"
                        }
                    ]
                },
                {
                    id: "goal_seeker_wrap_up_talk",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ],
                        },
                        dialog: [
                            {
                                speaker: Andreas.id,
                                text: "Wauw, de doelzoeker heeft ons veel meer inzicht gegeven in het verleden, het heden en de toekomst van Carlos. Dankzij de doelzoeker ben ik verder gaan nadenken over de levenskwaliteit van mijn grootvader."
                            }
                        ],
                        scene: Zoro003GoalSeekerScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "step_wrap_up_talk"
                        }
                    ]
                },
                {
                    id: "step_wrap_up_talk",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Begrijp ik het goed dat de zorgvraag de volgende is: Hoe kunnen we de onrust bij Carlos wegnemen en hem laten genieten van het hier en nu?"
                            },
                            {
                                speaker: Andreas.id,
                                text: "Ja, dat klopt <player.firstname>."
                            },
                            {
                                speaker: "player",
                                text: "Oké, bedankt voor alle informatie. Ik ga meteen aan de slag en hou jullie op de hoogte. Bedankt voor jullie tijd en tot ziens!"
                            }
                        ],
                        scene: Zoro003IntroRoomTalkRecipientScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "conclusion"
                        }
                    ]
                },
                {
                    id: "conclusion",
                    phase: "conclusion",
                    view: "free_roam",
                    properties: {
                        conclusion: {
                            title: "1. Kennismaking en zorgvraag cliënt",
                            description: "<p>Je hebt het eerste deel van je zorgplan ingevuld. Je hebt de<strong> zorgontvanger beter leren kennen</strong>.</p>\n" +
                                "<p>De<strong> doelzoeker</strong> helpt de zorgontvanger om na te denken over wat belangrijk is in het leven en wat levenskwaliteit biedt. Het helpt om concrete <strong>levensdoelen</strong> te formuleren die de zorgontvanger kan gebruiken in gesprek met zorgverleners. De doelzoeker werd ontwikkeld door het<strong> Vlaams Pati&euml;ntenplatform</strong>. Meer info staat op de gsm onder Info.</p>\n" +
                                "<p>Je <strong>onderzocht en signaleerde</strong> een <strong>zorgvraag</strong>. Nu weet je wat de zorgontvanger wil bereiken.</p>",
                            infoKeys: [ 'background-info', 'care-question', 'score-intra' ],
                            buttonText: "Bekijk dossier"
                        },
                        scene: Zoro003IntroRoomTalkRecipientScene
                    },
                    actions: [
                        {
                            action: "next_step",
                            step: "assembly"
                        }
                    ],
                }
            ],
            points: new GameScore({
                intrapreneurship: 3
            }),
            fixedPoints: new GameScore({
                intrapreneurship: 3
            })
        },
        {
            id: "assembly",
            name: "Zorgteam samenstellen",
            includeTodo: true,
            documents: [],
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Stel een zorgteam samen door naar je Contacten te gaan en kies voor 'Zorgteam aanmaken'",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "agenda",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_calendar.png"),
                                title: "Agenda"
                            },
                            body: {
                                title: "Reminder",
                                description: "Stel een zorgteam samen voor Carlos."
                            },
                            callToAction: {
                                text: "Zorgteam samenstellen",
                                action: "click_assembly"
                            },
                            idleTime: 10000,
                            autoDisplay: 20000
                        }
                    },
                    actions: [
                        {
                            action: "click_assembly",
                            phase: "contact_assembly"
                        }
                    ],
                    triggers: []
                },
                {
                    id: "contact_assembly",
                    view: "contacts",
                    title: "Contacten",
                    properties: {
                        meeting_type: "offline"
                    },
                    actions: [
                        {
                            action: "start_meeting",
                            phase: "heading_to_meeting"
                        }
                    ]
                },
                {
                    id: "heading_to_meeting",
                    view: "sending_team_invite",
                    title: "Agenda",
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "meeting"
                        }
                    ]
                },
                {
                    id: "meeting",
                    view: "meeting",
                    properties: {
                        meeting_type: "offline",
                        scene: Zoro003MeetingRoom,
                        initial_dialog_wrong: "Hallo allemaal, ik heb jullie samengebracht als zorgteam voor Carlos. Ik wil even afstemmen of jullie je hierin kunnen vinden?",
                        initial_dialog_correct: "Wat bevelen jullie aan voor Carlos vanuit jullie context? En Carlos wat denk jij over deze aanbevelingen?",
                        negativeFeedback: [
                            "Ik denk dat ik voor deze case weinig zal kunnen betekenen.",
                            "Misschien dat een ander type zorgverlener is aangewezen.",
                            "Sorry, ik kan me hierin niet vinden.",
                            "Ik kan weinig professionele meerwaarde bieden aan dit team.",
                            "Sorry, ik vrees dat ik hier weinig input zal kunnen bieden.",
                            "Mijn excuses, maar ik denk dat ik hier niet op mijn plaats zit.",
                            "Ik ben ervan overtuigd dat anderen hier meer van weten.",
                            "Bedankt voor de uitnodiging, maar hierbij kan ik je echt niet verderhelpen.",
                            "Geen idee hoe je bij mij komt. Ik ben niet de juiste persoon.",
                            "Deze vraag is zeer interessant, maar volledig buiten mijn expertisegebied.",
                            "Dit is niets voor mij. Veel succes met het zoeken naar een andere zorgverlener."
                        ],
                        positiveFeedback: [
                            "Ik kan me hierin wel vinden.",
                            "Lijkt me logisch dat je me hebt toegevoegd.",
                            "Oké voor mij.",
                            "Ja, hoor.",
                            "Ik wil absoluut helpen nadenken over een gepaste oplossing voor Carlos.",
                            "Bedankt voor de uitnodiging. Ik kijk ernaar uit om samen te werken.",
                            "Dit is helemaal iets voor mij.",
                            "Deze vraag is interessant en past binnen mijn expertisegebied.",
                            "Super, ik doe mee.",
                            "Ik ga de uitdaging aan."
                        ],
                        missingRecipient: "Het is misschien een goed idee om de zorgontvanger hier ook bij te betrekken. Heb je er al aan gedacht om Carlos uit te nodigen voor dit gesprek?",
                        recommendations: [
                            {
                                person: Zoro003Carlos,
                                recommendation: 'Amo a mi familia. Fernando, waar ben je ?'
                            },
                            {
                                person: Zoro003Andreas,
                                recommendation: 'Ik probeer dagelijks op bezoek te gaan bij mijn grootvader. Ik kan niet altijd aanwezig zijn en het is juist tijdens deze momenten van afwezigheid dat hij onrustig wordt.'
                            },
                            {
                                person: Zoro003Josefien,
                                recommendation: 'Uit de gesprekken die ik de afgelopen jaren had met Carlos, weet ik dat hij zeer veel belang hecht aan zijn familie. Hoewel het niet evident is om hem bij dit proces te betrekken, is hij toch de belangrijkste schakel.'
                            },
                            {
                                person: Zoro003Erica,
                                recommendation: 'Vanuit de fase van matige dementie is het belangrijk om het langetermijngeheugen  van de zorgontvanger te prikkelen. Daar zie ik zeker mogelijkheden voor.'
                            },
                            {
                                person: Zoro003Jack,
                                recommendation: 'Ik kan op een later tijdstip mee helpen nadenken over het juiste technologische hulpmiddel. Ik zie heel wat mogelijkheden voor de zorgvraag van Carlos.'
                            },
                            {
                                person: Zoro003Esther,
                                recommendation: 'Als ik de zorgvraag van Carlos hoor, denk ik dat de informatie die ik gekregen heb tijdens de ZORO-training wel van nut zou kunnen zijn. Ik heb hier geleerd om gepaste technologische hulpmiddelen te vinden voor allerlei situaties. Ik stuur je de link met informatie door.',
                                mentionZoroWebsiteLink: true
                            }
                        ]
                    },
                    actions: [
                        {
                            action: "make_correction",
                            phase: "contact_assembly"
                        },
                        {
                            action: "finish_meeting",
                            phase: "conclusion"
                        }
                    ]
                },
                {
                    id: "conclusion",
                    phase: "conclusion",
                    view: "meeting",
                    properties: {
                        scene: Zoro003MeetingRoom,
                        conclusion: {
                            title: "2. Stel een zorgteam samen",
                            description: "<p>Een team kies je op basis van de noden van je zorgontvanger. <strong>Wie kan er bij deze vraag</strong> <strong>het beste helpen?</strong> Dankzij het teamoverleg ga je in <strong>overleg</strong> en werk je<strong> samen</strong> met de <strong>verschillende disciplines</strong> en de <strong>zorgontvanger</strong> aan een <strong>gemeenschappelijk doel</strong>. Dit zorgt ervoor dat je echt kwalitatieve zorg op maat kan bieden!</p>",
                            infoKeys: [ 'care-team-added', 'recommendation-added', 'score-intra', 'score-is' ],
                            buttonText: "Bekijk dossier"
                        }
                    },
                    actions: [
                        {
                            action: "next_step",
                            step: "technology"
                        }
                    ]
                }
            ],
            points: new GameScore({
                intrapreneurship: 3,
                interprofessionalCollaboration: 15
            }),
            fixedPoints: new GameScore({
                intrapreneurship: 3,
                interprofessionalCollaboration: 15
            })
        },
        {
            id: "technology",
            name: "Technologische hulpmiddelen",
            includeTodo: true,
            documents: [],
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Bekijk de link met aanbevelingen van het zorgteam voor technologische hulpmiddelen in 'Bestanden'.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "files",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_files.png"),
                                title: "Bestanden"
                            },
                            body: {
                                title: "Reminder",
                                description: "Zoek een technologische oplossing voor Carlos. Zie 'Aanbevelingen'."
                            },
                            callToAction: {
                                type: "files",
                                text: "Open bestanden",
                                action: "click_files"
                            },
                            idleTime: 10000,
                            autoDisplay: 20000
                        }
                    },
                    actions: [
                        {
                            action: "click_files",
                            phase: "view_files"
                        }
                    ]
                },
                {
                    id: "view_files",
                    view: "documents",
                    title: "Bestanden",
                    properties: {
                        documents: [
                            "recommendations"
                        ],
                        selectedDocument: "recommendations"
                    },
                    actions: [
                        {
                            action: "click_website_link",
                            phase: "website_view"
                        }
                    ]
                },
                {
                    id: "website_view",
                    view: "website_view",
                    title: "https://www.hulpmiddelen.com",
                    titleType: "url",
                    properties: {
                        meeting_type: "offline"
                    },
                    actions: [
                        {
                            action: "start_meeting",
                            phase: "heading_to_meeting"
                        }
                    ]
                },
                {
                    id: "heading_to_meeting",
                    view: "sending_team_invite",
                    title: "Agenda",
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "meeting"
                        }
                    ]
                },
                {
                    id: "meeting",
                    view: "meeting",
                    properties: {
                        meeting_type: "offline",
                        scene: Zoro003MeetingRoom,
                        initial_dialog: "Dag iedereen, ik werd deze ochtend ingelicht dat Carlos vandaag een heldere dag heeft en actief wil meedenken in dit overleg. Wat vinden jullie van deze selectie hulpmiddelen voor Carlos?",
                        technologicalAidsResponses: [
                            {
                                technologicalAid: RobotCat,
                                questioner: Zoro003Erica,
                                question: "Zal je met deze snoezelkat terugdenken aan jouw eigen kat en zo rustiger zijn?",
                                answer: "Ik denk het wel. Ik ben altijd rustiger als er een kat in de buurt is. Het doet me terugdenken aan Fernando."
                            },
                            {
                                technologicalAid: MagicTable,
                                questioner: Zoro003Jack,
                                question: "Zal je begrijpen wat de bedoeling is van de tovertafel?",
                                answer: "Dat zouden we eens moeten testen. Ik weet niet of ik lang mijn aandacht zou kunnen houden bij de tovertafel. Ik was en ben niet echt een spelletjesspeler."
                            },
                            {
                                technologicalAid: RobotFlowerPot,
                                questioner: Zoro003Esther,
                                question: "Denk je dat jij graag voor het plantje zal zorgen en dat het wat structuur kan brengen?",
                                answer: "Ik heb niet echt groene vingers en de extra tools interesseren mij niet. Ik denk niet dat dit me zal helpen."
                            },
                            {
                                technologicalAid: SleepHeadset,
                                questioner: Zoro003Josefien,
                                question: "Denk je dat je hiermee zou kunnen slapen?",
                                answer: "Ik slaap op mijn zij of op mijn buik. Het lijkt me niet zo handig om met een hoofdtelefoon op je zij te slapen. Ik denk dat ik de hoofdtelefoon snel zal afzetten."
                            },
                            {
                                technologicalAid: MeditationHeadband,
                                questioner: Zoro003Erica,
                                question: "Zou je zo’n band verdragen, denk je?",
                                answer: "Ik weet het niet goed. De meditatieoefeningen zullen misschien te langdradig zijn."
                            },
                            {
                                technologicalAid: SmartSpeaker,
                                questioner: Zoro003Jack,
                                question: "Zou jij een slimme speaker kunnen aansturen?",
                                answer: "Ik denk het niet. Ik zal dit niet begrijpen of terug vergeten wat de slimme speaker allemaal kan."
                            },
                            {
                                technologicalAid: FrontDoorSticker,
                                questioner: Zoro003Esther,
                                question: "Kan dit helpend zijn om rust te creëren voor jou, Carlos?",
                                answer: "Dit is een leuk idee, maar ik heb al zo’n voordeursticker. Hierdoor vind ik mijn kamer sneller terug. Dit is geen oplossing voor de onrust die ik soms ervaar."
                            },
                            {
                                technologicalAid: Radio,
                                questioner: Zoro003Josefien,
                                question: "Kan muziek jou tot rust brengen, Carlos?",
                                answer: "Misschien wel, maar in elke kamer is er al een radio met verschillende zenders, toch?"
                            },
                            {
                                technologicalAid: Hometrainer,
                                questioner: Zoro003Jack,
                                question: "Zou je dit graag doen en zou jij dat nog kunnen? Fietsen op een hometrainer?",
                                answer: "Als het beelden van Spanje zijn, dan zal dit wel voor afleiding zorgen. Ik denk dat het fietsen zelf moeilijk is voor mij. Ik ben geen sporter."
                            },
                            {
                                technologicalAid: VRGlasses,
                                questioner: Zoro003Josefien,
                                question: "Hoe denk je dat jij hierop zou reageren?",
                                answer: "Het zegt me weinig. Ik heb schrik dat dit me snel zal irriteren en ik wil deze dure apparatuur niet beschadigen."
                            }
                        ],
                        closing_dialog: [
                            {
                                person: Zoro003Josefien,
                                text: "Het zal Carlos aan zijn eigen katten herinneren en hopelijk voldoende rust creëren."
                            },
                            {
                                person: Zoro003Erica,
                                text: "Het effect zal volgens mij heel positief zijn. We vonden gelukkig een snoezelkat die zeer goed lijkt op Fernando!"
                            },
                            {
                                person: Zoro003Jack,
                                text: "Ik vind het fijn dat de snoezelkat Carlos zal activeren: zorgen voor de kat, er tegen praten,… Carlos zal terug sociale interactie krijgen doordat andere zorgontvangers de kat zien."
                            },
                            {
                                person: Zoro003Esther,
                                text: "Goed dat er een hulpmiddel is gevonden dat qua technologie eenvoudig is. De kans op een succeservaring stijgt hierdoor volgens mij."
                            },
                            {
                                person: Zoro003Andreas,
                                text: "Ik ben tevreden over de gekozen oplossing. Het zal mijn grootvader zijn gedachten afleiden en ervoor zorgen dat hij minder onrustig is."
                            },
                            {
                                person: Zoro003Carlos,
                                text: "Ik kijk uit naar mijn nieuwe vriend!"
                            }
                        ]
                    },
                    actions: [
                        {
                            action: "make_correction",
                            phase: "website_view"
                        },
                        {
                            action: "finish_meeting",
                            phase: "conclusion"
                        }
                    ]
                },
                {
                    id: "conclusion",
                    phase: "conclusion",
                    view: "meeting",
                    properties: {
                        scene: Zoro003MeetingRoom,
                        conclusion: {
                            title: "3. Technologische hulpmiddelen",
                            description: "<p>Bij het zoeken naar <strong>geschikte zorgtechnologie</strong> is het belangrijk om de gebruiker van de technologie goed te <strong>bevragen</strong>. Die moet immers het voordeel zien en de technologie willen gebruiken. Als zorgverlener kan je je mening geven, maar de <strong>eindbeslissing ligt steeds bij de zorgontvanger.</strong></p>\n" +
                                "<p>Je hebt <strong>kritisch geluisterd</strong> naar het zorgteam en de wensen van de zorgontvanger. Zo kwam je bij een <strong>zorgoplossing op maat</strong>. De zorgontvanger zelf heeft ook hier steeds een belangrijke rol!</p>",
                            infoKeys: [ 'tech-added', 'score-tw', 'score-intra', 'score-is' ],
                            buttonText: "Bekijk dossier"
                        }
                    },
                    actions: [
                        {
                            action: "next_step",
                            step: "followup"
                        }
                    ]
                }
            ],
            points: new GameScore({
                technicalManeuverability: 15,
                intrapreneurship: 3,
                interprofessionalCollaboration: 3
            }),
            fixedPoints: new GameScore({
                technicalManeuverability: 15,
                intrapreneurship: 3,
                interprofessionalCollaboration: 3
            })
        },
        {
            id: "followup",
            name: "Follow up & ethiek",
            includeTodo: true,
            documents: [
                'recommendations'
            ],
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Bezoek je cliënt voor een follow-up.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "agenda",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_calendar.png"),
                                title: "Agenda"
                            },
                            body: {
                                title: "Reminder",
                                description: "Follow up - Bezoek Carlos en bekijk ethische aspect."
                            },
                            callToAction: {
                                type: "location",
                                text: "Bezoek Carlos",
                                action: "visit_recipient"
                            },
                            idleTime: 10000,
                            autoDisplay: 20000
                        }
                    },
                    actions: [
                        {
                            action: "visit_recipient",
                            phase: "heading_to_recipient"
                        }
                    ]
                },
                {
                    id: "heading_to_recipient",
                    view: "gps",
                    title: "GPS",
                    properties: {},
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "initial_home"
                        }
                    ]
                },
                {
                    id: "initial_home",
                    view: "free_roam",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Je bent  aangekomen bij je cliënt. Klop op de deur.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        scene: Zoro003InitialHome,
                    },
                    actions: [
                        {
                            action: "knock_room_door",
                            phase: "initial_home_waiting"
                        }
                    ]
                },
                {
                    id: "initial_home_waiting",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        scene: Zoro003InitialHome,
                    },
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "first_talk_recipient_carlos"
                        }
                    ]
                },
                {
                    id: "first_talk_recipient_carlos",
                    view: "free_roam",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Praat met Andreas.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        scene: Zoro003FollowupFirstTalkRecipientScene
                    },
                    actions: [
                        {
                            action: "talk_with_recipient",
                            phase: "secondary_talk_recipient_carlos"
                        }
                    ]
                },
                {
                    id: "secondary_talk_recipient_carlos",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        dialog: [
                            {
                                speaker: "player",
                                text: "Hallo, Carlos! Hoe gaat het met de snoezelkat? Ervaar je meer rust?"
                            },
                            {
                                speaker: Carlos.id,
                                text: "Kijk <player.firstname>, ik ben zo blij met mijn nieuwe kat!"
                            }
                        ],
                        scene: Zoro003FollowupRoomTalkRecipientCarlosScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "first_talk_recipient_andreas"
                        }
                    ]
                },
                {
                    id: "first_talk_recipient_andreas",
                    view: "free_roam",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Praat met Andreas.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        scene: Zoro003FollowupFirstTalkRecipientScene
                    },
                    actions: [
                        {
                            action: "talk_with_recipient",
                            phase: "secondary_talk_recipient_andreas"
                        }
                    ]
                },
                {
                    id: "secondary_talk_recipient_andreas",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        dialog: [
                            {
                                speaker: "player",
                                text: "Hallo, Andreas! Hoe gaat het met Carlos ondertussen?"
                            },
                            {
                                speaker: Andreas.id,
                                text: "Hoi <player.firstname>, met Carlos gaat het goed. Hij is heel wat rustiger met de oplossing die jullie hebben voorgesteld."
                            },
                            {
                                speaker: "player",
                                text: "Ik wil even rondkijken, om het zorgplan te vervolledigen, als je het niet erg vindt?"
                            },
                            {
                                speaker: Andreas.id,
                                text: "Kijk gerust rond in zijn kamer, geen probleem. We gaan ondertussen even naar de cafetaria om iets te drinken te halen."
                            }
                        ],
                        scene: Zoro003FollowupRoomTalkRecipientScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "collect_items"
                        }
                    ]
                },
                {
                    id: "collect_items",
                    view: "free_roam",
                    properties: {
                        scene: Zoro003FollowupCollectItemsScene
                    },
                    actions: [
                        {
                            action: "collect_item"
                        },
                        {
                            action: "move_location"
                        }
                    ],
                    triggers: [
                        {
                            trigger: "all_items_collected",
                            phase: "find_recipient"
                        }
                    ]
                },
                {
                    id: "find_recipient",
                    view: "free_roam",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Praat met Andreas.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "sms",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_sms.png"),
                                title: "SMS"
                            },
                            body: {
                                title: "Andreas:",
                                description: "We zijn terug op de kamer."
                            },
                            callToAction: {
                                text: "OK"
                            }
                        },
                        scene: Zoro003FollowupFindRecipientScene
                    },
                    actions: [
                        {
                            action: "talk_with_recipient",
                            phase: "talk_about_items"
                        }
                    ]
                },
                {
                    id: "talk_about_items",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        dialog: [
                            {
                                speaker: "player",
                                text: "Zo, Carlos en Andreas. Bedankt voor alle informatie. Ik bezorg jullie en de rest van het zorgteam zo snel mogelijk het zorgplan. Hou jullie goed en tot ziens!"
                            },
                            {
                                speaker: Carlos.id,
                                text: "Hartelijk dank voor al je werk, <player.firstname>. Tot ziens!"
                            }
                        ],
                        scene: Zoro003FollowupRoomTalkRecipientCarlosScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "conclusion"
                        }
                    ]
                },
                {
                    id: "conclusion",
                    phase: "conclusion",
                    view: "free_roam",
                    properties: {
                        conclusion: {
                            title: "4. Follow up en ethiek",
                            description: "<p>Ethisch handelen gaat niet (enkel) over grote ethische vraagstukken, maar om het streven naar <strong>het goede in de alledaagse zorg</strong>. Het gaat om<strong> zorg die deugd doet aan beide kanten van de zorgrelatie</strong>, want alleen door een <strong>betrokken</strong> relatie met de zorgontvanger aan te gaan, kan je ontdekken wat goede zorg voor hem of haar betekent.&nbsp;</p>\n" +
                                "<p>Je hebt je verbeterplan <strong>omgezet in een actie</strong>! En de zorgontvanger is tevreden.</p>",
                            infoKeys: [ 'eh-added', 'score-intra', 'score-eh' ],
                            buttonText: "Bekijk dossier"
                        },
                        scene: Zoro003FollowupRoomTalkRecipientCarlosScene
                    },
                    actions: [
                        {
                            action: "next_step",
                            step: "plan"
                        }
                    ]
                }
            ],
            points: new GameScore({
                intrapreneurship: 3,
                actingEthically: 15
            }),
            fixedPoints: new GameScore({
                intrapreneurship: 3,
                actingEthically: 15
            })
        },
        {
            id: "plan",
            name: "Zorgplan opleveren",
            includeTodo: true,
            documents: [
                'recommendations'
            ],
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Je hebt je zorgplan opgesteld en opgeslagen in 'Bestanden'. Tijd om het op te sturen naar het zorgteam.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "files",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_files.png"),
                                title: "Bestanden"
                            },
                            body: {
                                title: "Reminder",
                                description: "E-mail het zorgplan naar het zorgteam en de zorgvrager."
                            },
                            callToAction: {
                                type: "files",
                                text: "Open bestanden",
                                action: "click_files"
                            },
                            idleTime: 10000,
                            autoDisplay: 20000
                        }
                    },
                    actions: [
                        {
                            action: "click_files",
                            phase: "view_files"
                        }
                    ]
                },
                {
                    id: "view_files",
                    view: "documents",
                    title: "Bestanden",
                    properties: {
                        documents: [
                            "recommendations",
                            "careplan"
                        ],
                        selectedDocument: "careplan"
                    },
                    actions: [
                        {
                            action: "email_files",
                            popup: "confirm_email"
                        },
                        {
                            action: "confirm_send_email",
                            phase: "sending_email"
                        }
                    ]
                },
                {
                    id: "sending_email",
                    view: "sending_email",
                    title: "E-Mail",
                    triggers: [
                        {
                            trigger: "load-step",
                            auto: true,
                            duration: 2000,
                            step: "game-end"
                        }
                    ]
                }
            ]
        },
        {
            id: "game-end",
            includeTodo: false,
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {},
                    triggers: [
                        {
                            trigger: "game-end",
                            finishGame: true,
                            auto: true
                        }
                    ]
                }
            ]
        }
    ],
    documents: [
        {
            id: "recommendations",
            name: "Aanbevelingen",
            icon: require('@/assets/images/UI/icons/icon_doctype_zoro.png'),
            care_question: "Hoe kunnen we de onrust bij Carlos wegnemen en hem laten genieten van het hier en nu?",
            recommendations: [
                {
                    person: Zoro003Andreas,
                    recommendation: 'Ik probeer dagelijks op bezoek te gaan bij mijn grootvader. Ik kan niet altijd aanwezig zijn en het is juist tijdens deze momenten van afwezigheid dat hij onrustig wordt.'
                },
                {
                    person: Zoro003Josefien,
                    recommendation: 'Uit de gesprekken die ik de afgelopen jaren had met Carlos, weet ik dat hij zeer veel belang hecht aan zijn familie. Hoewel het niet evident is om hem bij dit proces te betrekken, is hij toch de belangrijkste schakel.'
                },
                {
                    person: Zoro003Erica,
                    recommendation: 'Vanuit de fase van matige dementie is het belangrijk om het langetermijngeheugen  van de zorgontvanger te prikkelen. Daar zie ik zeker mogelijkheden voor.'
                },
                {
                    person: Zoro003Jack,
                    recommendation: 'Ik kan op een later tijdstip mee helpen nadenken over het juiste technologische hulpmiddel. Ik zie heel wat mogelijkheden voor de zorgvraag van Carlos.'
                },
                {
                    person: Zoro003Esther,
                    recommendation: 'Als ik de zorgvraag van Carlos hoor, denk ik dat de informatie die ik gekregen heb tijdens de ZORO-training wel van nut zou kunnen zijn. Ik heb hier geleerd om gepaste technologische hulpmiddelen te vinden voor allerlei situaties. Ik stuur je de link met informatie door.',
                },
                {
                    person: Zoro003Carlos,
                    recommendation: 'Ik begrijp dat Andreas niet altijd aanwezig kan zijn, een vertrouwd gezicht geeft mij rust.'
                }
            ]
        },
        {
            id: "careplan",
            name: "Zorgplan",
            icon: require('@/assets/images/UI/icons/icon_doctype_zoro.png'),
            pdf: require('@/assets/pdfs/zorgplan_03.pdf').default,
            images: [
                require('@/assets/pdfs/zorgplan_03_1.png'),
                require('@/assets/pdfs/zorgplan_03_2.png'),
                require('@/assets/pdfs/zorgplan_03_3.png')
            ],
            actions: [ "email", "download" ]
        }
    ],
    document: {
        situationInstructions: "Bezoek Carlos en kom te weten wat zijn situatie en zorgvraag is.<br>Ga op zoek naar 6 objecten om Carlos beter te leren kennen.",
        careInstructions: "Stel een zorgteam samen om Carlos zijn zorgvraag aan te pakken",
        techAidInstructions: "Adviseer een technologisch hulpmiddel voor Carlos op basis van zijn zorgvraag en aanbevelingen van het zorgteam",
        ethicalInstructions: "Ga langs bij Carlos voor een follow-upgesprek met betrekking tot zijn hulpmiddel. Misschien is dit een goed moment om ook het ethische aspect van zijn situatie te bekijken. Ga op zoek naar 3 ethische dilemma’s.",
        situation: "Carlos is 86 jaar en woont sinds 4 jaar in het woonzorgcentrum ‘Zorgborg’. Sinds de dood van Carlos’ vrouw, anderhalf jaar geleden, ontfermt Andreas, de 36-jarige kleinzoon, zich mee over de zorg- en woonbegeleiding van Carlos.<br>" +
            "<br>" +
            "Hij communiceert met de familie als er beslissingen gemaakt moeten worden, gaat in overleg met het zorgteam en neemt deel aan activiteiten.<br>" +
            "<br>" +
            "Er werd dementie vastgesteld. Carlos is vaker onrustig tijdens de zorg en de vrije momenten.",
        careTeam: [
            Zoro003Carlos,
            Zoro003Andreas,
            Zoro003Josefien,
            Zoro003Jack,
            Zoro003Erica,
            Zoro003Esther
        ],
        collectionItems: [
            PhotoCatCollectionItem,
            MiningLampCollectionItem,
            StickerDoorCollectionItem,
            PosterPlannerCollectionItem,
            PhotoMinersCollectionItem,
            FlowersGiftCollectionItem
        ],
        careDescription: "Carlos, 86 jaar, dementie, weduwnaar, onrustig, contactpersoon is kleinzoon.",
        careQuestion: "Hoe kunnen we de onrust bij Carlos wegnemen en hem laten genieten van het hier en nu?",
        technologicalAid: RobotCat,
        ethical: [
            {
                item: PosterIsolationEthicalCollectionItem,
                theorem: "Contact",
                theoremDescription: "<p>Carlos kan geen bezoek ontvangen gedurende 7 dagen omwille van een virale besmetting. Dit doet men voor de veiligheid van de medewerkers, medebewoners en het bezoek. Veel mensen met dementie vertonen tijdens zo’n periode van isolatie meer symptomen van onrust.</p>" +
                    "<p>Is het weigeren van bezoek noodzakelijk?</p>",
                feedbackYes: "<p>Het antwoord is niet eenduidig.</p>" +
                    "<p>Welke afweging zouden de zorgontvangers zelf maken: veiligheid of sociaal contact? Ben je bewust van de macht die je als zorgverlener hebt? Veiligheid is belangrijk, maar niet ‘alles’. Ga in overleg met zorgontvangers en hun netwerk.</p>",
                feedbackNo: "<p>Het antwoord is niet eenduidig.</p>" +
                    "<p>Welke afweging zouden de zorgontvangers zelf maken: veiligheid of sociaal contact? Ben je je bewust van de macht die je als zorgverlener hebt? Veiligheid is belangrijk, maar niet ‘alles’. Ga in overleg met zorgontvangers en hun netwerk.</p>",
                ethical: "Beide",
                correctAnswers: [ "yes", "no" ]
            },
            {
                item: CareTeamEthicalCollectionItem,
                theorem: "Zorgverleners",
                theoremDescription: "<p>Tijdens de ochtendzorg komen heel wat mensen bij Carlos binnenlopen. Op drie uur tijd komen zeven zorgverleners langs op negen verschillende momenten. Is dit noodzakelijk om goede zorg te bieden aan de zorgontvanger?</p>",
                feedbackYes: "<p>Je gaf een fout antwoord: deze stelling is ethisch niet verantwoord.</p>" +
                    "<p>Bij zorgontvangers met dementie moet je bewust nadenken over wie de zorg uitvoert. Tijdens het interprofessioneel overleg moet het zorgteam hierover in gesprek gaan en afspraken maken. Het kan een bewuste keuze zijn om één of twee vaste zorgverleners te koppelen aan een zorgontvanger.</p>",
                feedbackNo: "<p>Correct</p>" +
                    "<p>Bij zorgontvangers met dementie moet je bewust nadenken over wie de zorg uitvoert. Tijdens het interprofessioneel overleg moet het zorgteam hierover in gesprek gaan en afspraken maken. Het kan een bewuste keuze zijn om één of twee vaste zorgverleners te koppelen aan een zorgontvanger.</p>",
                ethical: "Nee",
                correctAnswers: [ "no" ]
            },
            {
                item: CatRobotEthicalCollectionItem,
                theorem: "Technologie in de zorg",
                theoremDescription: "<p>Robots in de zorg kunnen ontzettend veel: afspraken onthouden, een signaal geven voor medicatie-inname, ... De snoezelkat van Carlos helpt hem om rustiger te worden en zorgt voor sociale interactie.</p>" +
                    "<p>Moeten we blij zijn met de zorgrobots of juist niet?</p>",
                feedbackYes: "<p>Correct!</p>" +
                    "<p>Het is belangrijk om steeds te vertrekken vanuit het perspectief van de zorgontvanger. Er moet daarom goed nagedacht worden over de keuze van de robot en de blijvende afstemming met de zorgontvanger en de zorgvraag.</p>",
                feedbackNo: "<p>Je gaf een fout antwoord: deze stelling is ethisch verantwoord.</p>" +
                    "<p>Het is belangrijk om steeds te vertrekken vanuit het perspectief van de zorgontvanger. Er moet daarom goed nagedacht worden over de keuze van de robot en de blijvende afstemming met de zorgontvanger en de zorgvraag.</p>",
                ethical: "Ja",
                correctAnswers: [ "yes" ]
            }
        ]
    },
});