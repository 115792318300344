import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";

export const Zoro001IntroGoalSeekerScene = new PointAndClickScene({

    id: 'zoro-001-intro-goal_seeker',
    startLocation: 'goal_seeker',
    showPhoneButton: false,
    locations: {
        'goal_seeker': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro001/bg_c1_doelzoeker.png'),
                    pos: { left: -180, top: -180 },
                    layer: 2,

                }),
                new PointAndClickItem({
                    id: 'past',
                    img: require('@/assets/images/scenes/zoro001/bg_c1_doelzoeker_01.jpg'),
                    map: "279,300 788.2,296 788.2,1139.4 492.4,1139.4 423,953.5 284.1,1001.5",
                    pos: { left: -180, top: -180 },
                    layer: 2,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    dontAnimateOnHover: true,
                    action: "click_past",
                    trigger: null,
                    name: "Verleden",
                }),
                new PointAndClickItem({
                    id: 'present',
                    img: require('@/assets/images/scenes/zoro001/bg_c1_doelzoeker_02.jpg'),
                    map: "0,304 606.7,301 606.7,1144.4 0.7, 1144.4",
                    pos: { left: 609, top: -180 },
                    layer: 2,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    dontAnimateOnHover: true,
                    action: "click_present",
                    trigger: null,
                    name: "Heden",
                }),
                new PointAndClickItem({
                    id: 'future',
                    img: require('@/assets/images/scenes/zoro001/bg_c1_doelzoeker_03.jpg'),
                    map: "0,290 540.2,289.6 540.2,1133 0,1133",
                    pos: { left: 1215, top: -180 },
                    layer: 2,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    dontAnimateOnHover: true,
                    action: "click_future",
                    trigger: null,
                    name: "Toekomst",
                }),

                //objects
                new PointAndClickItem({
                    id: 'goalseeker_bikehelmet',
                    img: require('@/assets/images/scenes/zoro001/objects/bikehelmet/bikehelmet_120x120.png'),
                    pos: { left: 1500, top: 300 },
                    layer: 10,
                    subtitle: "Elektrische fiets",
                    goalSeekerTime: 'future',

                }),
                new PointAndClickItem({
                    id: 'goalseeker_flowers',
                    img: require('@/assets/images/scenes/zoro001/objects/flowers/flowers_120x120.png'),
                    pos: { left: 250, top: 300 },
                    layer: 10,
                    subtitle: "Bloemen",
                    goalSeekerTime: 'past',

                }),
                new PointAndClickItem({
                    id: 'goalseeker_dog',
                    img: require('@/assets/images/scenes/zoro001/objects/dog_2/dog_2_120x120.png'),
                    pos: { left: 700, top: 600 },
                    layer: 10,
                    subtitle: "Spence",
                    goalSeekerTime: 'present',
                }),
                new PointAndClickItem({
                    id: 'goalseeker_family_photo',
                    img: require('@/assets/images/scenes/zoro001/objects/photo_1/photo_1_120x120.png'),
                    pos: { left: 900, top: 800 },
                    layer: 10,
                    subtitle: "Familiefoto",
                    goalSeekerTime: 'present',
                }),
                new PointAndClickItem({
                    id: 'goalseeker_art',
                    img: require('@/assets/images/scenes/zoro001/objects/art_1/art_1_120x120.png'),
                    pos: { left: 350, top: 700 },
                    layer: 10,
                    subtitle: "Kunst",
                    goalSeekerTime: 'past',
                }),
                new PointAndClickItem({
                    id: 'goalseeker_wheelchair',
                    img: require('@/assets/images/scenes/zoro001/objects/wheelchair/wheelchair_120x120.png'),
                    pos: { left: 800, top: 200 },
                    layer: 10,
                    subtitle: "Rolstoel",
                    goalSeekerTime: 'present',
                }),
            ],
        }
    },
});