import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";
import {
    FlowersGiftCollectionItem,
    MiningLampCollectionItem,
    PhotoCatCollectionItem, PhotoMinersCollectionItem, PosterPlannerCollectionItem,
    StickerDoorCollectionItem
} from "@/assets/data/items/collection_items-ZORO-003";

export const Zoro003GoalSeekerScene = new PointAndClickScene({
    id: 'zoro-003-intro-goal_seeker',
    startLocation: 'goal_seeker',
    showPhoneButton: false,
    locations: {
        'goal_seeker': {
            items: [
                new PointAndClickItem({
                    id: 'past',
                    img: require('@/assets/images/scenes/zoro003/bg_c3_doelzoeker_01.png'),
                    map: "279,300 788.2,296 788.2,1139.4 492.4,1139.4 423,953.5 284.1,1001.5",
                    pos: { left: -180, top: -180 },
                    layer: 2,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    dontAnimateOnHover: true,
                    action: "click_past",
                    trigger: null,
                    name: "Verleden",
                }),
                new PointAndClickItem({
                    id: 'present',
                    img: require('@/assets/images/scenes/zoro003/bg_c3_doelzoeker_02.png'),
                    map: "0,304 606.7,301 606.7,1144.4 0.7, 1144.4",
                    pos: { left: 609, top: -180 },
                    layer: 2,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    dontAnimateOnHover: true,
                    action: "click_present",
                    trigger: null,
                    name: "Heden",
                }),
                new PointAndClickItem({
                    id: 'future',
                    img: require('@/assets/images/scenes/zoro003/bg_c3_doelzoeker_03.png'),
                    map: "0,290 540.2,289.6 540.2,1133 0,1133",
                    pos: { left: 1215, top: -180 },
                    layer: 2,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    dontAnimateOnHover: true,
                    action: "click_future",
                    trigger: null,
                    name: "Toekomst",
                }),

                // Objects
                new PointAndClickItem({
                    id: `goal-${PhotoCatCollectionItem.id}`,
                    img: PhotoCatCollectionItem.icon,
                    pos: { left: 250, top: 300 },
                    layer: 10,
                    subtitle: PhotoCatCollectionItem.name,
                    goalSeekerTime: 'past',

                }),
                new PointAndClickItem({
                    id: `goal-${MiningLampCollectionItem.id}`,
                    img: MiningLampCollectionItem.icon,
                    pos: { left: 350, top: 600 },
                    layer: 10,
                    subtitle: MiningLampCollectionItem.name,
                    goalSeekerTime: 'past',

                }),
                new PointAndClickItem({
                    id: `goal-${StickerDoorCollectionItem.id}`,
                    img: StickerDoorCollectionItem.icon,
                    pos: { left: 1050, top: 500 },
                    layer: 10,
                    subtitle: StickerDoorCollectionItem.name,
                    goalSeekerTime: 'present',
                }),
                new PointAndClickItem({
                    id: `goal-${PosterPlannerCollectionItem.id}`,
                    img: PosterPlannerCollectionItem.icon,
                    pos: { left: 750, top: 250 },
                    layer: 10,
                    subtitle: PosterPlannerCollectionItem.name,
                    goalSeekerTime: 'present',
                }),
                new PointAndClickItem({
                    id: `goal-${PhotoMinersCollectionItem.id}`,
                    img: PhotoMinersCollectionItem.icon,
                    pos: { left: 1500, top: 300 },
                    layer: 10,
                    subtitle: PhotoMinersCollectionItem.name,
                    goalSeekerTime: 'future',
                }),
                new PointAndClickItem({
                    id: `goal-${FlowersGiftCollectionItem.id}`,
                    img: FlowersGiftCollectionItem.icon,
                    pos: { left: 1300, top: 700 },
                    layer: 10,
                    subtitle: FlowersGiftCollectionItem.name,
                    goalSeekerTime: 'future',
                }),
            ]
        }
    }
});