import CaseFile from "@/models/CaseFile";
import GameScore from "@/models/GameScore";
import Constants from "@/config/constants";

import {
    Zoro002Lukas,
    Zoro002Karim,
    Zoro002Caroline,
    Zoro002Francesco,
    Zoro002Amina,
    Zoro002Christien,
    Zoro002Josefien, Zoro002Jaap, Zoro002Iris, Zoro002Esther, Zoro002Annika
} from "@/assets/data/people/people";

import {
    BookRack, BrochureDiabetes,
    FootballScarf, KarimInsuline, Laptop,
    LukasCellphone, LukasMom, MealCart, Pizzabox

} from "@/assets/data/items/collection_items-ZORO-002";
import {
    CarbohydrateInsulinApp, CookBook, DigitalKitchenScale, GlucoseSensor,
    MedicationSmartWatch, MedicationVendingMachine,
    SmartOven, SmartWaterBottle,
    SubcutaneousInjection, TreadMill
} from "@/assets/data/technological_aids/technological_aids";
import { ZORO002MeetingRoom } from "@/assets/data/pointandclick/scenes/ZORO-002/ZORO-002-meeting_room";
import { Zoro002InitialHomeScene } from "@/assets/data/pointandclick/scenes/ZORO-002/ZORO-002-initial_home";
import { Zoro002IntroFirstTalkRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-002/intro/ZORO-002-intro-first_talk_recipient";
import { Zoro002IntroHospitalRoomTalkRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-002/intro/ZORO-002-intro-hospital_room_talk_recipient";
import { Zoro002IntroCollectItemsScene } from "@/assets/data/pointandclick/scenes/ZORO-002/intro/ZORO-002-intro-collect_items";
import { Zoro002IntroFindRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-002/intro/ZORO-002-intro-find_recipient";
import { Zoro002GoalSeekerScene } from "@/assets/data/pointandclick/scenes/ZORO-002/intro/ZORO-002-goal_seeker";
import { Zoro002FollowupFirstTalkRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-002/followup/ZORO-002-followup-first_talk_recipient";
import { Zoro002FollowupCollectItemsScene } from "@/assets/data/pointandclick/scenes/ZORO-002/followup/ZORO-002-followup-collect_items";
import { Zoro002FollowupFindRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-002/followup/ZORO-002-followup-find_recipient";
import { Zoro002FollowupHospitalRoomTalkRecipientScene } from "@/assets/data/pointandclick/scenes/ZORO-002/followup/ZORO-002-followup-hospital_room_talk_recipient";

export const casefile = new CaseFile({
// export default new CaseFile({
    id: "ZORO-002",
    enabled: true,
    version: "0.4.0",
    caseInfo: "Mijn naam is Lukas, 19 jaar. Ik ben opgenomen in het ziekenhuis met een hypo. Vorig jaar werd diabetes type 1 vastgesteld. Ik heb het moeilijk met het opvolgen/toepassen van mijn behandelplan.",
    caseSetting: "Ziekenhuis",
    recipient: {
        id: "lukas",
        firstname: "Lukas",
        lastname: "",
        age: 19,
        backstory: "Lukas is 19 jaar. Vorig jaar belandde hij in het ziekenhuis met symptomen zoals een groot dorstgevoel, hij drinkt veel en gewichtsverlies. Lukas is de afgelopen periode ook sneller moe. Daar werd na onderzoek vastgesteld dat hij diabetes type 1 heeft. In samenspraak met Lukas, de arts, de diëtiste en zijn ouders werd een strak schema opgesteld met wat hij wel/niet mag doen/eten.\n" +
            " \n" +
            "Lukas controleert 3 keer per dag zijn glycemie aan de hand van een glucosemeter met strips en geeft zichzelf aan de hand van een insulinepen de nodige eenheden insuline.\n" +
            " \n" +
            "De afgelopen maanden waren voor Lukas enorm lastig. Hij moet continu het evenwicht zoeken tussen wat hij wil (alles eten en drinken wat hij vroeger deed) en rekening houden met zijn diagnose, diabetes type 1.\n" +
            " \n" +
            "Hij wil kunnen voetballen, uitgaan met vrienden... en hier niet zoveel beperkingen in ondervinden. ! Kortom hij wil gewoon ‘normaal’ zijn zoals elke andere jongvolwassene. Hij vindt het vervelend dat andere kunnen zien dat hij diabetes heeft.\n" +
            " \n" +
            "De ouders van Lukas zijn erg bezorgd en willen voorkomen dat hij in de problemen komt. (hypo/hyper).\n" +
            " \n" +
            "Wanneer Lukas in het ziekenhuis belandt met een hypo gaat de diëtiste met hem in gesprek. Het blijkt dat Lukas het moeilijker heeft dat ze eerst dachten. Het dieet valt hem zwaar, hij moet enorm veel aanpassingen doen en is onzeker over de uitkomst. Als casemanager krijg jij de uitdaging om op zoek te gaan naar mogelijkheden om deze last meer in evenwicht te brengen met de draagkracht die Lukas nu heeft.\n",
        misc: {
            profession: "Student",
            partner: {
                name: "Geen"
            },
            siblingCount: 1
        }
    },
    team: [
        Zoro002Lukas,
        Zoro002Francesco,
        Zoro002Annika,
        Zoro002Christien,
        Zoro002Jaap,
        Zoro002Amina,
        Zoro002Josefien,
        Zoro002Caroline,
        Zoro002Esther,
        Zoro002Iris,
        Zoro002Karim,
    ],
    technologicalAids: [
        GlucoseSensor,
        CarbohydrateInsulinApp,
        SubcutaneousInjection,
        CookBook,
        SmartWaterBottle,
        SmartOven,
        MedicationSmartWatch,
        MedicationVendingMachine,
        TreadMill,
        DigitalKitchenScale
    ],
    steps: [
        {
            id: "intro",
            name: "Kennismaking en zorgvraag cliënt",
            includeTodo: true,
            documents: [],
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Je kan de zorgvrager bezoeken via jouw afspraak in de 'Agenda' app of via jouw 'Contacten'",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "agenda",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_calendar.png"),
                                title: "Agenda"
                            },
                            body: {
                                title: "Afspraak vandaag",
                                description: "Kennismaking met Lukas en zorgvraag bepalen."
                            },
                            callToAction: {
                                type: "location",
                                text: "Bezoek Lukas",
                                action: "visit_recipient"
                            },
                            idleTime: 10000,
                            autoDisplay: 20000
                        }
                    },
                    actions: [
                        {
                            action: "visit_recipient",
                            phase: "heading_to_recipient"
                        }
                    ],
                    triggers: []
                },
                {
                    id: "heading_to_recipient",
                    view: "gps",
                    title: "GPS",
                    properties: {},
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "initial_home"
                        }
                    ]
                },
                {
                    id: "initial_home",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ],
                            hints: [
                                {
                                    text: "Je bent  aangekomen bij je cliënt. Klop op de deur.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        scene: Zoro002InitialHomeScene,
                    },
                    actions: [
                        {
                            action: "knock_room_door",
                            phase: "initial_home_waiting"
                        }
                    ]
                },
                {
                    id: "initial_home_waiting",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        scene: Zoro002InitialHomeScene,
                    },
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "first_talk_recipient"
                        }
                    ]
                },
                {
                    id: "first_talk_recipient",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ],
                            hints: [
                                {
                                    text: "Praat met Lukas.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        scene: Zoro002IntroFirstTalkRecipientScene
                    },
                    actions: [
                        {
                            action: "talk_with_recipient",
                            phase: "secondary_talk_recipient"
                        }
                    ]
                },
                {
                    id: "secondary_talk_recipient",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Hallo Lukas! Ik heb vandaag een afspraak met jou. Ik ben <player.firstname> , jouw casemanager. Ik kom eens luisteren wat ik voor je kan betekenen."
                            },
                            {
                                speaker: "lukas",
                                text: "Welkom <player.firstname>. Ik moet nog één medisch onderzoek ondergaan en dan kom ik eraan. Kijk maar even rond, dan leer je mij al kennen."
                            }
                        ],
                        scene: Zoro002IntroHospitalRoomTalkRecipientScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "collect_items"
                        }
                    ]
                },
                {
                    id: "collect_items",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        location: "hallway",
                        recipient: "kitchen",
                        scene: Zoro002IntroCollectItemsScene
                    },
                    actions: [
                        {
                            action: "collect_item"
                        },
                        {
                            action: "move_location"
                        }
                    ],
                    triggers: [
                        {
                            trigger: "all_items_collected",
                            phase: "find_recipient"
                        }
                    ]
                },
                {
                    id: "find_recipient",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ],
                            hints: [
                                {
                                    text: "Praat met Lukas.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "sms",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_sms.png"),
                                title: "SMS"
                            },
                            body: {
                                title: "Lukas:",
                                description: "Het onderzoek is achter de rug. Ik zie je in mijn kamer."
                            },
                            callToAction: {
                                text: "OK"
                            }
                        },
                        scene: Zoro002IntroFindRecipientScene
                    },
                    actions: [
                        {
                            action: "talk_with_recipient",
                            phase: "goal_seeker_prep_talk"
                        }
                    ]
                },
                {
                    id: "goal_seeker_prep_talk",
                    phase: "dialog",
                    view: 'free_roam',
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Bedankt dat ik even mocht rondkijken. We gaan nu een doelzoeker opstellen. Via deze methode leer ik jou beter kennen en ontdekken we wat op dit moment je levensdoelen zijn."
                            }
                        ],
                        scene: Zoro002IntroHospitalRoomTalkRecipientScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "goal_seeker"
                        }
                    ]
                },
                {
                    id: "goal_seeker",
                    phase: "goal_seeker",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        scene: Zoro002GoalSeekerScene
                    },
                    actions: [
                        {
                            action: "click_past",
                            phase: "goal_seeker_past"
                        },
                        {
                            action: "click_present",
                            phase: "goal_seeker_present"
                        },
                        {
                            action: "click_future",
                            phase: "goal_seeker_future"
                        }
                    ],
                    triggers: [
                        {
                            trigger: "goal_seeker_completed",
                            phase: "goal_seeker_wrap_up_talk"
                        }
                    ]
                },
                {
                    id: "goal_seeker_past",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Van welke dingen kon je vroeger echt genieten?"
                            },
                            {
                                speaker: "lukas",
                                text: "Ik genoot ervan om zorgeloos samen met mijn vrienden te voetballen en te gaan supporteren. Nadien bleef ik dan plakken in de kantine met een pak friet."
                            }
                        ],
                        scene: Zoro002GoalSeekerScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "goal_seeker"
                        }
                    ]
                },
                {
                    id: "goal_seeker_present",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Wat vind je nu belangrijk?"
                            },
                            {
                                speaker: "lukas",
                                text: "Ik heb een toffe groep vrienden en ik zou het heel erg vinden mocht ik nu buiten de groep vallen."
                            }
                        ],
                        scene: Zoro002GoalSeekerScene,
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "goal_seeker"
                        }
                    ]
                },
                {
                    id: "goal_seeker_future",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Wat zijn jouw uitdagingen in de toekomst?"
                            },
                            {
                                speaker: "lukas",
                                text: "Ik moet leren om verantwoordelijk om te gaan met wat en wanneer ik eet en drink. Dit is echt levensbelangrijk. "
                            }
                        ],
                        scene: Zoro002GoalSeekerScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "goal_seeker"
                        }
                    ]
                },
                {
                    id: "goal_seeker_wrap_up_talk",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ],
                        },
                        dialog: [
                            {
                                speaker: "lukas",
                                text: "De doelzoeker heeft mij laten inzien dat zowel het verleden, het heden en de toekomst belangrijke momenten zijn om bij stil te staan. Ik weet nu veel beter wat mijn levensdoelen zijn. Dankzij de doelzoeker heb ik verder nagedacht over zaken die ik wil aanpakken. Ik wil verantwoordelijkheid nemen over mijn eigen gezondheid."
                            }
                        ],
                        scene: Zoro002GoalSeekerScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "step_wrap_up_talk"
                        }
                    ]
                },
                {
                    id: "step_wrap_up_talk",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        dialog: [
                            {
                                speaker: "player",
                                text: "Begrijp ik het goed dat jouw zorgvraag de volgende is: Je wil op een zo normaal mogelijke manier je leven leiden. Je wil graag hulp om je behandelplan correct op te volgen zodat je zelf verantwoordelijk draagt over je gezondheid."
                            },
                            {
                                speaker: "lukas",
                                text: "Ja, dat klopt <player.firstname>."
                            },
                            {
                                speaker: "player",
                                text: "Oké, bedankt voor alle informatie. Ik ga meteen aan de slag en hou je op de hoogte. Bedankt voor je tijd en tot ziens!"
                            }
                        ],
                        scene: Zoro002IntroHospitalRoomTalkRecipientScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "conclusion"
                        }
                    ]
                },
                {
                    id: "conclusion",
                    phase: "conclusion",
                    view: "free_roam",
                    properties: {
                        conclusion: {
                            title: "1. Kennismaking en zorgvraag cliënt",
                            description: "<p>Je hebt het eerste deel van je zorgplan ingevuld. Je hebt de<strong> zorgontvanger beter leren</strong> <strong>kennen</strong>.</p>\n" +
                                "<p>De<strong> doelzoeker</strong> helpt de zorgontvanger om na te denken over wat belangrijk is in het leven en wat levenskwaliteit biedt. Het helpt om concrete <strong>levensdoelen</strong> te formuleren die de zorgontvanger kan gebruiken in gesprek met zorgverleners. De doelzoeker werd ontwikkeld door het<strong> Vlaams Pati&euml;ntenplatform</strong>. Meer info staat op de gsm onder Info.</p>\n" +
                                "<p>Je <strong>onderzocht en signaleerde</strong> een <strong>zorgvraag</strong>. Nu weet je wat de zorgontvanger wil bereiken.</p>",
                            infoKeys: [ 'background-info', 'care-question', 'score-intra' ],
                            buttonText: "Bekijk dossier"
                        },
                        scene: Zoro002IntroHospitalRoomTalkRecipientScene
                    },
                    actions: [
                        {
                            action: "next_step",
                            step: "assembly"
                        }
                    ],
                }
            ],
            points: new GameScore({
                intrapreneurship: 3
            }),
            fixedPoints: new GameScore({
                intrapreneurship: 3
            })
        },
        {
            id: "assembly",
            name: "Zorgteam samenstellen",
            includeTodo: true,
            documents: [],
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Stel een zorgteam samen door naar je Contacten te gaan en kies voor 'Zorgteam aanmaken'",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "agenda",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_calendar.png"),
                                title: "Agenda"
                            },
                            body: {
                                title: "Reminder",
                                description: "Stel een zorgteam samen voor Lukas."
                            },
                            callToAction: {
                                text: "Zorgteam samenstellen",
                                action: "click_assembly"
                            },
                            idleTime: 10000,
                            autoDisplay: 20000
                        }
                    },
                    actions: [
                        {
                            action: "click_assembly",
                            phase: "contact_assembly"
                        }
                    ],
                    triggers: []
                },
                {
                    id: "contact_assembly",
                    view: "contacts",
                    title: "Contacten",
                    properties: {
                        meeting_type: "offline"
                    },
                    actions: [
                        {
                            action: "start_meeting",
                            phase: "heading_to_meeting"
                        }
                    ]
                },
                {
                    id: "heading_to_meeting",
                    view: "sending_team_invite",
                    title: "Agenda",
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "meeting"
                        }
                    ]
                },
                {
                    id: "meeting",
                    view: "meeting",
                    properties: {
                        meeting_type: "offline",
                        scene: ZORO002MeetingRoom,
                        initial_dialog_wrong: "Hallo allemaal, ik heb jullie samengebracht als zorgteam voor Lukas. Ik wil even afstemmen of jullie je hierin kunnen vinden?",
                        initial_dialog_correct: "Wat bevelen jullie aan voor Lukas vanuit jullie context? En Lukas wat denk jij over deze aanbevelingen?",
                        negativeFeedback: [
                            "Ik denk dat ik voor deze case weinig zal kunnen betekenen.",
                            "Misschien dat een ander type zorgverlener is aangewezen.",
                            "Sorry, ik kan me hierin niet vinden.",
                            "Ik kan weinig professionele meerwaarde bieden aan dit team.",
                            "Sorry, ik vrees dat ik hier weinig input zal kunnen bieden.",
                            "Mijn excuses, maar ik denk dat ik hier niet op mijn plaats zit.",
                            "Ik ben ervan overtuigd dat anderen hier meer van weten.",
                            "Bedankt voor de uitnodiging, maar hierbij kan ik je echt niet verderhelpen.",
                            "Geen idee hoe je bij mij komt. Ik ben niet de juiste persoon.",
                            "Deze vraag is zeer interessant, maar volledig buiten mijn expertisegebied.",
                            "Dit is niets voor mij. Veel succes met het zoeken naar een andere zorgverlener."
                        ],
                        positiveFeedback: [
                            "Ik kan me hierin wel vinden.",
                            "Lijkt me logisch dat je me hebt toegevoegd.",
                            "Oké voor mij.",
                            "Ja, hoor.",
                            "Ik wil absoluut helpen nadenken over een gepaste oplossing voor Lukas.",
                            "Bedankt voor de uitnodiging, ik kijk ernaar uit om samen te werken.",
                            "Dit is helemaal iets voor mij.",
                            "Deze vraag is interessant en past binnen mijn expertisegebied.",
                            "Super, ik doe mee.",
                            "Ik ga de uitdaging aan."
                        ],
                        missingRecipient: "Het is misschien een goed idee om de zorgontvanger hier ook bij te betrekken.\nHeb je er al aan gedacht om Lukas hierbij te betrekken?",
                        recommendations: [
                            {
                                person: Zoro002Lukas,
                                recommendation: 'Ik wil een oplossing die mij helpt mijn huidige levensstijl te organiseren en mijn diabetes onder controle te houden.'
                            },
                            {
                                person: Zoro002Karim,
                                recommendation: 'Ik weet zeker dat technologische hulpmiddelen Lukas kunnen helpen om meer controle te hebben over zijn eetgewoontes.'
                            },
                            {
                                person: Zoro002Caroline,
                                recommendation: 'Ik merk dat Lukas zijn glycemiewaarden erg schommelen. Dit zou toch echt wel wat stabieler moeten worden. Anders gaat dit problemen opleveren in de toekomst.'
                            },
                            {
                                person: Zoro002Francesco,
                                recommendation: 'Als ik de zorgvraag van Lukas hoor, denk ik dat de informatie die ik gekregen heb tijdens de ZORO-training wel van nut kan zijn. Ik heb hier geleerd om gepaste technologische hulpmiddelen te vinden voor allerlei situaties. Ik stuur de link naar de informatie door naar jou.',
                                mentionZoroWebsiteLink: true
                            },
                            {
                                person: Zoro002Christien,
                                recommendation: 'Ik zal Lukas pas kunnen loslaten als hij zelf de verantwoordelijkheid over zijn gezondheid kan nemen.'
                            },
                            {
                                person: Zoro002Amina,
                                recommendation: 'Lukas vertelt mij dat hij een hekel heeft aan de dagelijkse vingerprikken.'
                            }
                        ]
                    },
                    actions: [
                        {
                            action: "make_correction",
                            phase: "contact_assembly"
                        },
                        {
                            action: "finish_meeting",
                            phase: "conclusion"
                        }
                    ]
                },
                {
                    id: "conclusion",
                    phase: "conclusion",
                    view: "meeting",
                    properties: {
                        scene: ZORO002MeetingRoom,
                        conclusion: {
                            title: "2. Stel een zorgteam samen",
                            description: "<p>Een team kies je op basis van de noden van je zorgontvanger. <strong>Wie kan er bij deze vraag</strong> <strong>het beste helpen?</strong> Dankzij het teamoverleg ga je in <strong>overleg</strong> en werk je<strong> samen</strong> met de <strong>verschillende disciplines</strong> en de <strong>zorgontvanger</strong> aan een <strong>gemeenschappelijk doel</strong>. Dit zorgt ervoor dat je echt kwalitatieve zorg op maat kan bieden!</p>",
                            infoKeys: [ 'care-team-added', 'recommendation-added', 'score-intra', 'score-is' ],
                            buttonText: "Bekijk dossier"
                        }
                    },
                    actions: [
                        {
                            action: "next_step",
                            step: "technology"
                        }
                    ]
                }
            ],
            points: new GameScore({
                intrapreneurship: 3,
                interprofessionalCollaboration: 15
            }),
            fixedPoints: new GameScore({
                intrapreneurship: 3,
                interprofessionalCollaboration: 15
            })
        },
        {
            id: "technology",
            name: "Technologische hulpmiddelen",
            includeTodo: true,
            documents: [],
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Bekijk de link met aanbevelingen van het zorgteam voor technologische hulpmiddelen in 'Bestanden'.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "files",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_files.png"),
                                title: "Bestanden"
                            },
                            body: {
                                title: "Reminder",
                                description: "Zoek een technologische oplossing voor Lukas. Zie 'Aanbevelingen'."
                            },
                            callToAction: {
                                type: "files",
                                text: "Open bestanden",
                                action: "click_files"
                            },
                            idleTime: 10000,
                            autoDisplay: 20000
                        }
                    },
                    actions: [
                        {
                            action: "click_files",
                            phase: "view_files"
                        }
                    ]
                },
                {
                    id: "view_files",
                    view: "documents",
                    title: "Bestanden",
                    properties: {
                        documents: [
                            "recommendations"
                        ],
                        selectedDocument: "recommendations"
                    },
                    actions: [
                        {
                            action: "click_website_link",
                            phase: "website_view"
                        }
                    ]
                },
                {
                    id: "website_view",
                    view: "website_view",
                    title: "https://www.hulpmiddelen.com",
                    titleType: "url",
                    properties: {
                        meeting_type: "offline"
                    },
                    actions: [
                        {
                            action: "start_meeting",
                            phase: "heading_to_meeting"
                        }
                    ]
                },
                {
                    id: "heading_to_meeting",
                    view: "sending_team_invite",
                    title: "Agenda",
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "meeting"
                        }
                    ]
                },
                {
                    id: "meeting",
                    view: "meeting",
                    properties: {
                        meeting_type: "offline",
                        scene: ZORO002MeetingRoom,
                        initial_dialog: "Wat vinden jullie van deze selectie hulpmiddelen voor Lukas?",
                        technologicalAidsResponses: [
                            {
                                technologicalAid: GlucoseSensor,
                                questioner: Zoro002Caroline,
                                question: "Je kan een alarm instellen als je suikerwaarde afwijkend is. Is dit iets voor jou?",
                                answer: "Ik denk het wel. Het lijkt me erg handig dat die suikerwaarden continu opgevolgd worden."
                            },
                            {
                                technologicalAid: CarbohydrateInsulinApp,
                                questioner: Zoro002Karim,
                                question: "Zie je het zitten om bij elke maaltijd uit te rekenen hoeveel insuline je nodig hebt om de maaltijd te verwerken?",
                                answer: "Mama, wil jij dat niet helpen uitrekenen voor mij, afhankelijk van wat je kookt? Ik zie dat momenteel nog niet zitten om dat iedere keer zelf in te geven."
                            },
                            {
                                technologicalAid: SubcutaneousInjection,
                                questioner: Zoro002Amina,
                                question: "De zorgontvanger hoeft zichzelf dan niet meermaals per dag te prikken. Is dit iets voor jou?",
                                answer: "Neen, ik heb geen schrik van insulineprikken. Misschien krijg ik nog een insulinepomp? Ik hou het in gedachten, voorlopig lijkt het niet nuttig voor mij.",
                            },
                            {
                                technologicalAid: CookBook,
                                questioner: Zoro002Karim,
                                question: "Zou het helpen om je voedingsgewoonten aan te passen?",
                                answer: "Ik ben niet zo’n fan van groenten. Ik denk dat ik die nieuwe recepten niet zo lekker zal vinden. Voor mij hoeft niet het hele gezin een diabetes-dieet te volgen.",
                            },
                            {
                                technologicalAid: SmartWaterBottle,
                                questioner: Zoro002Amina,
                                question: "Zie je het haalbaar om de waterfles met je mee te nemen?",
                                answer: "Neen, ik zal de fles meestal vergeten. Ik zal die berichtjes snel vervelend vinden, denk ik.",
                            },
                            {
                                technologicalAid: SmartOven,
                                questioner: Zoro002Francesco,
                                question: "Kan deze oven je ondersteunen om je voedingsgewoonten aan te passen?",
                                answer: "Mijn mama kookt, misschien vindt mijn mama dat interessant. Voor mij hoeft het alvast niet.",
                            },
                            {
                                technologicalAid: MedicationSmartWatch,
                                questioner: Zoro002Christien,
                                question: "Lijkt dit je handig om je insuline tijdig te prikken?",
                                answer: "Het prikken van insuline gebeurt bij de maaltijd. Ik denk niet dat ik dat zal vergeten. Ik heb al een smartwatch en deze wil ik niet inruilen voor een medicatiealarm.",
                            },
                            {
                                technologicalAid: MedicationVendingMachine,
                                questioner: Zoro002Caroline,
                                question: "Dit toestel helpt je om je medicatie om het juiste moment te nemen. Heb jij hier nood aan?",
                                answer: "Ik hoef geen medicatie te nemen. Ik prik insuline. Dit toestel kan mij niet ondersteunen.",
                            },
                            {
                                technologicalAid: TreadMill,
                                questioner: Zoro002Christien,
                                question: "Wat denk je ervan om meer te sporten op een loopband? Dan ben je minder afhankelijk van het weer om te sporten?",
                                answer: "Ik hou niet van sporten binnen en ga liever buiten lopen. Ik vind het niet erg als het regent, dan trek ik een regenjas aan!",
                            },
                            {
                                technologicalAid: DigitalKitchenScale,
                                questioner: Zoro002Karim,
                                question: "Kan deze weegschaal je in de keuken ondersteunen om je porties juist af te wegen?",
                                answer: "Mijn mama kookt altijd. Ik zal dat niet direct gebruiken. ",
                            }
                        ],
                        closing_dialog: [
                            {
                                person: Zoro002Karim,
                                text: "Met de sensor zal je het effect van voeding op je glycemiewaarden beter kunnen opvolgen."
                            },
                            {
                                person: Zoro002Caroline,
                                text: "De gegevens vanuit de app laten het toe om je glycemiewaarden op te volgen bij je volgende consultatie."
                            },
                            {
                                person: Zoro002Francesco,
                                text: "Dit is een minder zichtbare manier om efficiënt je gezondheid te kunnen opvolgen."
                            },
                            {
                                person: Zoro002Christien,
                                text: "Ik ben blij dat je nu zelf meer inzicht hebt en verantwoordelijkheid kan nemen."
                            },
                            {
                                person: Zoro002Amina,
                                text: "Op deze manier zijn het al een aantal prikken minder voor Lukas. Super!"
                            },
                            {
                                person: Zoro002Lukas,
                                text: "Ik ben tevreden dat ik niet langer die vervelende vingerprikken moet doen. Via dit hulpmiddel zal ik tijdig een alarm ontvangen als mijn waarden afwijken. Ik kijk ernaar uit om het hulpmiddel te testen."
                            }
                        ]
                    },
                    actions: [
                        {
                            action: "make_correction",
                            phase: "website_view"
                        },
                        {
                            action: "finish_meeting",
                            phase: "conclusion"
                        }
                    ]
                },
                {
                    id: "conclusion",
                    phase: "conclusion",
                    view: "meeting",
                    properties: {
                        scene: ZORO002MeetingRoom,
                        conclusion: {
                            title: "3. Technologische hulpmiddelen",
                            description: "<p>Bij het zoeken naar <strong>geschikte zorgtechnologie</strong> is het belangrijk om de gebruiker van de technologie goed te <strong>bevragen</strong>. Die moet immers het voordeel zien en de technologie willen gebruiken. Als zorgverlener kan je je mening geven, maar de <strong>eindbeslissing ligt steeds bij de zorgontvanger.</strong></p>\n" +
                                "<p>Je hebt <strong>kritisch geluisterd</strong> naar het zorgteam en de wensen van de zorgontvanger. Zo kwam je bij een <strong>zorgoplossing op maat</strong>. De zorgontvanger zelf heeft ook hier steeds een belangrijke rol!</p>",
                            infoKeys: [ 'tech-added', 'score-tw', 'score-intra', 'score-is' ],
                            buttonText: "Bekijk dossier"
                        }
                    },
                    actions: [
                        {
                            action: "next_step",
                            step: "followup"
                        }
                    ]
                }
            ],
            points: new GameScore({
                technicalManeuverability: 15,
                intrapreneurship: 3,
                interprofessionalCollaboration: 3
            }),
            fixedPoints: new GameScore({
                technicalManeuverability: 15,
                intrapreneurship: 3,
                interprofessionalCollaboration: 3
            })
        },
        {
            id: "followup",
            name: "Follow up & ethiek",
            includeTodo: true,
            documents: [
                'recommendations'
            ],
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Bezoek je cliënt voor een follow-up.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "agenda",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_calendar.png"),
                                title: "Agenda"
                            },
                            body: {
                                title: "Reminder",
                                description: "Follow up - Bezoek Lukas en bekijk ethische aspect."
                            },
                            callToAction: {
                                type: "location",
                                text: "Bezoek Lukas",
                                action: "visit_recipient"
                            },
                            idleTime: 10000,
                            autoDisplay: 20000
                        }
                    },
                    actions: [
                        {
                            action: "visit_recipient",
                            phase: "heading_to_recipient"
                        }
                    ]
                },
                {
                    id: "heading_to_recipient",
                    view: "gps",
                    title: "GPS",
                    properties: {},
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "initial_home"
                        }
                    ]
                },
                {
                    id: "initial_home",
                    view: "free_roam",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Je bent  aangekomen bij je cliënt. Klop op de deur.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        scene: Zoro002InitialHomeScene,
                    },
                    actions: [
                        {
                            action: "knock_room_door",
                            phase: "initial_home_waiting"
                        }
                    ]
                },
                {
                    id: "initial_home_waiting",
                    view: "free_roam",
                    properties: {
                        phone: {
                            disabledApps: [
                                'contacts',
                                'agenda',
                                'files'
                            ]
                        },
                        scene: Zoro002InitialHomeScene,
                    },
                    triggers: [
                        {
                            trigger: "load-phase",
                            auto: true,
                            duration: 2000,
                            phase: "first_talk_recipient"
                        }
                    ]
                },
                {
                    id: "first_talk_recipient",
                    view: "free_roam",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Praat met Lukas.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        scene: Zoro002FollowupFirstTalkRecipientScene
                    },
                    actions: [
                        {
                            action: "talk_with_recipient",
                            phase: "secondary_talk_recipient"
                        }
                    ]
                },
                {
                    id: "secondary_talk_recipient",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        dialog: [
                            {
                                speaker: "player",
                                text: "Hallo, Lukas! Hoe gaat het met jou? Ben je tevreden met jouw technologisch hulpmiddel?\n" +
                                    "Ik wil ook even rondkijken om jouw zorgplan te vervolledigen, als je het niet erg vindt?"
                            },
                            {
                                speaker: "lukas",
                                text: "Hoi <player.firstname>, met mij gaat het heel goed. Ik ben erg tevreden met de oplossing die jullie hebben voorgesteld."
                            },
                            {
                                speaker: "lukas",
                                text: "Kijk maar even rond, geen probleem. Ik kom zo terug."
                            }
                        ],
                        scene: Zoro002FollowupHospitalRoomTalkRecipientScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "collect_items"
                        }
                    ]
                },
                {
                    id: "collect_items",
                    view: "free_roam",
                    properties: {
                        scene: Zoro002FollowupCollectItemsScene
                    },
                    actions: [
                        {
                            action: "collect_item"
                        },
                        {
                            action: "move_location"
                        }
                    ],
                    triggers: [
                        {
                            trigger: "all_items_collected",
                            phase: "find_recipient"
                        }
                    ]
                },
                {
                    id: "find_recipient",
                    view: "free_roam",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Praat met Lukas.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "sms",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_sms.png"),
                                title: "SMS"
                            },
                            body: {
                                title: "Lukas:",
                                description: "Het onderzoek is achter de rug. Ik zie je in mijn kamer."
                            },
                            callToAction: {
                                text: "OK"
                            }
                        },
                        scene: Zoro002FollowupFindRecipientScene
                    },
                    actions: [
                        {
                            action: "talk_with_recipient",
                            phase: "talk_about_items"
                        }
                    ]
                },
                {
                    id: "talk_about_items",
                    phase: "dialog",
                    view: "free_roam",
                    properties: {
                        dialog: [
                            {
                                speaker: "player",
                                text: "Zo, Lukas, bedankt voor alle informatie. Ik bezorg jou en de rest van het zorgteam zo snel mogelijk jouw zorgplan. Hou je goed en tot ziens!"
                            },
                            {
                                speaker: "lukas",
                                text: "Hartelijk dank voor al je werk <player.firstname>. Tot ziens!"
                            }
                        ],
                        scene: Zoro002FollowupHospitalRoomTalkRecipientScene
                    },
                    triggers: [
                        {
                            trigger: "dialog_end",
                            phase: "conclusion"
                        }
                    ]
                },
                {
                    id: "conclusion",
                    phase: "conclusion",
                    view: "free_roam",
                    properties: {
                        conclusion: {
                            title: "4. Follow up en ethiek",
                            description: "<p>Ethisch handelen gaat niet (enkel) over grote ethische vraagstukken, maar om het streven naar <strong>het goede in de alledaagse zorg</strong>. Het gaat om<strong> zorg die deugd doet aan beide kanten van de zorgrelatie</strong>, want alleen door een <strong>betrokken</strong> relatie met de zorgontvanger aan te gaan, kan je ontdekken wat goede zorg voor hem of haar betekent.&nbsp;</p>\n" +
                                "<p>Je hebt je verbeterplan <strong>omgezet in een actie</strong>! En de zorgontvanger is tevreden.</p>",
                            infoKeys: [ 'eh-added', 'score-intra', 'score-eh' ],
                            buttonText: "Bekijk dossier"
                        },
                        scene: Zoro002FollowupHospitalRoomTalkRecipientScene
                    },
                    actions: [
                        {
                            action: "next_step",
                            step: "plan"
                        }
                    ]
                }
            ],
            points: new GameScore({
                intrapreneurship: 3,
                actingEthically: 15
            }),
            fixedPoints: new GameScore({
                intrapreneurship: 3,
                actingEthically: 15
            })
        },
        {
            id: "plan",
            name: "Zorgplan opleveren",
            includeTodo: true,
            documents: [
                'recommendations'
            ],
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {
                        phone: {
                            hints: [
                                {
                                    text: "Je hebt je zorgplan opgesteld en opgeslagen in 'Bestanden'. Tijd om het op te sturen naar het zorgteam.",
                                    penalty: Constants.SCORE_TYPES.INTRA_PRENEURSHIP
                                }
                            ]
                        },
                        notification: {
                            app: "files",
                            header: {
                                icon: require("@/assets/images/UI/phone/icon_files.png"),
                                title: "Bestanden"
                            },
                            body: {
                                title: "Reminder",
                                description: "E-mail het zorgplan naar het zorgteam en de zorgvrager."
                            },
                            callToAction: {
                                type: "files",
                                text: "Open bestanden",
                                action: "click_files"
                            },
                            idleTime: 10000,
                            autoDisplay: 20000
                        }
                    },
                    actions: [
                        {
                            action: "click_files",
                            phase: "view_files"
                        }
                    ]
                },
                {
                    id: "view_files",
                    view: "documents",
                    title: "Bestanden",
                    properties: {
                        documents: [
                            "recommendations",
                            "careplan"
                        ],
                        selectedDocument: "careplan"
                    },
                    actions: [
                        {
                            action: "email_files",
                            popup: "confirm_email"
                        },
                        {
                            action: "confirm_send_email",
                            phase: "sending_email"
                        }
                    ]
                },
                {
                    id: "sending_email",
                    view: "sending_email",
                    title: "E-Mail",
                    triggers: [
                        {
                            trigger: "load-step",
                            auto: true,
                            duration: 2000,
                            step: "game-end"
                        }
                    ]
                }
            ]
        },
        {
            id: "game-end",
            includeTodo: false,
            phases: [
                {
                    id: "dashboard",
                    view: "dashboard",
                    properties: {},
                    triggers: [
                        {
                            trigger: "game-end",
                            finishGame: true,
                            auto: true
                        }
                    ]
                }
            ]
        }
    ],
    documents: [
        {
            id: "recommendations",
            name: "Aanbevelingen",
            icon: require('@/assets/images/UI/icons/icon_doctype_zoro.png'),
            care_question: "Lukas wil op een zo normaal mogelijke manier leven. Hij wil hulp om zijn behandelplan op te volgen zodat hij zelf de verantwoordelijkheid kan dragen over zijn gezondheid.",
            recommendations: [
                {
                    person: Zoro002Karim,
                    recommendation: 'Ik weet zeker dat technologische hulpmiddelen Lukas kunnen helpen om meer controle te hebben over zijn eetgewoontes.'
                },
                {
                    person: Zoro002Caroline,
                    recommendation: 'Ik merk dat Lukas zijn glycemiewaarden erg schommelen. Dit zou toch echt wel wat stabieler moeten worden. Anders gaat dit problemen opleveren in de toekomst.'
                },
                {
                    person: Zoro002Francesco,
                    recommendation: 'Als ik de zorgvraag van Lukas hoor, denk ik dat de informatie die ik gekregen heb tijdens de ZORO-training wel van nut kan zijn. Ik heb hier geleerd om gepaste technologische hulpmiddelen te vinden voor allerlei situaties. Ik stuur de link naar de informatie door naar jou.',
                },
                {
                    person: Zoro002Christien,
                    recommendation: 'Ik zal Lukas pas kunnen loslaten als hij zelf de verantwoordelijkheid over zijn gezondheid kan nemen.'
                },
                {
                    person: Zoro002Amina,
                    recommendation: 'Lukas vertelt mij dat hij een hekel heeft aan de dagelijkse vingerprikken.'
                },
                {
                    person: Zoro002Lukas,
                    recommendation: 'Ik wil een oplossing die mij helpt mijn huidige levensstijl te organiseren en mijn diabetes onder controle te houden.'
                }
            ]
        },
        {
            id: "careplan",
            name: "Zorgplan",
            icon: require('@/assets/images/UI/icons/icon_doctype_zoro.png'),
            pdf: require('@/assets/pdfs/zorgplan_02.pdf').default,
            images: [
                require('@/assets/pdfs/zorgplan_02_1.png'),
                require('@/assets/pdfs/zorgplan_02_2.png'),
                require('@/assets/pdfs/zorgplan_02_3.png')
            ],
            actions: [ "email", "download" ]
        }
    ],
    document: {
        situationInstructions: "Bezoek Lukas en kom te weten wat zijn situatie en zorgvraag is.<br>Ga op zoek naar 6 objecten om Lukas beter te leren kennen.",
        careInstructions: "Stel een zorgteam samen om Lukas zijn zorgvraag aan te pakken",
        techAidInstructions: "Adviseer een technologisch hulpmiddel voor Lukas op basis van zijn zorgvraag en aanbevelingen van het zorgteam",
        ethicalInstructions: "Ga langs bij Lukas voor een follow-upgesprek met betrekking tot zijn hulpmiddel. Misschien is dit een goed moment om ook het ethische aspect van zijn situatie te bekijken. Ga op zoek naar 3 ethische dilemma’s.",
        situation: "Mijn naam is Lukas, 19 jaar. Vorig jaar heb ik de diagnose diabetes type 1 gekregen.<br>" +
            "Hierdoor moet ik extra opletten bij activiteiten en met wat ik eet. Dit vind ik best moeilijk.<br>" +
            "<br>" +
            "Ik genoot ervan om zorgeloos samen met mijn vrienden te voetballen en te gaan supporteren. En nadien te blijven plakken in de kantine met een pak friet.  <br>" +
            "<br>" +
            "Ik heb een toffe groep vrienden en ik zou het heel erg vinden mocht ik nu buiten de groep vallen.  <br>" +
            "<br>" +
            "Ik moet leren verantwoordelijk om te gaan met wat en wanneer ik eet en drink. Dit is echt levensbelangrijk.",
        careTeam: [
            Zoro002Lukas,
            Zoro002Karim,
            Zoro002Caroline,
            Zoro002Francesco,
            Zoro002Amina,
            Zoro002Christien
        ],
        collectionItems: [
            LukasCellphone,
            MealCart,
            FootballScarf,
            LukasMom,
            Pizzabox,
            BookRack
        ],
        careDescription: "Lukas, man, 19 jaar, opgenomen in het ziekenhuis met een hypo, diabetes type 1, moeilijkheden met het opvolgen/toepassen van behandelplan.",
        careQuestion: "Lukas wil op een zo normaal mogelijke manier leven. Hij wil hulp om zijn behandelplan op te volgen zodat hij zelf de verantwoordelijkheid kan dragen over zijn gezondheid.",
        technologicalAid: GlucoseSensor,
        ethical: [
            {
                item: KarimInsuline,
                theorem: "Rollen en bevoegdheden",
                theoremDescription: "<p>Je ziet dat een diëtist insuline wil geven aan een zorgontvanger. Je hebt hier geen goed gevoel bij omdat je niet zeker weet of deze collega wel opgeleid is om deze zorghandeling uit te voeren. Spreek je die persoon hierop aan?</p>",
                feedbackYes: "<p>Correct!</p>" +
                    "<p>De diëtist eerst zelf aanspreken vraagt enige moed. Als je een collega handelingen ziet uitvoeren waarbij je je vragen stelt, is het belangrijk dat je hierover op een respectvolle manier in gesprek kan gaan met elkaar en met het team. Zo voorkom je incidenten in de toekomst.</p>",
                feedbackNo: "<p>Je gaf een fout antwoord, deze stelling is ethisch niet verantwoord.</p>" +
                    "<p>De diëtist eerst zelf aanspreken vraagt enige moed. Als je een collega handelingen ziet uitvoeren waarbij je je vragen stelt, is het belangrijk dat je hierover op een respectvolle manier in gesprek kan gaan met elkaar en met het team. Zo voorkom je incidenten in de toekomst.</p>",
                ethical: "Ja",
                correctAnswers: [ "yes" ]
            },
            {
                item: BrochureDiabetes,
                theorem: "Rechten en plichten",
                theoremDescription: "<p>De ouders van Lukas zijn zeer betrokken bij de zorg. Lukas gaat niet altijd akkoord met de beslissingen die zijn ouders voor hem nemen in functie van zijn behandeling.</p>" +
                    "<p>Kan Lukas zelfstandig beslissingen nemen zonder dat zijn ouders hiervan op de hoogte zijn?</p>",
                feedbackYes: "<p>Correct.</p>" +
                    "<p>Volgens de Wet op de Geneeskundige Behandelingsovereenkomst mag je vanaf 16 jaar zelf beslissingen nemen over je behandeling. Lukas mag advies vragen aan zijn ouders, maar de eindbeslissing ligt bij hem zelf.</p>",
                feedbackNo: "<p>Je gaf een fout antwoord, deze stelling is ethisch niet verantwoord.</p>" +
                    "<p>Volgens de Wet op de Geneeskundige Behandelingsovereenkomst mag je vanaf 16 jaar zelf beslissingen nemen over je behandeling. Lukas mag advies vragen aan zijn ouders, maar de eindbeslissing ligt bij hem zelf.</p>",
                ethical: "Ja",
                correctAnswers: [ "yes" ]
            },
            {
                item: Laptop,
                theorem: "Verjaardag",
                theoremDescription: "<p>Lukas is binnenkort jarig en wil dit graag uitgebreid vieren met zijn vrienden in het lokale jeugdhuis. Omwille van zijn diabetes is hij beperkt in wat hij kan eten en drinken, maar ach, voor een avondje kan dit toch geen kwaad. Mag je als diabetespatiënt uitzonderlijk eten en drinken wat je wil?</p>",
                feedbackYes: "<p>Je gaf een fout antwoord, deze stelling is ethisch niet verantwoord.</p>" +
                    "<p>Mensen met diabetes houden best wel rekening met een aantal zaken. Eén van deze aandachtspunten is drinken met mate (1 tot 2 alcoholconsumpties per dag). Het is belangrijk dat Lukas weet dat hij een glas alcohol steeds combineert met een koolhydraatrijke snack. Alcohol is namelijk vaak de oorzaak van onverwachte hypoglycemieën.</p>",
                feedbackNo: "<p>Correct</p>" +
                    "<p>Mensen met diabetes houden best wel rekening met een aantal zaken. Eén van deze aandachtspunten is drinken met mate (1 tot 2 alcoholconsumpties per dag). Het is belangrijk dat Lukas weet dat hij een glas alcohol steeds combineert met een koolhydraatrijke snack. Alcohol is namelijk vaak de oorzaak van onverwachte hypoglycemieën.</p>",
                ethical: "Nee",
                correctAnswers: [ "no" ]
            }
        ]
    },
});