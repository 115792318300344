import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";

export const Zoro001IntroFirstTalkRecipientScene = new PointAndClickScene({

    id: 'zoro-001-intro-first_talk_recipient',
    startLocation: 'entrance',
    showPhoneButton: true,
    locations: {
        'entrance': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro001/bg_c1_hall.png'),
                    pos: { left: -180, top: -180 },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'coatrack',
                    img: require('@/assets/images/scenes/zoro001/objects/_case1_general/coatrack.png'),
                    pos: { left: 100, top: 257 },
                    layer: 2,
                    interactable: false,
                    itemType: "item",
                }),
                new PointAndClickItem({
                    id: 'closedoor_wide',
                    img: require('@/assets/images/scenes/zoro001/objects/_doors/closedoor_wide.png'),
                    pos: { left: 299, top: 178 },
                    layer: 1,
                    interactable: false,
                    itemType: "item",
                    hoverImage: require('@/assets/images/scenes/zoro001/objects/_doors/opendoor_wide.png'),
                    dontAnimateOnHover: true,
                }),
                new PointAndClickItem({
                    id: 'closedoor_small',
                    img: require('@/assets/images/scenes/zoro001/objects/_doors/closedoor_small.png'),
                    pos: { left: 1211, top: 178 },
                    layer: 1,
                    interactable: false,
                    itemType: "item",
                    hoverImage: require('@/assets/images/scenes/zoro001/objects/_doors/closedoor_small.png'),
                    dontAnimateOnHover: true,
                }),
                new PointAndClickItem({
                    id: 'art_1',
                    img: require('@/assets/images/scenes/zoro001/objects/art_1/art_1.png'),
                    pos: { left: 1638, top: 225 },
                    layer: 2,
                    interactable: false,
                    itemType: "item",
                }),
                new PointAndClickItem({
                    id: 'drawer',
                    img: require('@/assets/images/scenes/zoro001/objects/drawer/drawer.png'),
                    pos: { left: 787, top: 613 },
                    layer: 2,
                    interactable: false,
                    itemType: "item",
                }),

                new PointAndClickItem({
                    id: 'coat',
                    img: require('@/assets/images/scenes/zoro001/objects/coat/coat.png'),
                    pos: { left: 76, top: 360 },
                    layer: 2,
                    interactable: false,
                    itemType: "item",
                }),
                new PointAndClickItem({
                    id: 'bikehelmet',
                    img: require('@/assets/images/scenes/zoro001/objects/bikehelmet/bikehelmet.png'),
                    pos: { left: 166, top: 270 },
                    layer: 2,
                    interactable: false,
                    itemType: "item",
                }),

                new PointAndClickItem({
                    id: 'recipient',
                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_full_body.png'),
                    imgElements: [
                        {
                            id: 'eyes',
                            pos: { left: 85, top: 77 },
                            layer: 2,
                            img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_full_eo.png'),
                            frames: [
                                {
                                    id: "open",
                                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_full_ec.png')
                                },
                                {
                                    id: "closed",
                                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_full_eo.png')
                                }
                            ],
                            timing: [
                                {
                                    duration: 50,
                                    frameId: "open"
                                },
                                {
                                    duration: 100,
                                    frameId: "closed"
                                },
                                {
                                    duration: 100,
                                    frameId: "open"
                                },
                                {
                                    duration: 3000,
                                    frameId: "closed"
                                },
                                {
                                    duration: 100,
                                    frameId: "open"
                                },
                                {
                                    duration: 2000,
                                    frameId: "closed"
                                },
                            ],
                        },
                        {
                            id: 'mouth',
                            pos: { left: 107, top: 105 },
                            layer: 2,
                            img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_full_mc.png'),
                            frames: [
                                {
                                    id: "closed",
                                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_full_mc.png')
                                },
                                {
                                    id: "open",
                                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_full_mo.png')
                                }
                            ],
                            timing: [
                                {
                                    duration: 0,
                                    frameId: "closed"
                                }
                            ],
                        }
                    ],
                    pos: { left: 651, top: 246 },
                    layer: 5,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    action: "talk_with_recipient",
                    name: "Praat met Stien",
                }),
            ],
        },
    },
});