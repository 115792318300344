import SaveState from "@/models/SaveState";
import GameScore from "@/models/GameScore";
import { event } from "vue-gtag";

const STORAGE_ALL_GAMES = "all-games";
const STORAGE_SAVE_STATE_KEY = "current-game-state";

const state = () => ({
    allGames: JSON.parse(localStorage.getItem(STORAGE_ALL_GAMES) ?? "[]"),
    saveState: new SaveState(JSON.parse(localStorage.getItem(STORAGE_SAVE_STATE_KEY) ?? "{}"))
});

const getters = {
    getSaveGame: (state) => (playerName, caseID, caseVersion) =>
    {
        return state.allGames.find(game => game.playerName === playerName && game.savedCaseInfo.id === caseID && game.savedCaseInfo.version === caseVersion);
    },
    getPlayerName(state)
    {
        return state.saveState.playerName;
    },
    getGameScore(state)
    {
        return state.saveState.score;
    },
    getActiveCaseInfo(state)
    {
        return state.saveState.savedCaseInfo;
    },
    getActiveStepInfo(state)
    {
        return state.saveState.savedStepInfo;
    },
    getActiveStepInfoValue: (state) => (key) =>
    {
        return state.saveState.savedStepInfo?.[key] || undefined;
    },
    getActivePhaseInfo(state)
    {
        return state.saveState.savedPhaseInfo;
    },
    getActivePhaseInfoValue: (state) => (key) =>
    {
        return state.saveState.savedPhaseInfo?.[key] || undefined;
    },
    getSavedCareTeam(state)
    {
        return state.saveState.savedCareTeam;
    },
    getAttemptedCareTeam(state)
    {
        return state.saveState.savedAttemptedCareTeam;
    },
    getSavedTechnologicalAids(state)
    {
        return state.saveState.savedTechnologicalAids;
    },
    getAttemptedTechnologicalAids(state)
    {
        return state.saveState.savedAttemptedTechnologicalAids;
    }
};

const actions = {
    startCase(context, caseInfo)
    {
        // TODO fetch case save template, set the case info and save it
        if (!caseInfo)
        {
            console.error("Expected case info ...");
            return;
        }

        event('start_case', { case_id: caseInfo.id, case_version: caseInfo.version });

        context.commit("setActiveCaseInfo", caseInfo);
        context.commit("setActiveStepInfo", undefined);
        context.commit("setActivePhaseInfo", undefined);
        context.commit("setSelectedCareTeam", []);
        context.commit("setAttemptedCareTeam", []);
        context.commit("setSelectedTechnologicalAids", []);
        context.commit("setAttemptedTechnologicalAids", []);
        context.commit("updateScore", new GameScore());
        context.commit("setFinished", false);
    },
    resumeGame(context, saveState)
    {
        if (!saveState)
        {
            console.error("Expected save state in order to resume a game");
            return;
        }

        event('resume_case', { caseID: saveState.savedCaseInfo.id, caseVersion: saveState.savedCaseInfo.version });

        context.commit("setActiveCaseInfo", saveState.savedCaseInfo);
        context.commit("setActiveStepInfo", saveState.savedStepInfo);
        context.commit("setActivePhaseInfo", saveState.savedPhaseInfo);
        context.commit("setSelectedCareTeam", saveState.savedCareTeam);
        context.commit("setAttemptedCareTeam", saveState.savedAttemptedCareTeam);
        context.commit("setSelectedTechnologicalAids", saveState.savedTechnologicalAids);
        context.commit("setAttemptedTechnologicalAids", saveState.savedAttemptedTechnologicalAids);
        context.commit("updateScore", saveState.gameScore)
        context.commit("setFinished", saveState.finished);
    },
    stopActiveCase(context)
    {
        context.commit("clearSaveState");
    }
};

const mutations = {
    updateName(state, name)
    {
        state.saveState.playerName = name;
        persistSaveState(state, state.saveState);
    },
    updateScore(state, gameScore)
    {
        state.saveState.score = gameScore;
        persistSaveState(state, state.saveState);
    },
    setActiveCaseInfo(state, caseInfo)
    {
        state.saveState.savedCaseInfo = caseInfo;
        persistSaveState(state, state.saveState);
    },
    setActiveStepInfo(state, stepInfo)
    {
        state.saveState.savedStepInfo = stepInfo;
        state.saveState.savedPhaseInfo = undefined;
        persistSaveState(state, state.saveState);
    },
    setActiveStepInfoKeyValue(state, { key, value })
    {
        if (state.saveState.savedStepInfo === undefined)
        {
            state.saveState.savedStepInfo = {};
        }

        state.saveState.savedStepInfo[key] = value;
        persistSaveState(state, state.saveState);
    },
    setActivePhaseInfo(state, phaseInfo)
    {
        state.saveState.savedPhaseInfo = phaseInfo;
        persistSaveState(state, state.saveState);
    },
    setActivePhaseInfoKeyValue(state, { key, value })
    {
        if (state.saveState.savedPhaseInfo === undefined)
        {
            state.saveState.savedPhaseInfo = {};
        }

        state.saveState.savedPhaseInfo[key] = value;
        persistSaveState(state, state.saveState);
    },
    setSelectedCareTeam(state, careTeam)
    {
        state.saveState.savedCareTeam = careTeam;
        persistSaveState(state, state.saveState);
    },
    setAttemptedCareTeam(state, attemptedCareTeam)
    {
        state.saveState.savedAttemptedCareTeam = attemptedCareTeam;
        persistSaveState(state, state.saveState);
    },
    setSelectedTechnologicalAids(state, technologicalAids)
    {
        state.saveState.savedTechnologicalAids = technologicalAids;
        persistSaveState(state, state.saveState);
    },
    setAttemptedTechnologicalAids(state, attemptedTechnologicalAids)
    {
        state.saveState.savedAttemptedTechnologicalAids = attemptedTechnologicalAids;
        persistSaveState(state, state.saveState);
    },
    setFinished(state, finished)
    {
        if (finished && state.saveState.finished != finished)
        {
            event('finish_case', {
                caseID: state.saveState.savedCaseInfo.id,
                caseVersion: state.saveState.savedCaseInfo.version
            });
        }

        state.saveState.finished = finished;
        persistSaveState(state, state.saveState);
    },
    updateSaveState(state, saveState)
    {
        state.saveState = new SaveState(saveState);
        persistSaveState(state, state.saveState);
    },
    clearSaveState(state)
    {
        state.saveState = new SaveState({});
        persistSaveState(state, state.saveState);
    },
    clearAllGames(state, options = {})
    {
        console.log(`Clearing all games with options`, options);
        state.saveState = new SaveState({
            playerName: options.playerName
        });
        state.allGames = state.allGames.filter(game => options.playerName && game.playerName !== options.playerName);
        persistSaveState(state, state.saveState);

        event('clear_all_games');
    }
};

function persistSaveState(state, saveState)
{
    if (saveState)
    {
        if (saveState.savedCaseInfo)
        {
            let gameIndex = state.allGames?.findIndex(game =>
                saveState.playerName === game.playerName &&
                saveState.savedCaseInfo.id === game.savedCaseInfo.id &&
                saveState.savedCaseInfo.version === game.savedCaseInfo.version
            );

            if (gameIndex < 0)
            {
                state.allGames.push(JSON.parse(saveState.stringify()));
            }
            else
            {
                state.allGames[gameIndex] = JSON.parse(saveState.stringify());
            }
        }

        localStorage.setItem(STORAGE_ALL_GAMES, JSON.stringify(state.allGames));
        localStorage.setItem(STORAGE_SAVE_STATE_KEY, saveState.stringify());
    }
    else
    {
        localStorage.removeItem(STORAGE_SAVE_STATE_KEY);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};