export default {
    STEP_NAMES: {
        INTRO: "intro",
        ASSEMBLY: "assembly",
        TECHNOLOGY: "technology",
        FOLLOWUP: "followup",
        PLAN: "plan",
        GAME_END: "game-end"
    },
    SCORE_TYPES: {
        INTRA_PRENEURSHIP: "intrapreneurship",
        INTER_PROFESSIONAL_COLLABORATION: "interprofessionalCollaboration",
        TECHNICAL_MANEUVERABILITY: "technicalManeuverability",
        ACTING_ETHICALLY: "actingEthically"
    }
}

export const MATERIAL_GUIDE_LINK = "https://www.provincieantwerpen.be/aanbod/dese/dienst-werk/zoro.html";
