import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const HomeView = () => import(/* webpackChunkName: "home" */ "@/views/home/HomeView");
const CookiePolicyView = () => import(/* webpackChunkName: "home" */ "@/views/policies/CookiePolicyView");
const OnboardingView = () => import(/* webpackChunkName: "onboarding" */ "@/views/onboarding/OnboardingView");
const CaseSelectionView = () => import(/* webpackChunkName: "onboarding" */ "@/views/onboarding/CaseSelectionView");
const GameView = () => import(/* webpackChunkName: "game" */ "@/views/game/GameView");
const TestIndex = () => import(/* webpackChunkName: "test" */ "@/views/_test/TestIndex");
const StateTest = () => import(/* webpackChunkName: "test" */ "@/views/_test/StateTest");
const UITest = () => import(/* webpackChunkName: "test" */ "@/views/_test/UITest");
const DialogueTest = () => import(/* webpackChunkName: "test" */ "@/views/_test/DialogueTest");
const PNCTest = () => import(/* webpackChunkName: "test" */ "@/views/_test/PointAndClickTest");
const FontReadabilityTest = () => import(/* webpackChunkName: "test" */ "@/views/_test/FontReadabilityTest");
const ScrollingTest = () => import(/* webpackChunkName: "test" */ "@/views/_test/ScrollingTest")

function validatePlayerName(to, from, next)
{
    if (store.getters['game/getPlayerName'])
    {
        return next();
    }
    else
    {
        return next({ name: 'onboarding' });
    }
}

function validateSelectedGame(to, from, next)
{
    if (store.getters["game/getActiveCaseInfo"])
    {
        return next();
    }
    else
    {
        return next({ name: "onboarding" });
    }
}

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: { title: "Home" }
    },
    {
        path: "/cookie-policy",
        name: "cookie-policy",
        component: CookiePolicyView,
        meta: { title: "Cookie policy" }
    },
    {
        path: "/player-selection",
        name: "onboarding",
        component: OnboardingView,
        meta: { title: 'Home' }
    },
    {
        path: "/case-selection",
        name: "case-selection",
        component: CaseSelectionView,
        beforeEnter: [ validatePlayerName ]
    },
    {
        path: "/game",
        name: "game",
        component: GameView,
        beforeEnter: [ validateSelectedGame ]
    },
    {
        path: "/game/manual",
        name: "game-manual",
        redirect: () =>
        {
            window.location.href = require("@/assets/website/Handleiding game.pdf").default;
        }
    },
    {
        path: "/qr/kaartspel",
        name: "card-game",
        redirect: () =>
        {
            window.location.href = require("@/assets/cardgame/Zoro Kaartspel.pdf").default;
        }
    },
    {
        path: "/qr/kaartspel/spelregels",
        name: "card-game-rules",
        redirect: () =>
        {
            window.location.href = require("@/assets/cardgame/Zoro kaartspel - Spelregels.pdf").default;
        }
    },
    {
        path: "/test",
        component: TestIndex,
        children: [
            {
                path: "game-state",
                name: "state-test",
                component: StateTest
            },
            {
                path: "ui",
                name: "ui-test",
                component: UITest
            },
            {
                path: "dialogue",
                name: "dialogue-test",
                component: DialogueTest
            },
            {
                path: "pnc",
                name: "pnc-test",
                component: PNCTest
            },
            {
                path: "font-readability",
                name: "font-readability-test",
                component: FontReadabilityTest
            },
            {
                path: "scrolling",
                name: "scrolling",
                component: ScrollingTest
            }
        ]
    },
    {
        path: "/:catchAll(.*)",
        redirect: { path: "/" }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition)
    {
        if (savedPosition)
        {
            return savedPosition;
        }
        if (to.hash)
        {
            return { el: to.hash };
        }
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) =>
{
    // Not inline because of 
    console.log(`Router from:`, from);
    console.log(`Router to:`, to);

    next();
});

router.afterEach((to, from, failure) =>
{
    console.log(`Router has loaded: `, to);
    console.log(`State: ${failure ? 'Failed' : 'Success'}`);
    if (!failure)
    {
        // sendToAnalytics(to.fullPath)
    }
})

export default router;