import PointAndClickScene from "@/models/PointAndClickScene";
import {
    BackgroundHallway,
    HallwayDoorRight,
    AminaBehindDesk, StickerDoor, Plant3Hallway, PosterMenu, PosterPlanner, Railing
} from "@/assets/data/objects/ZORO-003-objects";
import PointAndClickItem from "@/models/PointAndClickItem";

export const Zoro003InitialHome = new PointAndClickScene({
    id: 'zoro-003-intro-initial_home',
    startLocation: 'hallway',
    showPhoneButton: true,
    locations: {
        'hallway': {
            items: [
                BackgroundHallway,
                AminaBehindDesk,

                Plant3Hallway,
                PosterMenu,

                StickerDoor,
                new PointAndClickItem({
                    ...HallwayDoorRight,
                    interactable: true,
                    itemType: 'action',
                    hoverType: 'use',
                    action: 'knock_room_door',
                    name: 'Klop op de deur',
                }),

                PosterPlanner,
                Railing
            ]
        }
    }
})