export const Blink = {
    timing: [
        {
            duration: 50,
            frameId: "closed"
        },
        {
            duration: 100,
            frameId: "open"
        },
        {
            duration: 100,
            frameId: "closed"
        },
        {
            duration: 3000,
            frameId: "open"
        },
        {
            duration: 100,
            frameId: "closed"
        },
        {
            duration: 2000,
            frameId: "open"
        },
    ]
};

export const BlinkSimple = {
    timing: [
        {
            duration: 7000,
            frameId: "open"
        },
        {
            duration: 100,
            frameId: "closed"
        },
    ]
};

export const Talk = {
    timing: [
        {
            duration: 100,
            frameId: "open"
        },
        {
            duration: 100,
            frameId: "closed"
        },
    ],
}
    