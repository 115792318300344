import PointAndClickScene from "@/models/PointAndClickScene";
import {
    AminaInNurseStation,
    Aquarium,
    ArtFlamenco,
    BackgroundHallway,
    BackgroundNurseStation,
    BackgroundRecreationRoom,
    BackgroundRoomCarlos,
    BedAssistance,
    Blanket,
    CalenderRip2,
    CarlosRoomDoor, CartIsolation,
    CatRobot, Chair, ChristienInNurseStation,
    ClockDigitalDate2,
    ClockWallModern,
    ClockWallOld,
    Cup1,
    Cup2, EricaInNurseStation,
    FilingCabinet,
    FlowersGift,
    HallwayDoorRight, JackInHallway,
    MagazineStack, MiningHelmet,
    MiningLamp,
    MoveHitboxLeft,
    MoveHitboxRight,
    NurseStationRoomDoor,
    PaperTowelDispenser,
    PhotoCat,
    PhotoMiners,
    PhotoWedding,
    Plant3,
    Plant3Hallway,
    Plant4, PosterIsolation,
    PosterMenu,
    PosterPlanner,
    Railing, Rollator,
    StickerDoor,
    TableChairs,
    TVRecreationRoom2, Wheelchair
} from "@/assets/data/objects/ZORO-003-objects";
import PointAndClickItem from "@/models/PointAndClickItem";
import {
    ArtFlamencoCollectionItem,
    CalenderRipCollectionItem, CareTeamEthicalCollectionItem, CartIsolationCollectionItem,
    CatRobotEthicalCollectionItem, ClockWallModernCollectionItem, ClockWallOldCollectionItem,
    JackInHallwayCollectionItem, MagazineStackCollectionItem, PaperTowelDispenserCollectionItem,
    PhotoWeddingCollectionItem,
    PosterIsolationEthicalCollectionItem,
    PosterMenuCollectionItem,
    StickerDoorCollectionItem,
} from "@/assets/data/items/collection_items-ZORO-003";

export const Zoro003FollowupCollectItemsScene = new PointAndClickScene({
    id: 'zoro-003-followup-collect_items',
    type: 'ethical_question',
    showPhoneButton: true,
    startLocation: 'room-carlos',
    locations: {
        'room-carlos': {
            items: [
                BackgroundRoomCarlos,

                new PointAndClickItem({
                    ...ArtFlamenco,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: ArtFlamencoCollectionItem
                }),
                
                MiningLamp,
                PhotoCat,
                Plant4,
                ClockDigitalDate2,
                BedAssistance,
                Blanket,
                MiningHelmet,

                new PointAndClickItem({
                    ...CarlosRoomDoor,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang',
                }),
                Chair,
                new PointAndClickItem({
                    ...Wheelchair,
                    scale: { x: -1, y: 1 }
                }),


                new PointAndClickItem({
                    ...PhotoWedding,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: PhotoWeddingCollectionItem
                }),
                new PointAndClickItem({
                    ...CatRobot,
                    pos: { left: 588, top: 716 },
                    interactable: true,
                    itemType: 'ethical',
                    collectable: true,
                    itemData: CatRobotEthicalCollectionItem
                }),
            ]
        },
        'hallway': {
            items: [
                BackgroundHallway,

                Plant3Hallway,
                PosterPlanner,
                Railing,

                new PointAndClickItem({
                    ...StickerDoor,
                    interactable: false,
                    itemType: 'item',
                    collectable: false,
                    itemData: StickerDoorCollectionItem
                }),
                new PointAndClickItem({
                    ...HallwayDoorRight,
                    sound: undefined,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'room-carlos',
                    name: 'Ga naar kamer',
                    hoverName: 'Ga naar kamer'
                }),

                new PointAndClickItem({
                    ...PosterMenu,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: PosterMenuCollectionItem
                }),
                new PointAndClickItem({
                    ...PosterIsolation,
                    interactable: true,
                    itemType: 'ethical',
                    collectable: true,
                    itemData: PosterIsolationEthicalCollectionItem
                }),
                new PointAndClickItem({
                    ...CartIsolation,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: CartIsolationCollectionItem
                }),
                new PointAndClickItem({
                    ...JackInHallway,
                    scale: { x: -1, y: 1 },
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: JackInHallwayCollectionItem
                }),

                new PointAndClickItem({
                    ...MoveHitboxLeft,
                    id: 'to-nurse-station',
                    moveLocation: 'nurse-station',
                    name: 'Ga naar verpleegpost',
                    hoverName: 'Ga naar verpleegpost',
                }),
                new PointAndClickItem({
                    ...MoveHitboxRight,
                    id: 'to-recreation-room',
                    moveLocation: 'recreation-room',
                    name: 'Ga naar leefruimte',
                    hoverName: 'Ga naar leefruimte',
                })
            ]
        },
        'nurse-station': {
            items: [
                BackgroundNurseStation,

                Cup1,
                Cup2,
                FlowersGift,
                FilingCabinet,


                new PointAndClickItem({
                    ...NurseStationRoomDoor,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang'
                }),

                new PointAndClickItem({
                    ...ClockWallModern,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: ClockWallModernCollectionItem
                }),

                new PointAndClickItem({
                    ...PaperTowelDispenser,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: PaperTowelDispenserCollectionItem
                }),

                new PointAndClickItem({
                    ...AminaInNurseStation,
                    pos: { left: 209, top: 314 },
                    interactable: true,
                    itemType: 'ethical',
                    collectable: true,
                    itemData: CareTeamEthicalCollectionItem
                }),

                new PointAndClickItem({
                    ...EricaInNurseStation,
                    pos: { left: 452, top: 314 },
                    interactable: true,
                    itemType: 'ethical',
                    collectable: true,
                    itemData: CareTeamEthicalCollectionItem
                }),

                new PointAndClickItem({
                    ...ChristienInNurseStation,
                    pos: { left: 656, top: 314 },
                    interactable: true,
                    itemType: 'ethical',
                    collectable: true,
                    itemData: CareTeamEthicalCollectionItem
                }),
            ]
        },
        'recreation-room': {
            items: [
                BackgroundRecreationRoom,

                Plant3,
                TVRecreationRoom2,
                TableChairs,
                new PointAndClickItem({
                    ...Rollator,
                    pos: { left: 266 - 180 - 350 / 2, top: 1021 - 180 - 315 / 2 }
                }),
                PhotoMiners,
                Aquarium,

                new PointAndClickItem({
                    ...ClockWallOld,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: ClockWallOldCollectionItem
                }),
                new PointAndClickItem({
                    ...CalenderRip2,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: CalenderRipCollectionItem
                }),
                new PointAndClickItem({
                    ...MagazineStack,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: MagazineStackCollectionItem
                }),

                new PointAndClickItem({
                    ...MoveHitboxLeft,
                    id: 'to-hallway',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang',
                })
            ]
        }
    }
});