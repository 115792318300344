import CollectionItem from "@/models/CollectionItem";
import {
    Aquarium, ArtFlamenco,
    Bingo,
    Blanket, CalenderRip, CartIsolation, CartMedication, CatRobot, ClockWallModern, ClockWallOld, Cup1,
    FilingCabinet, FlowersGift, JackInHallway, MagazineStack,
    MiningLamp, PaperTowelDispenser,
    PhotoCat, PhotoMiners, PhotoWedding, PosterIsolation, PosterMenu, PosterPlanner, Railing,
    Rollator, StickerDoor, TVRecreationRoom
} from "@/assets/data/objects/ZORO-003-objects";

export const AquariumCollectionItem = new CollectionItem({
    id: Aquarium.id,
    img: Aquarium.img,
    icon: require('@/assets/images/scenes/zoro003/objects/aquarium/aquarium_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/aquarium/aquarium_400x400.png'),
    name: 'Aquarium met vissen',
    description: 'Dieren stimuleren zorgontvangers om in gesprek te gaan en ze vrolijken de ruimte op.',
    hoverName: '',
});

export const BingoCollectionItem = new CollectionItem({
    id: Bingo.id,
    img: Bingo.img,
    icon: require('@/assets/images/scenes/zoro003/objects/bingo/bingo_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/bingo/bingo_400x400.png'),
    name: 'Bingo',
    description: 'Wie zal deze keer als eerste “BINGO” kunnen roepen?',
    hoverName: '',
});

export const RollatorCollectionItem = new CollectionItem({
    id: Rollator.id,
    img: Rollator.img,
    icon: require('@/assets/images/scenes/zoro003/objects/rollator/rollator_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/rollator/rollator_400x400.png'),
    name: 'Rollator',
    description: 'Wie is er zijn rollator vergeten?',
    hoverName: '',
});

export const FilingCabinetCollectionItem = new CollectionItem({
    id: FilingCabinet.id,
    img: FilingCabinet.img,
    icon: require('@/assets/images/scenes/zoro003/objects/filing_cabinet/filing_cabinet_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/filing_cabinet/filing_cabinet_400x400.png'),
    name: 'Dossierkast',
    description: 'In deze mappen zit alle informatie van de bewoners.',
    hoverName: '',
});

export const BlanketCollectionItem = new CollectionItem({
    id: Blanket.id,
    img: Blanket.img,
    icon: require('@/assets/images/scenes/zoro003/objects/blanket_colourful/blanket_colourful_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/blanket_colourful/blanket_colourful_400x400.png'),
    name: 'Deken',
    description: 'Een deken om Carlos lekker warm te houden.',
    hoverName: '',
});

export const Cup1CollectionItem = new CollectionItem({
    id: Cup1.id,
    img: Cup1.img,
    icon: require('@/assets/images/scenes/zoro003/objects/cup_1/cup_1_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/cup_1/cup_1_400x400.png'),
    name: 'Koffie',
    description: 'Even tot rust komen na een drukke ochtendshift met een lekkere kop koffie.',
    hoverName: '',
});

export const RailingCollectionItem = new CollectionItem({
    id: Railing.id,
    img: Railing.img,
    icon: require('@/assets/images/scenes/zoro003/objects/railing/railing_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/railing/railing_400x400.png'),
    name: 'Wandelleuning',
    description: 'Dankzij dit hulpstuk kunnen bewoners zich veilig verplaatsen.',
    hoverName: '',
});

export const CartMedicationCollectionItem = new CollectionItem({
    id: CartMedication.id,
    img: CartMedication.img,
    icon: require('@/assets/images/scenes/zoro003/objects/cart_medication/cart_medication_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/cart_medication/cart_medication_400x400.png'),
    name: 'Medicatiekar',
    description: 'In deze kar wordt alle medicatie van de bewoners veilig opgeborgen.',
    hoverName: '',
});

export const TVRecreationRoomCollectionItem = new CollectionItem({
    id: TVRecreationRoom.id,
    img: TVRecreationRoom.img,
    icon: require('@/assets/images/scenes/zoro003/objects/tv_recroom/tv_recroom_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/tv_recroom/tv_recroom_400x400.png'),
    name: 'TV',
    description: 'Af en toe een klassieker op tv is een fijn ontspanningsmoment.',
    hoverName: '',
});

export const ArtFlamencoCollectionItem = new CollectionItem({
    id: ArtFlamenco.id,
    img: ArtFlamenco.img,
    icon: require('@/assets/images/scenes/zoro003/objects/art_flamenco/art_flamenco_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/art_flamenco/art_flamenco_400x400.png'),
    name: 'Flamenco poster',
    description: 'Flamenco, olé!',
    hoverName: '',
});

export const PhotoWeddingCollectionItem = new CollectionItem({
    id: PhotoWedding.id,
    img: PhotoWedding.img,
    icon: require('@/assets/images/scenes/zoro003/objects/photo_wedding/photo_wedding_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/photo_wedding/photo_wedding_400x400.png'),
    name: 'Huwelijksfoto',
    description: 'Een van de mooiste dagen uit Carlos leven.',
    hoverName: '',
});

export const CartIsolationCollectionItem = new CollectionItem({
    id: CartIsolation.id,
    img: CartIsolation.img,
    icon: require('@/assets/images/scenes/zoro003/objects/cart_isolation/cart_isolation_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/cart_isolation/cart_isolation_400x400.png'),
    name: 'Isolatiekar',
    description: 'Veiligheid komt altijd op de eerste plaats.',
    hoverName: '',
});

export const PosterMenuCollectionItem = new CollectionItem({
    id: PosterMenu.id,
    img: PosterMenu.img,
    icon: require('@/assets/images/scenes/zoro003/objects/poster_menu/poster_menu_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/poster_menu/poster_menu_400x400.png'),
    name: 'Weekmenu',
    description: 'Wat staat er vandaag op het menu?',
    hoverName: '',
});

export const JackInHallwayCollectionItem = new CollectionItem({
    id: JackInHallway.id,
    img: JackInHallway.img,
    icon: require('@/assets/images/scenes/zoro003/objects/ergotherapeut/ergotherapeut_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/ergotherapeut/ergotherapeut_400x400.png'),
    name: 'Ergotherapeut',
    description: 'De bewoners kunnen helpen om zelfstandiger te blijven functioneren is een droomjob.',
    hoverName: '',
});

export const ClockWallModernCollectionItem = new CollectionItem({
    id: ClockWallModern.id,
    img: ClockWallModern.img,
    icon: require('@/assets/images/scenes/zoro003/objects/clock_wall_modern/clock_wall_modern_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/clock_wall_modern/clock_wall_modern_400x400.png'),
    name: 'Klok',
    description: 'Al zo laat!? En nog zoveel te doen.',
    hoverName: '',
});

export const PaperTowelDispenserCollectionItem = new CollectionItem({
    id: PaperTowelDispenser.id,
    img: PaperTowelDispenser.img,
    icon: require('@/assets/images/scenes/zoro003/objects/paper_towel_dispenser/paper_towel_dispenser_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/paper_towel_dispenser/paper_towel_dispenser_400x400.png'),
    name: 'Papierdispenser',
    description: 'Papier is hygiënischer om je handen mee te drogen in vergelijking met een stoffen handdoek.',
    hoverName: '',
});

export const ClockWallOldCollectionItem = new CollectionItem({
    id: ClockWallOld.id,
    img: ClockWallOld.img,
    icon: require('@/assets/images/scenes/zoro003/objects/clock_wall_old/clock_wall_old_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/clock_wall_old/clock_wall_old_400x400.png'),
    name: 'Koekoeksklok',
    description: 'Zoals het klokje thuis tikt tikt het nergens.',
    hoverName: '',
});

export const CalenderRipCollectionItem = new CollectionItem({
    id: CalenderRip.id,
    img: CalenderRip.img,
    icon: require('@/assets/images/scenes/zoro003/objects/calender_rip/calender_rip_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/calender_rip/calender_rip_400x400.png'),
    name: 'Kalender',
    description: 'Er staat weer heel wat op het programma deze week.',
    hoverName: '',
});

export const MagazineStackCollectionItem = new CollectionItem({
    id: MagazineStack.id,
    img: MagazineStack.img,
    icon: require('@/assets/images/scenes/zoro003/objects/magazine_stack/magazine_stack_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/magazine_stack/magazine_stack_400x400.png'),
    name: 'Magazines',
    description: 'Altijd wel iets interessant om te lezen.',
    hoverName: '',
});

// Collectables

export const PhotoCatCollectionItem = new CollectionItem({
    id: PhotoCat.id,
    img: PhotoCat.img,
    icon: require('@/assets/images/scenes/zoro003/objects/photo_cat/photo_cat_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/photo_cat/photo_cat_400x400.png'),
    name: 'Foto met kat Fernando',
    description: 'Carlos, zijn vrouw en kinderen hadden doorheen hun leven verschillende huiskatten. Fernando is de laatste kat en woont nog steeds bij zijn mantelzorger Andreas.',
    hoverName: '',
});

export const MiningLampCollectionItem = new CollectionItem({
    id: MiningLamp.id,
    img: MiningLamp.img,
    icon: require('@/assets/images/scenes/zoro003/objects/mining_lamp/mining_lamp_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/mining_lamp/mining_lamp_400x400.png'),
    name: 'Mijnlamp',
    description: 'Carlos kwam als twintiger vanuit Spanje in de mijnen werken.',
    hoverName: '',
});

export const StickerDoorCollectionItem = new CollectionItem({
    id: StickerDoor.id,
    img: StickerDoor.img,
    icon: require('@/assets/images/scenes/zoro003/objects/sticker_door/sticker_door_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/sticker_door/sticker_door_400x400.png'),
    name: 'Deur',
    description: 'Door zijn dementie ervaart Carlos onrust, wegloopgedrag, desoriëntatie in tijd en plaats. Soms vindt Carlos zijn eigen kamer niet meer. De medewerkers van het woonzorgcentrum hebben voor alle bewoners een foto van hun ‘oude’ voordeur op de kamer geplakt. Zo vinden ze de weg naar hun eigen kamer.',
    hoverName: '',
});

export const PosterPlannerCollectionItem = new CollectionItem({
    id: PosterPlanner.id,
    img: PosterPlanner.img,
    icon: require('@/assets/images/scenes/zoro003/objects/poster_planner/poster_planner_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/poster_planner/poster_planner_400x400.png'),
    name: 'Familiedag mantelzorgers',
    description: 'Andreas communiceert als mantelzorger met de familie als er beslissingen genomen moeten worden, gaat in overleg met het zorgteam en neemt deel aan activiteiten.',
    hoverName: '',
});

export const PhotoMinersCollectionItem = new CollectionItem({
    id: PhotoMiners.id,
    img: PhotoMiners.img,
    icon: require('@/assets/images/scenes/zoro003/objects/photo_miners/photo_miners_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/photo_miners/photo_miners_400x400.png'),
    name: 'Familiedag naar de mijn.',
    description: 'Op deze foto zien jullie Carlos tijdens de laatste familie-uitstap naar de mijnen. Zijn gezin en familie zijn de trots in het leven van Carlos.',
    hoverName: '',
});

export const FlowersGiftCollectionItem = new CollectionItem({
    id: FlowersGift.id,
    img: FlowersGift.img,
    icon: require('@/assets/images/scenes/zoro003/objects/flowers_gift/flowers_gift_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/flowers_gift/flowers_gift_400x400.png'),
    name: 'Bos bloemen en pralines - helden van de zorg',
    description: 'Carlos blijft het belangrijk vinden om zijn dankbaarheid uit te drukken. Het is een waarde die hij via kleine gebaren blijft tonen aan het zorgteam en zijn familie.',
    hoverName: '',
});

// Ethical

export const PosterIsolationEthicalCollectionItem = new CollectionItem({
    id: PosterIsolation.id,
    img: PosterIsolation.img,
    icon: require('@/assets/images/scenes/zoro003/objects/poster_isolation/poster_isolation_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/poster_isolation/poster_isolation_400x400.png'),
    name: 'Isolatieblad',
    description: 'Geen bezoek tijdens quarantaine periode.',
    hoverName: '',
});

export const CareTeamEthicalCollectionItem = new CollectionItem({
    id: 'group-caregivers',
    img: undefined,
    icon: require('@/assets/images/scenes/zoro003/objects/group_caregivers/group_caregivers_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/group_caregivers/group_caregivers_400x400.png'),
    name: 'Veel zorgverleners samen in verpleegpost.',
    description: '',
    hoverName: '',
});

export const CatRobotEthicalCollectionItem = new CollectionItem({
    id: CatRobot.id,
    img: CatRobot.img,
    icon: require('@/assets/images/scenes/zoro003/objects/cat_robot/cat_robot_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro003/objects/cat_robot/cat_robot_400x400.png'),
    name: 'Snoezelkat',
    description: 'zorgrobot',
    hoverName: '',
});