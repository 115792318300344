export default class PointAndClickItem
{
    constructor(options)
    {
        options = options ?? {};

        this.id = options.id;
        this.img = options.img;
        this.map = options.map || null;
        this.imgElements = options.imgElements || null;
        this.detailImage = options.detailImage || null;
        this.iconImage = options.iconImage || null;
        //some items should switch image (instead of scale) on hover.
        this.hoverImage = options.hoverImage || null;
        this.pos = options.pos || { left: 0, top: 0 };
        this.scale = options.scale || { x: 1, y: 1 };
        this.rotation = options.rotation || 0;
        this.layer = options.layer || 0;
        this.animation = options.animation || false;
        this.dontAnimateOnHover = options.dontAnimateOnHover || false;
        this.interactable = options.interactable || false;
        this.collectable = options.collectable || false;
        this.sound = options.sound || null;
        this.moveLocation = options.moveLocation || null;
        this.itemType = options.itemType || "item";
        this.hoverType = options.hoverType || null;
        this.action = options.action || null;
        this.trigger = options.trigger || null;
        this.name = options.name || "";
        this.description = options.description || "";
        this.itemData = options.itemData || null;
        this.subtitle = options.subtitle || null;
        this.goalSeekerTime = options.goalSeekerTime || null;
    }
}