import Person from "@/models/Person";
import { BlinkSimple, Talk } from "@/assets/data/animations/animations";

/*
* Base people
*/

export const Stien = new Person({
    id: 'stien',
    name: 'Stien',

    avatar: require("@/assets/images/characters/zorgontvanger_c1/avatar_zorgontvanger_40x40.png"),
    avatarDashboard: require("@/assets/images/characters/zorgontvanger_c1/avatar_zorgontvanger_175x175_dashboard.png"),
    avatarDialog: require("@/assets/images/characters/zorgontvanger_c1/avatar_zorgontvanger_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/zorgontvanger_c1/avatar_zorgontvanger_60x60.png"),
    avatar160: require("@/assets/images/characters/zorgontvanger_c1/avatar_zorgontvanger_160x160.png"),
    background: require("@/assets/images/characters/zorgontvanger_c1/teamcall_bg_c1_zorgontvanger.png"),

    chatAvatar: {
        body: require("@/assets/images/characters/zorgontvanger_c1/zorgontvanger_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 146, 297 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 192, 238 ]

        },
    }
});

export const Amina = new Person({
    id: 'amina',
    name: 'Amina',
    function: 'Zorgkundige / Verzorgende',
    contact: true,

    avatar: require("@/assets/images/characters/verpleegkundige/avatar_verpleegkundige_40x40.png"),
    avatarDialog: require("@/assets/images/characters/verpleegkundige/avatar_verpleegkundige_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/verpleegkundige/avatar_verpleegkundige_60x60.png"),
    avatar160: require("@/assets/images/characters/verpleegkundige/avatar_verpleegkundige_160x160.png"),
    background: require("@/assets/images/characters/verpleegkundige/teamcall_bg_c1_zorgkundige.png"),
    chatAvatar: {
        body: require("@/assets/images/characters/verpleegkundige/verpleegkundige_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 172, 272 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 202, 216 ]

        },
    },
    meetingAvatar: {
        body: require("@/assets/images/characters/verpleegkundige/verpleegkundige_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/verpleegkundige/verpleegkundige_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/verpleegkundige/verpleegkundige_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 124, 343 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/verpleegkundige/verpleegkundige_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/verpleegkundige/verpleegkundige_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 146, 292 ]
        }
    }
});

export const Tine = new Person({
    id: 'tine',
    name: 'Tine',
    function: 'Huishoudhulp',
    description: 'Ik doe alleen huishoudelijke taken en geen verzorgende taken. Ik kom wekelijks 4u strijken en 4u poetsen bij Stien.',
    contact: true,

    avatar: require("@/assets/images/characters/huishoudhulp/avatar_huishoudhulp_40x40.png"),
    avatarDialog: require("@/assets/images/characters/huishoudhulp/avatar_huishoudhulp_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/huishoudhulp/avatar_huishoudhulp_60x60.png"),
    avatar160: require("@/assets/images/characters/huishoudhulp/avatar_huishoudhulp_160x160.png"),
    background: require("@/assets/images/characters/huishoudhulp/teamcall_bg_c1_huishoudhulp.png"),
    chatAvatar: {
        body: require("@/assets/images/characters/huishoudhulp/huishoudhulp_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 147, 276 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 174, 216 ]

        },
    }
});

export const Jan = new Person({
    id: 'jan',
    name: 'Jan',
    function: 'Mantelzorger',
    description: 'Ik ben de levenspartner van Stien. Wij zijn volgend jaar 15 jaar samen.',
    contact: true,

    avatar: require("@/assets/images/characters/mantelzorger/avatar_mantelzorger_40x40.png"),
    avatarDialog: require("@/assets/images/characters/mantelzorger/avatar_mantelzorger_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/mantelzorger/avatar_mantelzorger_60x60.png"),
    avatar160: require("@/assets/images/characters/mantelzorger/avatar_mantelzorger_160x160.png"),
    background: require("@/assets/images/characters/mantelzorger/teamcall_bg_c1_mantelzorger.png"),
    chatAvatar: {
        body: require("@/assets/images/characters/mantelzorger/mantelzorger_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/mantelzorger/mantelzorger_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/mantelzorger/mantelzorger_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 171, 318 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/mantelzorger/mantelzorger_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/mantelzorger/mantelzorger_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 177, 229 ]

        },
    }
});

export const Julia = new Person({
    id: 'julia',
    name: 'Julia',
    function: 'Thuisverpleegkundige',
    description: 'Ik kom twee keer per week langs om de wondverzorging van Stien te doen.',
    contact: true,

    avatar: require("@/assets/images/characters/thuisverpleegkundige/avatar_thuisverpleegkundige_40x40.png"),
    avatarDialog: require("@/assets/images/characters/thuisverpleegkundige/avatar_thuisverpleegkundige_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/thuisverpleegkundige/avatar_thuisverpleegkundige_60x60.png"),
    avatar160: require("@/assets/images/characters/thuisverpleegkundige/avatar_thuisverpleegkundige_160x160.png"),
    background: require("@/assets/images/characters/thuisverpleegkundige/teamcall_bg_c1_thuisverpleegkundige.png"),
    chatAvatar: {
        body: require("@/assets/images/characters/thuisverpleegkundige/thuisverpleegkundige_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/thuisverpleegkundige/thuisverpleegkundige_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/thuisverpleegkundige/thuisverpleegkundige_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 163, 270 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/thuisverpleegkundige/thuisverpleegkundige_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/thuisverpleegkundige/thuisverpleegkundige_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 196, 215 ]

        },
    }
});

export const Annika = new Person({
    id: 'annika',
    name: 'Annika',
    function: 'Kinesitherapeut / Fysiotherapeut',
    description: 'Ik oefen vier keer per week met Stien op evenwicht en spierversterking. Ik geef haar ook kleine, eenvoudige oefeningen mee om thuis te doen.',
    contact: true,

    avatar: require("@/assets/images/characters/kinesitherapeut/avatar_kinesitherapeut_40x40.png"),
    avatarDialog: require("@/assets/images/characters/kinesitherapeut/avatar_kinesitherapeut_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/kinesitherapeut/avatar_kinesitherapeut_60x60.png"),
    avatar160: require("@/assets/images/characters/kinesitherapeut/avatar_kinesitherapeut_160x160.png"),
    background: require("@/assets/images/characters/kinesitherapeut/teamcall_bg_c1_kine.png"),
    chatAvatar: {
        body: require("@/assets/images/characters/kinesitherapeut/kinesitherapeut_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/kinesitherapeut/kinesitherapeut_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/kinesitherapeut/kinesitherapeut_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 202, 282 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/kinesitherapeut/kinesitherapeut_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/kinesitherapeut/kinesitherapeut_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 231, 223 ]

        },
    },
    meetingAvatar: {
        body: require("@/assets/images/characters/kinesitherapeut/kinesitherapeut_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/kinesitherapeut/kinesitherapeut_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/kinesitherapeut/kinesitherapeut_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 126, 342 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/kinesitherapeut/kinesitherapeut_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/kinesitherapeut/kinesitherapeut_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 145, 291 ]
        }
    }
});

export const Karim = new Person({
    id: 'karim',
    name: 'Karim',
    function: 'Diëtist',
    description: 'Ik geef voedingstips aan Stien en stel samen met haar een voedingsplan op om overgewicht en winderigheid tegen te gaan.',
    contact: true,

    avatar: require("@/assets/images/characters/dietist/avatar_dietist_40x40.png"),
    avatarDialog: require("@/assets/images/characters/dietist/avatar_dietist_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/dietist/avatar_dietist_60x60.png"),
    avatar160: require("@/assets/images/characters/dietist/avatar_dietist_160x160.png"),
    background: require("@/assets/images/characters/dietist/teamcall_bg_c1_dietist.png"),
    chatAvatar: {
        body: require("@/assets/images/characters/dietist/dietist_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/dietist/dietist_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/dietist/dietist_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 183, 284 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/dietist/dietist_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/dietist/dietist_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 200, 217 ]

        },
    },
    meetingAvatar: {
        body: require("@/assets/images/characters/dietist/dietist_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/dietist/dietist_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/dietist/dietist_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 138, 347 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/dietist/dietist_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/dietist/dietist_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 151, 284 ]
        }
    }
});

export const Josefien = new Person({
    id: 'josefien',
    name: 'Josefien',
    function: 'Huisarts',
    contact: true,

    avatar: require("@/assets/images/characters/huisarts/avatar_huisarts_40x40.png"),
    avatarDialog: require("@/assets/images/characters/huisarts/avatar_huisarts_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/huisarts/avatar_huisarts_60x60.png"),
    avatar160: require("@/assets/images/characters/huisarts/avatar_huisarts_160x160.png"),
    background: require("@/assets/images/characters/huisarts/teamcall_bg_c1_huisarts.png"),
    chatAvatar: {
        body: require("@/assets/images/characters/huisarts/huisarts_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/huisarts/huisarts_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/huisarts/huisarts_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 169, 313 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/huisarts/huisarts_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/huisarts/huisarts_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 198, 253 ]

        },
    },
    meetingAvatar: {
        body: require("@/assets/images/characters/huisarts/huisarts_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/huisarts/huisarts_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/huisarts/huisarts_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 138, 356 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/huisarts/huisarts_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/huisarts/huisarts_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 163, 303 ]
        }
    }
});

export const Guy = new Person({
    id: 'guy',
    name: 'Guy',
    function: 'Revalidatiearts',
    description: 'Ik ben de vertrouwenspersoon van Stien. Ik denk mee na over haar levensdoelen en hoe we deze kunnen realiseren. Daarnaast zorg ik ervoor dat de zenuwpijnen onder controle blijven.',
    contact: true,

    avatar: require("@/assets/images/characters/revalidatie-arts/avatar_revalidatie-arts_40x40.png"),
    avatarDialog: require("@/assets/images/characters/revalidatie-arts/avatar_revalidatie-arts_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/revalidatie-arts/avatar_revalidatie-arts_60x60.png"),
    avatar160: require("@/assets/images/characters/revalidatie-arts/avatar_revalidatie-arts_160x160.png"),
    background: require("@/assets/images/characters/revalidatie-arts/teamcall_bg_c1_reva-arts.png"),
    chatAvatar: {
        body: require("@/assets/images/characters/revalidatie-arts/revalidatie-arts_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/revalidatie-arts/revalidatie-arts_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/revalidatie-arts/revalidatie-arts_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 215, 325 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/revalidatie-arts/revalidatie-arts_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/revalidatie-arts/revalidatie-arts_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 231, 262 ]

        },
    }
});

export const Andreas = new Person({
    id: 'bob',
    name: 'Andreas',
    function: 'Vrijwilliger',
    description: 'Ik ga drie keer per week wandelen met Spence, de hond.',
    contact: true,

    avatar: require("@/assets/images/characters/vrijwilliger/avatar_vrijwilliger_40x40.png"),
    avatarDialog: require("@/assets/images/characters/vrijwilliger/avatar_vrijwilliger_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/vrijwilliger/avatar_vrijwilliger_60x60.png"),
    avatar160: require("@/assets/images/characters/vrijwilliger/avatar_vrijwilliger_160x160.png"),
    background: require("@/assets/images/characters/vrijwilliger/teamcall_bg_c1_vrijwilliger.png"),
    chatAvatar: {
        body: require("@/assets/images/characters/vrijwilliger/vrijwilliger_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 205, 297 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 224, 211 ]

        },
    },
    meetingAvatar: {
        body: require("@/assets/images/characters/vrijwilliger/vrijwilliger_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/vrijwilliger/vrijwilliger_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/vrijwilliger/vrijwilliger_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 158, 364 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/vrijwilliger/vrijwilliger_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/vrijwilliger/vrijwilliger_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 170, 293 ]
        }
    }
});

export const Jack = new Person({
    id: 'jack',
    name: 'Jack',
    function: 'Ergotherapeut',
    description: 'Ik zorgde mee voor de aanpassingen in het huis van Stien. Van technologie tot aangepaste deuren.',
    contact: true,

    avatar: require("@/assets/images/characters/ergotherapeut/avatar_ergotherapeut_40x40.png"),
    avatarDialog: require("@/assets/images/characters/ergotherapeut/avatar_ergotherapeut_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/ergotherapeut/avatar_ergotherapeut_60x60.png"),
    avatar160: require("@/assets/images/characters/ergotherapeut/avatar_ergotherapeut_160x160.png"),
    background: require("@/assets/images/characters/ergotherapeut/teamcall_bg_c1_ergo.png"),
    chatAvatar: {
        body: require("@/assets/images/characters/ergotherapeut/ergotherapeut_teamcall_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_teamcall_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_teamcall_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 214, 302 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_teamcall_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_teamcall_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 230, 241 ]

        },
    },
    meetingAvatar: {
        body: require("@/assets/images/characters/ergotherapeut/ergotherapeut_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_meetingroom_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_meetingroom_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 190, 357 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_meetingroom_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_meetingroom_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 197, 298 ]

        },
    }
});

export const Lukas = new Person({
    id: 'lukas',
    name: 'Lukas',
    function: 'Zorgontvanger',
    description: '',
    contact: true,

    avatar: require("@/assets/images/characters/zorgontvanger_c2/avatar_zorgontvanger_c2_40x40.png"),
    avatarDashboard: require("@/assets/images/characters/zorgontvanger_c2/avatar_zorgontvanger_c2_175x175.png"),
    avatarDialog: require("@/assets/images/characters/zorgontvanger_c2/avatar_zorgontvanger_c2_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/zorgontvanger_c2/avatar_zorgontvanger_c2_60x60.png"),
    avatar160: require("@/assets/images/characters/zorgontvanger_c2/avatar_zorgontvanger_c2_160x160.png"),
    background: undefined,

    chatAvatar: undefined,
    meetingAvatar: {
        body: require("@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 156, 360 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 175, 303 ]
        }
    }
});

export const Caroline = new Person({
    id: 'caroline',
    name: 'Caroline',
    function: 'Endocrinoloog',
    contact: true,

    avatar: require("@/assets/images/characters/endocrinoloog/avatar_endocrinoloog_40x40.png"),
    avatarDialog: require("@/assets/images/characters/endocrinoloog/avatar_endocrinoloog_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/endocrinoloog/avatar_endocrinoloog_60x60.png"),
    avatar160: require("@/assets/images/characters/endocrinoloog/avatar_endocrinoloog_160x160.png"),
    meetingAvatar: {
        body: require("@/assets/images/characters/endocrinoloog/endocrinoloog_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/endocrinoloog/endocrinoloog_meetingroom_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/endocrinoloog/endocrinoloog_meetingroom_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 81, 312 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/endocrinoloog/endocrinoloog_meetingroom_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/endocrinoloog/endocrinoloog_meetingroom_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 109, 261 ]

        },
    }
});

export const Francesco = new Person({
    id: 'francesco',
    name: 'Francesco',
    function: 'Diabetes educator',
    contact: true,

    avatar: require("@/assets/images/characters/diabetuseducator/avatar_diabetuseducator_40x40.png"),
    avatarDialog: require("@/assets/images/characters/diabetuseducator/avatar_diabetuseducator_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/diabetuseducator/avatar_diabetuseducator_60x60.png"),
    avatar160: require("@/assets/images/characters/diabetuseducator/avatar_diabetuseducator_160x160.png"),
    meetingAvatar: {
        body: require("@/assets/images/characters/diabetuseducator/diabetuseducator_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/diabetuseducator/diabetuseducator_meetingroom_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/diabetuseducator/diabetuseducator_meetingroom_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 140, 376 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/diabetuseducator/diabetuseducator_meetingroom_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/diabetuseducator/diabetuseducator_meetingroom_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 156, 317 ]

        },
    }
});

export const Christien = new Person({
    id: 'christien',
    name: 'Christien',
    function: 'Mama Lukas',
    contact: true,

    avatar: require("@/assets/images/characters/mantelzorger_c2/avatar_mantelzorger_c2_40x40.png"),
    avatarDialog: require("@/assets/images/characters/mantelzorger_c2/avatar_mantelzorger_c2_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/mantelzorger_c2/avatar_mantelzorger_c2_60x60.png"),
    avatar160: require("@/assets/images/characters/mantelzorger_c2/avatar_mantelzorger_c2_160x160.png"),

    meetingAvatar: {
        body: require("@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 82, 339 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 91, 288 ]
        }
    }
});

export const Jaap = new Person({
    id: 'jaap',
    name: 'Jaap',
    function: 'Podoloog',
    contact: true,

    avatar: require("@/assets/images/characters/podoloog/avatar_podoloog_40x40.png"),
    avatarDialog: require("@/assets/images/characters/podoloog/avatar_podoloog_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/podoloog/avatar_podoloog_60x60.png"),
    avatar160: require("@/assets/images/characters/podoloog/avatar_podoloog_160x160.png"),
    meetingAvatar: {
        body: require("@/assets/images/characters/podoloog/podoloog_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/podoloog/podoloog_meetingroom_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/podoloog/podoloog_meetingroom_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 124, 385 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/podoloog/podoloog_meetingroom_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/podoloog/podoloog_meetingroom_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 127, 322 ]

        },
    }
});

export const Iris = new Person({
    id: 'iris',
    name: 'Iris',
    function: 'Oogarts',
    contact: true,

    avatar: require("@/assets/images/characters/oogarts/avatar_oogarts_40x40.png"),
    avatarDialog: require("@/assets/images/characters/oogarts/avatar_oogarts_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/oogarts/avatar_oogarts_60x60.png"),
    avatar160: require("@/assets/images/characters/oogarts/avatar_oogarts_160x160.png"),
    meetingAvatar: {
        body: require("@/assets/images/characters/oogarts/oogarts_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/oogarts/oogarts_meetingroom_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/oogarts/oogarts_meetingroom_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 86, 329 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/oogarts/oogarts_meetingroom_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/oogarts/oogarts_meetingroom_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 97, 275 ]

        },
    }
});

export const Esther = new Person({
    id: 'esther',
    name: 'Esther',
    function: 'Logistiek',
    contact: true,

    avatar: require("@/assets/images/characters/logistiek-medewerker/avatar_logistiek-medewerker_40x40.png"),
    avatarDialog: require("@/assets/images/characters/logistiek-medewerker/avatar_logistiek-medewerker_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/logistiek-medewerker/avatar_logistiek-medewerker_60x60.png"),
    avatar160: require("@/assets/images/characters/logistiek-medewerker/avatar_logistiek-medewerker_160x160.png"),
    meetingAvatar: {
        body: require("@/assets/images/characters/logistiek-medewerker/logistiek-medewerker_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require('@/assets/images/characters/logistiek-medewerker/logistiek-medewerker_meetingroom_eo.png')
                },
                {
                    id: "closed",
                    img: require('@/assets/images/characters/logistiek-medewerker/logistiek-medewerker_meetingroom_ec.png')
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 133, 294 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require('@/assets/images/characters/logistiek-medewerker/logistiek-medewerker_meetingroom_mc.png')
                },
                {
                    id: "open",
                    img: require('@/assets/images/characters/logistiek-medewerker/logistiek-medewerker_meetingroom_mo.png')
                }
            ],
            timing: Talk.timing,
            position: [ 160, 236 ]

        },
    }
});

export const Carlos = new Person({
    id: 'carlos',
    name: 'Carlos',

    avatar: require("@/assets/images/characters/zorgontvanger_c3/avatar_zorgontvanger_c3_40x40.png"),
    avatarDashboard: require("@/assets/images/characters/zorgontvanger_c3/avatar_zorgontvanger_c3_175x175.png"),
    avatarDialog: require("@/assets/images/characters/zorgontvanger_c3/avatar_zorgontvanger_c3_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/zorgontvanger_c3/avatar_zorgontvanger_c3_60x60.png"),
    avatar160: require("@/assets/images/characters/zorgontvanger_c3/avatar_zorgontvanger_c3_160x160.png"),
    meetingAvatar: {
        body: require("@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 194, 314 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 200, 245 ]
        }
    }
});

export const Erica = new Person({
    id: 'erica',
    name: 'Erica',

    avatar: require("@/assets/images/characters/referentiepersoon_dementie/avatar_refpersoon_dementie_40x40.png"),
    avatarDialog: require("@/assets/images/characters/referentiepersoon_dementie/avatar_refpersoon_dementie_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/referentiepersoon_dementie/avatar_refpersoon_dementie_60x60.png"),
    avatar160: require("@/assets/images/characters/referentiepersoon_dementie/avatar_refpersoon_dementie_160x160.png"),
    meetingAvatar: {
        body: require("@/assets/images/characters/referentiepersoon_dementie/refpersoon_dementie_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/referentiepersoon_dementie/refpersoon_dementie_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/referentiepersoon_dementie/refpersoon_dementie_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 130, 343 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/referentiepersoon_dementie/refpersoon_dementie_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/referentiepersoon_dementie/refpersoon_dementie_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 147, 293 ]
        }
    }
});

export const An = new Person({
    id: 'an',
    name: 'An',

    avatar: require("@/assets/images/characters/geriater/avatar_geriater_40x40.png"),
    avatarDialog: require("@/assets/images/characters/geriater/avatar_geriater_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/geriater/avatar_geriater_60x60.png"),
    avatar160: require("@/assets/images/characters/geriater/avatar_geriater_160x160.png"),
    meetingAvatar: {
        body: require("@/assets/images/characters/geriater/geriater_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/geriater/geriater_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/geriater/geriater_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 194, 366 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/geriater/geriater_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/geriater/geriater_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 218, 310 ]
        }
    }
});

export const Bas = new Person({
    id: 'bas',
    name: 'Bas',

    avatar: require("@/assets/images/characters/animatieverantwoordelijke/avatar_animatieverantw_40x40.png"),
    avatarDialog: require("@/assets/images/characters/animatieverantwoordelijke/avatar_animatieverantw_100x100.png"),
    avatarRecommendationDocument: require("@/assets/images/characters/animatieverantwoordelijke/avatar_animatieverantw_60x60.png"),
    avatar160: require("@/assets/images/characters/animatieverantwoordelijke/avatar_animatieverantw_160x160.png"),
    meetingAvatar: {
        body: require("@/assets/images/characters/animatieverantwoordelijke/animatieverantw_meetingroom_body.png"),
        eyes: {
            frames: [
                {
                    id: "open",
                    img: require("@/assets/images/characters/animatieverantwoordelijke/animatieverantw_meetingroom_eo.png")
                },
                {
                    id: "closed",
                    img: require("@/assets/images/characters/animatieverantwoordelijke/animatieverantw_meetingroom_ec.png")
                }
            ],
            timing: BlinkSimple.timing,
            position: [ 47, 364 ]
        },
        mouth: {
            frames: [
                {
                    id: "closed",
                    img: require("@/assets/images/characters/animatieverantwoordelijke/animatieverantw_meetingroom_mc.png")
                },
                {
                    id: "open",
                    img: require("@/assets/images/characters/animatieverantwoordelijke/animatieverantw_meetingroom_mo.png")
                }
            ],
            timing: Talk.timing,
            position: [ 48, 295 ]
        }
    }
});

/*
* Case 1                                            
*/

export const Zoro001Stien = new Person({
    ...Stien,
    function: 'Zorgontvanger',
    description: '',
    contact: true
});

export const Zoro001Amina = new Person({
    ...Amina,
    description: 'Ik help Stien dagelijks bij het wassen, aankleden en soms bij het maken van eten.',
    contact: true,
});

export const Zoro001Tine = new Person({
    ...Tine,
    description: 'Ik doe alleen huishoudelijke taken en geen verzorgende taken. Ik kom wekelijks 4u strijken en 4u poetsen bij Stien.',
    contact: true,
});

export const Zoro001Jan = new Person({
    ...Jan,
    function: 'Mantelzorger',
    description: 'Ik ben de levenspartner van Stien. Wij zijn volgend jaar 15 jaar samen.',
    contact: true,
});

export const Zoro001Julia = new Person({
    ...Julia,
    description: 'Ik kom twee keer per week langs om de wondverzorging van Stien te doen.',
    contact: true,
});

export const Zoro001Annika = new Person({
    ...Annika,
    description: 'Ik oefen vier keer per week met Stien op evenwicht en spierversterking. Ik geef haar ook kleine, eenvoudige oefeningen mee om thuis te doen.',
    contact: true,
});

export const Zoro001Karim = new Person({
    ...Karim,
    description: 'Ik geef voedingstips aan Stien en stel samen met haar een voedingsplan op om overgewicht en winderigheid tegen te gaan.',
    contact: true,
});

export const Zoro001Josefien = new Person({
    ...Josefien,
    description: 'Ik help Stien bij heel praktische zaken zoals het voorschrijven van medicatie.',
    contact: true,
});

export const Zoro001Guy = new Person({
    ...Guy,
    description: 'Ik ben de vertrouwenspersoon van Stien. Ik denk mee na over haar levensdoelen en hoe we deze kunnen realiseren. Daarnaast zorg ik ervoor dat de zenuwpijnen onder controle blijven.',
    contact: true,
});

export const Zoro001Andreas = new Person({
    ...Andreas,
    description: 'Ik ga drie keer per week wandelen met Spence, de hond.',
    contact: true,
});

export const Zoro001Jack = new Person({
    ...Jack,
    description: 'Ik zorgde mee voor de aanpassingen in het huis van Stien. Van technologie tot aangepaste deuren.',
    contact: true,
});

/*
* Case 2
* */
export const Zoro002Lukas = new Person({
    ...Lukas,
    function: 'Zorgontvanger',
    description: '',
    contact: true,
});

export const Zoro002Karim = new Person({
    ...Karim,
    description: "Ik stel samen met Lukas een haalbaar voedingsplan op.",
    contact: true,
});

export const Zoro002Caroline = new Person({
    ...Caroline,
    description: "Ik sta in voor de medische behandeling van Lukas. Ik volg zijn glycemiewaarden nauwkeurig op en pas het medicatieschema aan indien nodig.",
    contact: true,
});

export const Zoro002Francesco = new Person({
    ...Francesco,
    description: "Ik begeleid Lukas in het proces. Ik leer Lukas hoe hij moet omgaan met alle veranderingen en zorg ervoor dat hij alles begrijpt.",
    contact: true
});

export const Zoro002Amina = new Person({
    ...Amina,
    description: "Ik noteer Lukas zijn glycemie 3 keer per dag.",
    contact: true
});

export const Zoro002Christien = new Person({
    ...Christien,
    description: "Ik ben de mama van Lukas. Hij is onze oudste zoon en ik vind het mijn verantwoordelijkheid om hem te helpen bij de aanvaarding van zijn diagnose.",
    contact: true
});

export const Zoro002Josefien = new Person({
    ...Josefien,
    description: "Ik heb Lukas doorverwezen naar het ziekenhuis zes maanden geleden. Dankzij deze doorverwijzing is diabetes type 1 vastgesteld.",
    contact: true
});

export const Zoro002Jaap = new Person({
    ...Jaap,
    description: "Ik geef podologiesessies aan patiënten met diabetes type 2.",
    contact: true
});

export const Zoro002Iris = new Person({
    ...Iris,
    description: "Ik controleer preventief de ogen van Lukas. Als je diabetes hebt, bestaat de kans dat je ogen slechter worden. Elk jaar komt Lukas 1 keer bij mij langs voor een oogtest.",
    contact: true
});

export const Zoro002Esther = new Person({
    ...Esther,
    description: "Ik sta in voor de dagelijkse orde en netheid op de kamer van Lukas. Daarnaast breng ik ook het eten rond.",
    contact: true
});

export const Zoro002Annika = new Person({
    ...Annika,
    description: "Ik zorg ervoor dat Lukas actief bezig blijft. Sporten is gezond en Lukas is goed bezig.",
    contact: true
});

/*
* Case 3
* */
export const Zoro003Carlos = new Person({
    ...Carlos,
    function: 'Zorgontvanger',
    description: '',
    contact: true
});

export const Zoro003Andreas = new Person({
    ...Andreas,
    function: "Mantelzorger",
    description: "Ik ben Carlos’ kleinzoon en mantelzorger. Ik sta mee in voor het nemen van beslissingen en ondersteun de zorg- en woonbegeleiding. Ik ben de spreekbuis voor Carlos als hij dat zelf (even) niet kan.",
    contact: true
});

export const Zoro003Amina = new Person({
    ...Amina,
    function: "Zorgkundige",
    description: "Ik begeleid Carlos bij alle verzorgende taken zoals wassen, aankleden, mictietraining, eten.",
    contact: true
});

export const Zoro003Josefien = new Person({
    ...Josefien,
    function: "Huisarts",
    description: "Ik help Carlos bij medische zaken zoals het aanpassen van de medicatie en het zoeken naar hulpmiddelen.",
    contact: true
});

export const Zoro003Jack = new Person({
    ...Jack,
    function: "Ergotherapeut",
    description: "Ik zorg mee voor de aanpassingen in de woon- en zorgcontext van Carlos. Van kamersensoren tot aangepast bestek.",
    contact: true
});

export const Zoro003Erica = new Person({
    ...Erica,
    function: "Referentiepersoon dementie",
    description: "Ik ben het aanspreekpunt om meer te weten over de visie en aanpak betreft dementie binnen het woonzorgcentrum. Ik ben ook verpleegkundige op de afdeling van Carlos.",
    contact: true
});

export const Zoro003Christien = new Person({
    ...Christien,
    function: "Woon- en leefbegeleidster",
    description: "Ik zorg voor een huiselijke sfeer en luister naar de noden van de zorgontvangers bij het inkleden van de gemeenschappelijke ruimtes.",
    contact: true
});

export const Zoro003Francesco = new Person({
    ...Francesco,
    function: "Verpleegkundige",
    description: "Ik ondersteun de zorg en voer de strikt verpleegkundige handelingen uit zoals wondzorg en  medicatie klaarzetten.",
    contact: true
});

export const Zoro003An = new Person({
    ...An,
    function: "Geriater (CRA)",
    description: "Ik ben de vaste geriater van het woonzorgcentrum en zoek naar oplossingen voor lichamelijke, psychische of sociale problemen van patiënten.",
    contact: true
});

export const Zoro003Esther = new Person({
    ...Esther,
    function: "Logistiek medewerkster",
    description: "Ik verschoon de lakens, breng propere was, zorg dat de kamer op orde blijft. Ik heb dagelijks contact met Carlos.",
    contact: true
});

export const Zoro003Bas = new Person({
    ...Bas,
    function: "Animatieverantwoordelijke",
    description: "Ik begeleid de animatieactiviteiten in woonzorgcentrum ‘Zorgborg’.",
    contact: true
});