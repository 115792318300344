import PersonBodyPart from "@/models/PersonBodyPart";

export default class Person
{
    constructor(options)
    {
        options = options ?? {};

        this.id = options.id;
        this.name = options.name;
        this.function = options.function;
        this.description = options.description;
        this.contact = options.contact;

        this.avatar = options.avatar;
        this.avatarDashboard = options.avatarDashboard;
        this.avatarDialog = options.avatarDialog;
        this.avatarRecommendationDocument = options.avatarRecommendationDocument;
        this.avatar160 = options.avatar160;
        this.background = options.background;

        this.chatAvatar = new ChatAvatar(options.chatAvatar);
        this.meetingAvatar = new MeetingAvatar(options.meetingAvatar);
    }
}

class ChatAvatar
{
    constructor(options)
    {
        options = options ?? {};

        this.body = options.body;
        this.eyes = new PersonBodyPart(options.eyes);
        this.mouth = new PersonBodyPart(options.mouth);
    }
}

class MeetingAvatar
{
    constructor(options = {})
    {
        this.body = options.body;
        this.eyes = new PersonBodyPart(options.eyes);
        this.mouth = new PersonBodyPart(options.mouth);
    }
}