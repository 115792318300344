import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";
import PointAndClickCharacter from "@/models/PointAndClickCharacter";

export const Zoro001IntroStepWrapUpTalkScene = new PointAndClickScene({

    id: 'zoro-001-intro-step_wrap_up_talk',
    startLocation: 'step_wrap_up_talk',
    showPhoneButton: false,
    locations: {
        'step_wrap_up_talk': {
            items: [
                new PointAndClickItem({
                    id: 'bg_step_wrap_up',
                    img: require('@/assets/images/scenes/zoro001/bg_kitchen.png'),
                    pos: { left: -180, top: -180 },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'art_3',
                    img: require('@/assets/images/scenes/zoro001/objects/art_3/art_3.png'),
                    pos: { left: 193, top: 141 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'hangingplant',
                    img: require('@/assets/images/scenes/zoro001/objects/_case1_general/hangingplant.png'),
                    pos: { left: 55, top: -180 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'ceilinglamp',
                    img: require('@/assets/images/scenes/zoro001/objects/_case1_general/ceilinglamp.png'),
                    pos: { left: 665, top: -180 },
                    layer: 2,
                }),

                new PointAndClickItem({
                    id: 'fridge',
                    img: require('@/assets/images/scenes/zoro001/objects/fridge/fridge.png'),
                    pos: { left: 1686, top: 210 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'coffeemachine',
                    img: require('@/assets/images/scenes/zoro001/objects/coffeemachine/coffeemachine.png'),
                    pos: { left: 1382, top: 454 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'dogbed_1',
                    img: require('@/assets/images/scenes/zoro001/objects/dogbed_2/dogbed_2.png'),
                    pos: { left: 1568, top: 909 },
                    layer: 3,
                }),
            ],
            characters: [
                new PointAndClickCharacter({
                    id: 'stien_wrap_up',
                    layer: 5,
                    imgElements: [
                        {
                            id: 'stien_bg',
                            img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_body.png'),
                            pos: { left: 600, top: 160 },
                            frames: [
                                {
                                    id: 'stien',
                                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_body.png')
                                }
                            ],
                            timing: {
                                "0": "stien",
                            }
                        },
                        {
                            id: 'eyes',
                            pos: { left: 844, top: 345 },
                            layer: 2,
                            img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_eo.png'),
                            frames: [
                                {
                                    id: "open",
                                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_ec.png')
                                },
                                {
                                    id: "closed",
                                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_eo.png')
                                }
                            ],
                            timing: [
                                {
                                    duration: 50,
                                    frameId: "open"
                                },
                                {
                                    duration: 100,
                                    frameId: "closed"
                                },
                                {
                                    duration: 100,
                                    frameId: "open"
                                },
                                {
                                    duration: 3000,
                                    frameId: "closed"
                                },
                                {
                                    duration: 100,
                                    frameId: "open"
                                },
                                {
                                    duration: 2000,
                                    frameId: "closed"
                                },
                            ],
                        },
                        {
                            id: 'mouth',
                            pos: { left: 916, top: 452 },
                            layer: 2,
                            img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_mc.png'),
                            frames: [
                                {
                                    id: "closed",
                                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_mc.png')
                                },
                                {
                                    id: "open",
                                    img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_mo.png')
                                }
                            ],
                            timing: [
                                {
                                    duration: 100,
                                    frameId: "open"
                                },
                                {
                                    duration: 100,
                                    frameId: "closed"
                                },
                            ],
                        }
                    ],
                }),
            ],
        }
    },
});