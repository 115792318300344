import PointAndClickItem from "@/models/PointAndClickItem";
import { Blink } from "@/assets/data/animations/animations";

// Backgrounds

export const BackgroundHallway = new PointAndClickItem({
    id: 'bg-hallway',
    img: require('@/assets/images/scenes/zoro003/bg_exterior_corridor.png'),

    pos: { left: -180, top: -180 },
    layer: 1
});

export const BackgroundRoomCarlos = new PointAndClickItem({
    id: 'bg-room-carlos',
    img: require('@/assets/images/scenes/zoro003/bg_room.png'),

    pos: { left: -180, top: -180 },
    layer: 1
});

export const BackgroundNurseStation = new PointAndClickItem({
    id: 'bg-nurse-station',
    img: require('@/assets/images/scenes/zoro003/bg_nursestation.png'),

    pos: { left: -180, top: -180 },
    layer: 1
});

export const BackgroundRecreationRoom = new PointAndClickItem({
    id: 'bg-recreation-room',
    img: require('@/assets/images/scenes/zoro003/bg_recroom.png'),

    pos: { left: -180, top: -180 },
    layer: 1
});

// Doors

export const MoveHitbox = new PointAndClickItem({
    img: require('@/assets/images/scenes/zoro001/move_hitbox.png'),
    layer: 4,
    interactable: true,
    itemType: 'move',
});

export const MoveHitboxLeft = new PointAndClickItem({
    ...MoveHitbox,
    pos: { left: 25, top: 480 },
});

export const MoveHitboxRight = new PointAndClickItem({
    ...MoveHitbox,
    pos: { left: 1830, top: 480 },
});

export const HallwayDoorRight = new PointAndClickItem({
    id: 'room-door-yellow',
    img: require('@/assets/images/scenes/zoro003/objects/_doors/door_stick_1_c.png'),
    hoverImage: require('@/assets/images/scenes/zoro003/objects/_doors/door_stick_1_o.png'),
    sound: require('@/assets/sounds/door_knock.mp3'),
    pos: { left: 1236.5, top: 181 },
    layer: 2,
    dontAnimateOnHover: true
});

export const CarlosRoomDoor = new PointAndClickItem({
    id: 'carlos-room-door',
    img: require('@/assets/images/scenes/zoro003/objects/_doors/door_nh_2_c.png'),
    hoverImage: require('@/assets/images/scenes/zoro003/objects/_doors/door_nh_2_o.png'),
    pos: { left: 27, top: 177 },
    layer: 2,
    dontAnimateOnHover: true
});

export const NurseStationRoomDoor = new PointAndClickItem({
    id: 'nurse-station-room-door',
    img: require('@/assets/images/scenes/zoro003/objects/_doors/door_nh_1_c.png'),
    hoverImage: require('@/assets/images/scenes/zoro003/objects/_doors/door_nh_1_o.png'),
    pos: { left: 731, top: 181 },
    layer: 2,
    dontAnimateOnHover: true
});

// People

export const AminaBehindDesk = new PointAndClickItem({
    id: 'exterior-verpleegkundige',
    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_bg_body.png'),

    imgElements: [
        {
            id: 'eyes',
            pos: { left: 70, top: 54 },
            layer: 2,
            img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_bg_eo.png'),
            frames: [
                {
                    id: 'open',
                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_bg_eo.png'),
                },
                {
                    id: 'closed',
                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_bg_ec.png'),
                }
            ],
            timing: Blink.timing
        }
    ],

    pos: { left: -14, top: 461 },
    layer: 2
});

export const AminaInNurseStation = new PointAndClickItem({
    id: 'amina-in-nurse-station',
    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_full_body.png'),
    imgElements: [
        {
            id: 'eyes',
            pos: { left: 79, top: 90 },
            layer: 2,
            img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_full_eo.png'),
            frames: [
                {
                    id: 'open',
                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_full_eo.png'),
                },
                {
                    id: 'closed',
                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_full_ec.png'),
                }
            ],
            timing: Blink.timing
        },
        {
            id: 'mouth',
            pos: { left: 92, top: 120 },
            layer: 2,
            img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_full_mc.png'),
        }
    ],
    pos: { left: 1249, top: 335 },
    layer: 2
});

export const EricaInNurseStation = new PointAndClickItem({
    id: 'erica-in-nurse-station',
    img: require('@/assets/images/characters/referentiepersoon_dementie/refpersoon_dementie_full_body.png'),
    imgElements: [
        {
            id: 'eyes',
            pos: { left: 89, top: 118 },
            layer: 2,
            img: require('@/assets/images/characters/referentiepersoon_dementie/refpersoon_dementie_full_eo.png'),
            frames: [
                {
                    id: 'open',
                    img: require('@/assets/images/characters/referentiepersoon_dementie/refpersoon_dementie_full_eo.png'),
                },
                {
                    id: 'closed',
                    img: require('@/assets/images/characters/referentiepersoon_dementie/refpersoon_dementie_full_ec.png'),
                }
            ],
            timing: Blink.timing
        },
        {
            id: 'mouth',
            pos: { left: 103, top: 143 },
            layer: 2,
            img: require('@/assets/images/characters/referentiepersoon_dementie/refpersoon_dementie_full_mc.png'),
        }
    ],
    pos: { left: 1249, top: 335 },
    layer: 2
});

export const ChristienInNurseStation = new PointAndClickItem({
    id: 'christien-in-nurse-station',
    img: require('@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_full_body.png'),
    imgElements: [
        {
            id: 'eyes',
            pos: { left: 80, top: 82 },
            layer: 2,
            img: require('@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_full_eo.png'),
            frames: [
                {
                    id: 'open',
                    img: require('@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_full_eo.png'),
                },
                {
                    id: 'closed',
                    img: require('@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_full_ec.png'),
                }
            ],
            timing: Blink.timing
        },
        {
            id: 'mouth',
            pos: { left: 92, top: 120 },
            layer: 2,
            img: require('@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_full_mc.png'),
        }
    ],
    pos: { left: 1249, top: 335 },
    layer: 2
});

export const CarlosInWheelchair = new PointAndClickItem({
    id: 'carlos-in-wheelchair',
    img: require('@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_full_body.png'),
    imgElements: [
        {
            id: 'eyes',
            pos: { left: 180, top: 246 },
            layer: 2,
            img: require('@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_full_eo.png'),
            frames: [
                {
                    id: 'open',
                    img: require('@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_full_eo.png'),
                },
                {
                    id: 'closed',
                    img: require('@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_full_ec.png'),
                }
            ],
            timing: Blink.timing
        },
        {
            id: 'mouth',
            pos: { left: 184, top: 269 },
            layer: 2,
            img: require('@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_full_mc.png'),
        }
    ],
    pos: { left: 589, top: 370 },
    layer: 2
});

export const CarlosInChair = new PointAndClickItem({
    id: 'carlos-in-chair',
    img: require('@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_2_full_body.png'),
    imgElements: [
        {
            id: 'eyes',
            pos: { left: 183, top: 290 },
            layer: 2,
            img: require('@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_2_full_eo.png'),
            frames: [
                {
                    id: 'open',
                    img: require('@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_2_full_eo.png'),
                },
                {
                    id: 'closed',
                    img: require('@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_2_full_ec.png'),
                }
            ],
            timing: Blink.timing
        },
        {
            id: 'mouth',
            pos: { left: 188, top: 314 },
            layer: 2,
            img: require('@/assets/images/characters/zorgontvanger_c3/zorgontvanger_c3_2_full_mc.png'),
        }
    ],
    pos: { left: 440, top: 273 },
    layer: 2
});

export const AndreasInRoom = new PointAndClickItem({
    id: 'andreas-in-room',
    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_full_body.png'),
    imgElements: [
        {
            id: 'eyes',
            pos: { left: 104, top: 82 },
            layer: 2,
            img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_full_eo.png'),
            frames: [
                {
                    id: 'open',
                    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_full_eo.png'),
                },
                {
                    id: 'closed',
                    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_full_ec.png'),
                }
            ],
            timing: Blink.timing
        },
        {
            id: 'mouth',
            pos: { left: 115, top: 113 },
            layer: 2,
            img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_full_mc.png'),
        }
    ],
    pos: { left: 309, top: 365 },
    layer: 2
});

export const JackInHallway = new PointAndClickItem({
    id: 'jack-in-hallway',
    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_full_body.png'),
    imgElements: [
        {
            id: 'eyes',
            pos: { left: 122, top: 74 },
            layer: 2,
            img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_full_eo.png'),
            frames: [
                {
                    id: 'open',
                    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_full_eo.png'),
                },
                {
                    id: 'closed',
                    img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_full_ec.png'),
                }
            ],
            timing: Blink.timing
        },
        {
            id: 'mouth',
            pos: { left: 133, top: 99 },
            layer: 2,
            img: require('@/assets/images/characters/ergotherapeut/ergotherapeut_full_mc.png'),
        }
    ],
    pos: { left: 763, top: 296 },
    layer: 2
});

export const EstherInHallway = new PointAndClickItem({
    id: 'esther-in-hallway',
    img: require('@/assets/images/characters/logistiek-medewerker/logistiek-medewerker_full_body.png'),
    imgElements: [
        {
            id: 'eyes',
            pos: { left: 79, top: 123 },
            layer: 2,
            img: require('@/assets/images/characters/logistiek-medewerker/logistiek-medewerker_full_eo.png'),
            frames: [
                {
                    id: 'open',
                    img: require('@/assets/images/characters/logistiek-medewerker/logistiek-medewerker_full_eo.png'),
                },
                {
                    id: 'closed',
                    img: require('@/assets/images/characters/logistiek-medewerker/logistiek-medewerker_full_ec.png'),
                }
            ],
            timing: Blink.timing
        },
        {
            id: 'mouth',
            pos: { left: 97, top: 156 },
            layer: 2,
            img: require('@/assets/images/characters/logistiek-medewerker/logistiek-medewerker_full_mc.png'),
        }
    ],
    pos: { left: 1122, top: 304 },
    layer: 2
});

export const BasInRecreationRoom = new PointAndClickItem({
    id: 'bas-in-recreation-room',
    img: require('@/assets/images/characters/animatieverantwoordelijke/animatieverantw_full_body.png'),
    imgElements: [
        {
            id: 'eyes',
            pos: { left: 72, top: 66 },
            layer: 2,
            img: require('@/assets/images/characters/animatieverantwoordelijke/animatieverantw_full_eo.png'),
            frames: [
                {
                    id: 'open',
                    img: require('@/assets/images/characters/animatieverantwoordelijke/animatieverantw_full_eo.png'),
                },
                {
                    id: 'closed',
                    img: require('@/assets/images/characters/animatieverantwoordelijke/animatieverantw_full_ec.png'),
                }
            ],
            timing: Blink.timing
        },
        {
            id: 'mouth',
            pos: { left: 77, top: 102 },
            layer: 2,
            img: require('@/assets/images/characters/animatieverantwoordelijke/animatieverantw_full_mc.png'),
        }
    ],
    pos: { left: 1640, top: 315 },
    layer: 2
});

// Objects

export const Aquarium = new PointAndClickItem({
    id: 'aquarium',
    img: require('@/assets/images/scenes/zoro003/objects/aquarium/aquarium.png'),
    pos: { left: 646 - 180 - 218 / 2, top: 620 - 180 - 138 / 2 },
    layer: 2
});

export const ArtFlamenco = new PointAndClickItem({
    id: 'art-flamenco',
    img: require('@/assets/images/scenes/zoro003/objects/art_flamenco/art_flamenco.png'),
    pos: { left: 1299 - 180 - 237 / 2, top: 517 - 180 - 237 / 2 },
    layer: 2
});

export const Bingo = new PointAndClickItem({
    id: 'bingo',
    img: require('@/assets/images/scenes/zoro003/objects/bingo/bingo.png'),
    pos: { left: 1867 - 180 - 140 / 2, top: 769 - 180 - 149 / 2 },
    layer: 2
});

export const Blanket = new PointAndClickItem({
    id: 'blanket',
    img: require('@/assets/images/scenes/zoro003/objects/blanket_colourful/blanket.png'),
    pos: { left: 930, top: 695 },
    layer: 2
});

export const CalenderRip = new PointAndClickItem({
    id: 'calender-rip',
    img: require('@/assets/images/scenes/zoro003/objects/calender_rip/calender_rip_1.png'),
    pos: { left: 1645 - 180 - 140 / 2, top: 518 - 180 - 104 / 2 },
    layer: 2
});

export const CalenderRip2 = new PointAndClickItem({
    ...CalenderRip,
    img: require('@/assets/images/scenes/zoro003/objects/calender_rip/calender_rip_2.png'),
});

export const CartIsolation = new PointAndClickItem({
    id: 'cart-isolation',
    img: require('@/assets/images/scenes/zoro003/objects/cart_isolation/cart_isolation.png'),
    pos: { left: 1921 - 180 - 334 / 2, top: 933 - 180 - 390 / 2 },
    layer: 2
});

export const CartMedication = new PointAndClickItem({
    id: 'cart-medication',
    img: require('@/assets/images/scenes/zoro003/objects/cart_medication/cart_medication.png'),
    pos: { left: 1721 - 180 - 320 / 2, top: 947 - 180 - 491 / 2 },
    layer: 2
});

export const CatRobot = new PointAndClickItem({
    id: 'cat-robot',
    img: require('@/assets/images/scenes/zoro003/objects/cat_robot/cat_robot.png'),
    pos: { left: 588, top: 681 },
    layer: 2
});

export const Chair = new PointAndClickItem({
    id: 'chair',
    img: require('@/assets/images/scenes/zoro003/objects/chair_3/chair_3.png'),
    pos: { left: 802 - 180 - 364 / 2, top: 944 - 180 - 413 / 2 },
    layer: 2
});

export const ClockWallModern = new PointAndClickItem({
    id: 'clock-wall-modern',
    img: require('@/assets/images/scenes/zoro003/objects/clock_wall_modern/clock_wall_modern.png'),
    pos: { left: 1471 - 180 - 132 / 2, top: 284 - 180 - 131 / 2 },
    layer: 2
});

export const ClockWallOld = new PointAndClickItem({
    id: 'clock-wall-old',
    img: require('@/assets/images/scenes/zoro003/objects/clock_wall_old/clock_wall_old.png'),
    pos: { left: 691 - 180 - 113 / 2, top: 418 - 180 - 230 / 2 },
    layer: 2
});

export const Cup1 = new PointAndClickItem({
    id: 'cup-1',
    img: require('@/assets/images/scenes/zoro003/objects/cup_1/cup_1.png'),
    pos: { left: 617 - 180 - 60 / 2, top: 798 - 180 - 51 / 2 },
    layer: 2
});

export const FilingCabinet = new PointAndClickItem({
    id: 'filing-cabinet',
    img: require('@/assets/images/scenes/zoro003/objects/filing_cabinet/filing_cabinet.png'),
    pos: { left: 781 - 180 - 235 / 2, top: 841 - 180 - 518 / 2 },
    layer: 2
});

export const FlowersGift = new PointAndClickItem({
    id: 'flowers-gift',
    img: require('@/assets/images/scenes/zoro003/objects/flowers_gift/flowers_gift.png'),
    pos: { left: 801 - 180 - 175 / 2, top: 494 - 180 - 177 / 2 },
    layer: 2
});

export const MagazineStack = new PointAndClickItem({
    id: 'magazine-stack',
    img: require('@/assets/images/scenes/zoro003/objects/magazine_stack/magazine_stack.png'),
    pos: { left: 1205 - 180 - 113 / 2, top: 987 - 180 - 70 / 2 },
    layer: 2
});

export const MiningHelmet = new PointAndClickItem({
    id: 'mining-helmet',
    img: require('@/assets/images/scenes/zoro003/objects/mining_helmet/mining_helmet.png'),
    pos: { left: 1924 - 180 - 121 / 2, top: 769 - 180 - 66 / 2 },
    layer: 2
});

export const MiningLamp = new PointAndClickItem({
    id: 'mining-lamp',
    img: require('@/assets/images/scenes/zoro003/objects/mining_lamp/mining_lamp.png'),
    pos: { left: 1604 - 180 - 83 / 2, top: 758 - 180 - 159 / 2 },
    layer: 2
});

export const PaperTowelDispenser = new PointAndClickItem({
    id: 'paper-towel-dispenser',
    img: require('@/assets/images/scenes/zoro003/objects/paper_towel_dispenser/paper_towel_dispenser.png'),
    pos: { left: 1391 - 180 - 119 / 2, top: 505 - 180 - 152 / 2 },
    layer: 2
});

export const PhotoCat = new PointAndClickItem({
    id: 'photo-cat',
    img: require('@/assets/images/scenes/zoro003/objects/photo_cat/photo_cat.png'),
    pos: { left: 2008 - 180 - 135 / 2, top: 754 - 180 - 99 / 2 },
    layer: 2
});

export const PhotoMiners = new PointAndClickItem({
    id: 'photo-miners',
    img: require('@/assets/images/scenes/zoro003/objects/photo_miners/pinboard.png'),
    pos: { left: 1995 - 180 - 396 / 2, top: 543 - 180 - 254 / 2 },
    layer: 2
});

export const PhotoWedding = new PointAndClickItem({
    id: 'photo-wedding',
    img: require('@/assets/images/scenes/zoro003/objects/photo_wedding/photo_wedding.png'),
    pos: { left: 2041 - 180 - 176 / 2, top: 481 - 180 - 149 / 2 },
    layer: 2
});

export const Plant3 = new PointAndClickItem({
    id: 'plant-3',
    img: require('@/assets/images/scenes/zoro003/objects/plant_3/plant_3.png'),
    pos: { left: 2180 - 180 - 242 / 2, top: 795 - 180 - 460 / 2 },
    layer: 2
});

export const Plant3Hallway = new PointAndClickItem({
    id: 'plant-3-hallway',
    img: require('@/assets/images/scenes/zoro003/objects/plant_3/plant_3.png'),
    pos: { left: 852 - 180 - 242 / 2, top: 867 - 180 - 460 / 2 },
    layer: 2
})

export const PosterIsolation = new PointAndClickItem({
    id: 'poster-isolation',
    img: require('@/assets/images/scenes/zoro003/objects/poster_isolation/poster_isolation.png'),
    pos: { left: 1903 - 180 - 138 / 2, top: 635 - 180 - 193 / 2 },
    layer: 2
});

export const PosterMenu = new PointAndClickItem({
    id: 'poster-menu',
    img: require('@/assets/images/scenes/zoro003/objects/poster_menu/poster_menu.png'),
    pos: { left: 815 - 180 - 152 / 2, top: 620 - 180 - 109 / 2 },
    layer: 2
});

export const PosterPlanner = new PointAndClickItem({
    id: 'poster-planner',
    img: require('@/assets/images/scenes/zoro003/objects/poster_planner/poster_planner.png'),
    pos: { left: 821 - 180 - 111 / 2, top: 480 - 180 - 156 / 2 },
    layer: 2
});

export const Railing = new PointAndClickItem({
    id: 'railing',
    img: require('@/assets/images/scenes/zoro003/objects/railing/railing.png'),
    pos: { left: 2029 - 180 - 375 / 2, top: 801 - 180 - 64 / 2 },
    layer: 2
});

export const Rollator = new PointAndClickItem({
    id: 'rollator',
    img: require('@/assets/images/scenes/zoro003/objects/rollator/rollator.png'),
    pos: { left: 165, top: 744 },
    layer: 2
});

export const StickerDoor = new PointAndClickItem({
    id: 'sticker-door',
    img: require('@/assets/images/scenes/zoro003/objects/_doors/door_stick_2_c.png'),
    pos: { left: 813, top: 178 },
    layer: 2
});

export const TVRecreationRoom = new PointAndClickItem({
    id: 'tv-recreation-room',
    img: require('@/assets/images/scenes/zoro003/objects/tv_recroom/tv_recroom_1.png'),
    pos: { left: 1638 - 180 - 289 / 2, top: 733 - 180 - 196 / 2 },
    layer: 2
});

export const TVRecreationRoom2 = new PointAndClickItem({
    ...TVRecreationRoom,
    img: require('@/assets/images/scenes/zoro003/objects/tv_recroom/tv_recroom_2.png'),
})

export const Wheelchair = new PointAndClickItem({
    id: 'wheelchair',
    img: require('@/assets/images/scenes/zoro003/objects/wheelchair/wheelchair.png'),
    pos: { left: 234 - 180 - 401 / 2, top: 1038 - 180 - 402 / 2 },
    layer: 2
});

// General
export const BedAssistance = new PointAndClickItem({
    id: 'bed-assistance',
    img: require('@/assets/images/scenes/zoro003/objects/_general/bed_assistance.png'),
    pos: { left: 1304 - 180 - 541 / 2, top: 885 - 180 - 611 / 2 },
    layer: 2
});

export const ClockDigitalDate1 = new PointAndClickItem({
    id: 'clock-digital-date-1',
    img: require('@/assets/images/scenes/zoro003/objects/_general/clock_digital_date_1.png'),
    pos: { left: 1654 - 180 - 108 / 2, top: 807 - 180 - 63 / 2 },
    layer: 2
});

export const ClockDigitalDate2 = new PointAndClickItem({
    id: 'clock-digital-date-2',
    img: require('@/assets/images/scenes/zoro003/objects/_general/clock_digital_date_2.png'),
    pos: { left: 1654 - 180 - 108 / 2, top: 807 - 180 - 62 / 2 },
    layer: 2
});

export const Cup2 = new PointAndClickItem({
    id: 'cup-2',
    img: require('@/assets/images/scenes/zoro003/objects/_general/cup_2.png'),
    pos: { left: 407 - 180 - 59 / 2, top: 795 - 180 - 51 / 2 },
    layer: 2
});

export const Plant4 = new PointAndClickItem({
    id: 'plant-4',
    img: require('@/assets/images/scenes/zoro003/objects/_general/plant_4.png'),
    pos: { left: 2139 - 180 - 168 / 2, top: 725 - 180 - 152 / 2 },
    layer: 2
});

export const TableChairs = new PointAndClickItem({
    id: 'table-chairs',
    img: require('@/assets/images/scenes/zoro003/objects/_general/table_chairs.png'),
    pos: { left: 2088 - 180 - 706 / 2, top: 948 - 180 - 363 / 2 },
    layer: 2
});
