import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";
import PointAndClickCharacter from "@/models/PointAndClickCharacter";
import { Blink } from "@/assets/data/animations/animations";

export const Zoro001IntroSecondaryTalkRecipientScene = new PointAndClickScene({

        id: 'zoro-001-intro-first_talk_recipient',
        startLocation: 'entrance',
        showPhoneButton: false,
        locations: {
            'entrance': {
                items: [
                    new PointAndClickItem({
                        id: 'bg',
                        img: require('@/assets/images/scenes/zoro001/bg_c1_hall.png'),
                        pos: { left: -180, top: -180 },
                        layer: 1,
                    }),
                    new PointAndClickItem({
                        id: 'coatrack',
                        img: require('@/assets/images/scenes/zoro001/objects/_case1_general/coatrack.png'),
                        pos: { left: 100, top: 257 },
                        layer: 2,
                        interactable: false,
                        itemType: "item",
                    }),
                    new PointAndClickItem({
                        id: 'closedoor_wide',
                        img: require('@/assets/images/scenes/zoro001/objects/_doors/closedoor_wide.png'),
                        pos: { left: 299, top: 178 },
                        layer: 1,
                        interactable: false,
                        itemType: "item",
                        hoverImage: require('@/assets/images/scenes/zoro001/objects/_doors/opendoor_wide.png'),
                        dontAnimateOnHover: true,
                    }),
                    new PointAndClickItem({
                        id: 'closedoor_small',
                        img: require('@/assets/images/scenes/zoro001/objects/_doors/closedoor_small.png'),
                        pos: { left: 1211, top: 178 },
                        layer: 1,
                        interactable: false,
                        itemType: "item",
                        hoverImage: require('@/assets/images/scenes/zoro001/objects/_doors/opendoor_small.png'),

                        dontAnimateOnHover: true,
                    }),
                    new PointAndClickItem({
                        id: 'art_1',
                        img: require('@/assets/images/scenes/zoro001/objects/art_1/art_1.png'),
                        pos: { left: 1638, top: 225 },
                        layer: 2,
                        interactable: false,
                        itemType: "item",
                    }),
                    new PointAndClickItem({
                        id: 'drawer',
                        img: require('@/assets/images/scenes/zoro001/objects/drawer/drawer.png'),
                        pos: { left: 787, top: 613 },
                        layer: 2,
                        interactable: false,
                        itemType: "item",
                    }),
                    new PointAndClickItem({
                        id: 'coat',
                        img: require('@/assets/images/scenes/zoro001/objects/coat/coat.png'),
                        pos: { left: 76, top: 360 },
                        layer: 2,
                        interactable: false,
                        itemType: "item",
                    }),
                    new PointAndClickItem({
                        id: 'bikehelmet',
                        img: require('@/assets/images/scenes/zoro001/objects/bikehelmet/bikehelmet.png'),
                        pos: { left: 166, top: 270 },
                        layer: 2,
                        interactable: false,
                        itemType: "item",
                    }),
                ],
                characters: [
                    new PointAndClickCharacter({
                        id: 'stien',
                        layer: 5,
                        imgElements: [
                            {
                                id: 'stien_bg',
                                img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_body.png'),
                                pos: { left: 600, top: 160 },
                                frames: [
                                    {
                                        id: 'stien',
                                        img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_body.png')
                                    }
                                ],
                                timing: {
                                    "0": "stien",
                                }
                            },
                            {
                                id: 'eyes',
                                pos: { left: 844, top: 345 },
                                layer: 2,
                                img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_eo.png'),
                                frames: [
                                    {
                                        id: "open",
                                        img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_eo.png')
                                    },
                                    {
                                        id: "closed",
                                        img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_ec.png')
                                    }
                                ],
                                timing: Blink.timing,
                            },
                            {
                                id: 'mouth',
                                pos: { left: 916, top: 452 },
                                layer: 2,
                                img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_mc.png'),
                                frames: [
                                    {
                                        id: "closed",
                                        img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_mc.png')
                                    },
                                    {
                                        id: "open",
                                        img: require('@/assets/images/characters/zorgontvanger_c1/zorgontvanger_dialog_mo.png')
                                    }
                                ],
                                timing: [
                                    {
                                        duration: 100,
                                        frameId: "open"
                                    },
                                    {
                                        duration: 100,
                                        frameId: "closed"
                                    },
                                ],
                            }
                        ],
                    }),
                ],
            },

        },

    })
;