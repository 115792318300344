import PointAndClickScene from "@/models/PointAndClickScene";
import {
    AndreasInRoom, ArtFlamenco,
    BackgroundRoomCarlos, BedAssistance, Blanket, CarlosInChair, CatRobot, ClockDigitalDate2, MiningHelmet,
    MiningLamp, PhotoCat, PhotoWedding,
    Plant4, Wheelchair
} from "@/assets/data/objects/ZORO-003-objects";
import PointAndClickItem from "@/models/PointAndClickItem";

export const Zoro003FollowupFirstTalkRecipientScene = new PointAndClickScene({
    id: 'zoro-002-followup-first_talk_recipient',
    startLocation: 'room-carlos',
    showPhoneButton: true,
    locations: {
        'room-carlos': {
            items: [
                BackgroundRoomCarlos,

                ArtFlamenco,
                MiningLamp,
                PhotoCat,
                Plant4,
                ClockDigitalDate2,

                PhotoWedding,
                MiningHelmet,
                BedAssistance,
                Blanket,

                new PointAndClickItem({
                    ...CarlosInChair,
                    scale: { x: -1, y: 1 },
                    interactable: true,
                    itemType: 'action',
                    hoverType: 'talk',
                    action: 'talk_with_recipient',
                    name: 'Praat met Andreas en Carlos'
                }),
                new PointAndClickItem({
                    ...AndreasInRoom,
                    pos: { left: 202, top: 285 },
                    interactable: true,
                    itemType: 'action',
                    hoverType: 'talk',
                    action: 'talk_with_recipient',
                    name: 'Praat met Andreas en Carlos'
                }),
                new PointAndClickItem({
                    ...Wheelchair,
                    scale: { x: -1, y: 1 }
                }),
                CatRobot,
            ]
        }
    }
});