import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";
import {
    ArtFollowup, CellPhone, CoffeeMachine, Datasheet,
    DogFollowup, Gift,
    LampFollowup,
    PhotoFollowup, ToiletFollowup,
    WheelchairFollowup
} from "@/assets/data/items/collection_items-ZORO-001";

export const Zoro001FollowupCollectItemsScene = new PointAndClickScene({

    id: 'zoro-001-followup-collect_items',
    type: "ethical_question",
    showPhoneButton: true,
    startLocation: 'living-room',
    locations: {
        'hall': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro001/bg_c1_hall.png'),
                    pos: { left: -180, top: -180 },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'coatrack',
                    img: require('@/assets/images/scenes/zoro001/objects/_case1_general/coatrack.png'),
                    pos: { left: 100, top: 257 },
                    layer: 2,
                    itemType: "item",
                }),
                new PointAndClickItem({
                    id: 'art_1',
                    img: require('@/assets/images/scenes/zoro001/objects/art_1/art_1.png'),
                    pos: { left: 1638, top: 225 },
                    layer: 2,
                    itemType: "item",
                }),
                new PointAndClickItem({
                    id: 'drawer',
                    img: require('@/assets/images/scenes/zoro001/objects/drawer/drawer.png'),
                    pos: { left: 787, top: 613 },
                    layer: 2,
                    itemType: "item",
                }),
                new PointAndClickItem({
                    id: 'coat',
                    img: require('@/assets/images/scenes/zoro001/objects/coat/coat.png'),
                    pos: { left: 76, top: 360 },
                    layer: 2,
                    itemType: "item",
                }),
                new PointAndClickItem({
                    id: 'bikehelmet',
                    img: require('@/assets/images/scenes/zoro001/objects/bikehelmet/bikehelmet.png'),
                    pos: { left: 166, top: 270 },
                    layer: 2,
                }),

                //interactables
                new PointAndClickItem({
                    id: 'closedoor_wide_to_bathroom',
                    img: require('@/assets/images/scenes/zoro001/objects/_doors/closedoor_wide.png'),
                    pos: { left: 299, top: 178 },
                    layer: 1,
                    interactable: true,
                    itemType: "move",
                    moveLocation: 'bathroom',
                    sound: null,
                    name: "Ga naar badkamer",
                    hoverImage: require('@/assets/images/scenes/zoro001/objects/_doors/opendoor_wide.png'),
                    dontAnimateOnHover: true,
                }),
                new PointAndClickItem({
                    id: 'closedoor_small',
                    img: require('@/assets/images/scenes/zoro001/objects/_doors/closedoor_small.png'),
                    pos: { left: 1211, top: 178 },
                    layer: 1,
                    interactable: true,
                    itemType: "move",
                    moveLocation: 'living-room',
                    sound: null,
                    name: "Ga naar woonkamer",
                    hoverImage: require('@/assets/images/scenes/zoro001/objects/_doors/opendoor_small.png'),
                    dontAnimateOnHover: true,
                }),
                new PointAndClickItem({
                    id: 'wheelchair',
                    img: require('@/assets/images/scenes/zoro001/objects/wheelchair/wheelchair.png'),
                    pos: { left: 1540, top: 532 },
                    scale: { x: 1, y: 1 },
                    layer: 2,
                    interactable: true,
                    itemData: WheelchairFollowup
                }),
                new PointAndClickItem({
                    id: 'dog_1',
                    img: require('@/assets/images/scenes/zoro001/objects/dog_1/dog_1.png'),
                    pos: { left: 105, top: 613 },
                    layer: 3,
                    interactable: true,
                    itemData: DogFollowup
                }),
            ]
        },
        'living-room': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro001/bg_livingroom.png'),
                    pos: { left: -180, top: -180 },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'couch',
                    img: require('@/assets/images/scenes/zoro001/objects/_case1_general/couch.png'),
                    pos: { left: 241, top: 603 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'photo_1',
                    img: require('@/assets/images/scenes/zoro001/objects/photo_1/photo_1.png'),
                    pos: { left: 556, top: 187 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'plant_2',
                    img: require('@/assets/images/scenes/zoro001/objects/plant_2/plant_2.png'),
                    pos: { left: 1064, top: 430 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'photo_2',
                    img: require('@/assets/images/scenes/zoro001/objects/photo_2/photo_2.png'),
                    pos: { left: 1682, top: 561 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'flowers',
                    img: require('@/assets/images/scenes/zoro001/objects/flowers/flowers.png'),
                    pos: { left: 527, top: 655 },
                    layer: 3,
                }),
                new PointAndClickItem({
                    id: 'chair',
                    img: require('@/assets/images/scenes/zoro001/objects/chair/chair.png'),
                    pos: { left: -180, top: 677 },
                    layer: 3,
                }),
                new PointAndClickItem({
                    id: 'coffeetable',
                    img: require('@/assets/images/scenes/zoro001/objects/_case1_general/coffeetable.png'),
                    pos: { left: 384, top: 837 },
                    layer: 3,
                }),

                //interactables
                new PointAndClickItem({
                    id: 'lamp',
                    img: require('@/assets/images/scenes/zoro001/objects/lamp/lamp.png'),
                    pos: { left: 177, top: 400 },
                    layer: 2,
                    interactable: true,
                    itemData: LampFollowup
                }),
                new PointAndClickItem({
                    id: 'photo_3',
                    img: require('@/assets/images/scenes/zoro001/objects/photo_3/photo_3.png'),
                    pos: { left: 1792, top: 561 },
                    layer: 2,
                    interactable: true,
                    itemData: PhotoFollowup
                }),
                new PointAndClickItem({
                    id: 'gift',
                    img: require('@/assets/images/scenes/zoro001/objects/gift/gift.png'),
                    pos: { left: 721, top: 671 },
                    layer: 2,
                    interactable: true,
                    collectable: true,
                    itemType: "ethical",
                    itemData: Gift
                }),
                new PointAndClickItem({
                    id: 'closedoor_small_to_hall',
                    img: require('@/assets/images/scenes/zoro001/objects/_doors/closedoor_small.png'),
                    pos: { left: 1248, top: 178 },
                    layer: 1,
                    interactable: true,
                    itemType: "move",
                    moveLocation: 'hall',
                    sound: null,
                    name: "Ga naar hal",
                    hoverImage: require('@/assets/images/scenes/zoro001/objects/_doors/opendoor_small.png'),
                    dontAnimateOnHover: true,
                }),
                new PointAndClickItem({
                    id: 'to_kitchen',
                    img: require('@/assets/images/scenes/zoro001/move_hitbox.png'),
                    pos: { left: 1830, top: 480 },
                    layer: 4,
                    interactable: true,
                    itemType: "move",
                    moveLocation: "kitchen",
                    name: "Ga naar keuken",
                    dontAnimateOnHover: true
                }),

            ],
        },
        'kitchen': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro001/bg_kitchen.png'),
                    pos: { left: -180, top: -180 },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'hangingplant',
                    img: require('@/assets/images/scenes/zoro001/objects/_case1_general/hangingplant.png'),
                    pos: { left: 55, top: -180 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'ceilinglamp',
                    img: require('@/assets/images/scenes/zoro001/objects/_case1_general/ceilinglamp.png'),
                    pos: { left: 665, top: -180 },
                    layer: 3,
                }),
                new PointAndClickItem({
                    id: 'dogbed_2',
                    img: require('@/assets/images/scenes/zoro001/objects/dogbed_2/dogbed_2.png'),
                    pos: { left: 1568, top: 909 },
                    layer: 3,
                }),
                new PointAndClickItem({
                    id: 'fridge',
                    img: require('@/assets/images/scenes/zoro001/objects/fridge/fridge.png'),
                    pos: { left: 1686, top: 210 },
                    layer: 2,
                }),

                //interactables
                new PointAndClickItem({
                    id: 'coffeemachine',
                    img: require('@/assets/images/scenes/zoro001/objects/coffeemachine/coffeemachine.png'),
                    pos: { left: 1382, top: 454 },
                    layer: 2,
                    interactable: false,
                    itemType: "item",
                    itemData: CoffeeMachine
                }),
                new PointAndClickItem({
                    id: 'art_3',
                    img: require('@/assets/images/scenes/zoro001/objects/art_3/art_3.png'),
                    pos: { left: 193, top: 141 },
                    layer: 2,
                    interactable: false,
                    itemType: "item",
                    name: "art_3 f4",
                    description: "[TEMP] art_3 f4"
                }),
                new PointAndClickItem({
                    id: 'datasheet',
                    img: require('@/assets/images/scenes/zoro001/objects/datasheet/datasheet.png'),
                    pos: { left: 1760, top: 318 },
                    layer: 2,
                    interactable: true,
                    collectable: true,
                    itemType: "ethical",
                    itemData: Datasheet
                }),

                new PointAndClickItem({
                    id: 'to_living_room',
                    img: require('@/assets/images/scenes/zoro001/move_hitbox.png'),
                    pos: { left: 25, top: 480 },
                    layer: 4,
                    interactable: true,
                    itemType: "move",
                    moveLocation: "living-room",
                    name: "Ga naar woonkamer",
                }),
            ]
        },
        'bathroom': {
            items: [
                new PointAndClickItem({
                    id: 'bg_bathroom',
                    img: require('@/assets/images/scenes/zoro001/bg_c1_bathroom.png'),
                    pos: { left: -180, top: -180 },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'closedoor_wide',
                    img: require('@/assets/images/scenes/zoro001/objects/_doors/closedoor_wide.png'),
                    pos: { left: 242, top: 178 },
                    layer: 1,
                    interactable: true,
                    itemType: "move",
                    moveLocation: "hall",
                    name: "Ga naar hal",
                    hoverImage: require('@/assets/images/scenes/zoro001/objects/_doors/opendoor_wide.png'),
                    dontAnimateOnHover: true,
                }),

                new PointAndClickItem({
                    id: 'showerstool',
                    img: require('@/assets/images/scenes/zoro001/objects/showerstool/showerstool.png'),
                    pos: { left: 1530, top: 548 },
                    layer: 2,
                }),

                new PointAndClickItem({
                    id: 'armrest',
                    img: require('@/assets/images/scenes/zoro001/objects/armrest/armrest.png'),
                    pos: { left: 707, top: 558 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'plant_1',
                    img: require('@/assets/images/scenes/zoro001/objects/plant_1/plant_1.png'),
                    pos: { left: 791, top: 165 },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'mopbucket',
                    img: require('@/assets/images/scenes/zoro001/objects/_case1_general/mopbucket.png'),
                    pos: { left: 933, top: 448 },
                    layer: 2,
                }),


                //interactables
                new PointAndClickItem({
                    id: 'toilet',
                    img: require('@/assets/images/scenes/zoro001/objects/toilet/toilet.png'),
                    pos: { left: 766, top: 723 },
                    layer: 2,
                    interactable: true,
                    itemData: ToiletFollowup

                }),
                new PointAndClickItem({
                    id: 'art_2',
                    img: require('@/assets/images/scenes/zoro001/objects/art_2/art_2.png'),
                    pos: { left: 54, top: 228 },
                    layer: 2,
                    interactable: true,
                    itemData: ArtFollowup

                }),
                new PointAndClickItem({
                    id: 'cell_phone',
                    img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_full_body.png'),
                    imgElements: [
                        {
                            id: 'eyes',
                            pos: { left: 140, top: 101 },
                            layer: 2,
                            img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_full_eo.png'),
                            frames: [
                                {
                                    id: "open",
                                    img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_full_ec.png')
                                },
                                {
                                    id: "closed",
                                    img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_full_eo.png')
                                }
                            ],
                            timing: [
                                {
                                    duration: 50,
                                    frameId: "open"
                                },
                                {
                                    duration: 100,
                                    frameId: "closed"
                                },
                                {
                                    duration: 100,
                                    frameId: "open"
                                },
                                {
                                    duration: 3000,
                                    frameId: "closed"
                                },
                                {
                                    duration: 100,
                                    frameId: "open"
                                },
                                {
                                    duration: 2000,
                                    frameId: "closed"
                                },
                            ],
                        },
                        {
                            id: 'mouth',
                            pos: {left: 149, top: 134},
                            layer: 2,
                            img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_full_mc.png'),
                            frames: [
                                {
                                    id: "closed",
                                    img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_full_mc.png')
                                },
                                {
                                    id: "open",
                                    img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_full_mo.png')
                                }
                            ],
                            timing: [
                                {
                                    duration: 0,
                                    frameId: "closed"
                                }
                            ],
                        }
                    ],
                    pos: { left: 1119, top: 288 },
                    layer: 5,
                    interactable: true,
                    collectable: true,
                    itemType: "ethical",
                    name: "Huishoudhulp",
                    itemData: CellPhone
                }),
            ]
        }
    },
});