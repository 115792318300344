import PointAndClickScene from "@/models/PointAndClickScene";
import {
    AminaInNurseStation,
    AndreasInRoom, Aquarium,
    ArtFlamenco,
    BackgroundHallway,
    BackgroundNurseStation,
    BackgroundRecreationRoom,
    BackgroundRoomCarlos, BasInRecreationRoom,
    BedAssistance, Bingo,
    Blanket, CalenderRip, CarlosInChair,
    CarlosRoomDoor, CartMedication,
    ClockDigitalDate1, ClockWallModern, ClockWallOld, Cup1, Cup2, EstherInHallway, FilingCabinet, FlowersGift,
    HallwayDoorRight, MagazineStack,
    MiningHelmet,
    MiningLamp, MoveHitboxLeft, MoveHitboxRight, NurseStationRoomDoor, PaperTowelDispenser,
    PhotoCat, PhotoMiners,
    PhotoWedding, Plant3, Plant3Hallway,
    Plant4, PosterMenu, PosterPlanner, Railing, Rollator, StickerDoor, TableChairs, TVRecreationRoom, Wheelchair
} from "@/assets/data/objects/ZORO-003-objects";
import PointAndClickItem from "@/models/PointAndClickItem";

export const Zoro003IntroFindRecipientScene = new PointAndClickScene({
    id: 'zoro-003-intro-find_recipient',
    startLocation: 'hallway',
    showPhoneButton: true,
    startInPreviousSceneLocation: true,
    locations: {
        'room-carlos': {
            items: [
                BackgroundRoomCarlos,
                ArtFlamenco,
                PhotoCat,
                PhotoWedding,
                MiningHelmet,
                MiningLamp,
                Plant4,
                ClockDigitalDate1,
                BedAssistance,
                Blanket,

                new PointAndClickItem({
                    ...CarlosRoomDoor,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang',
                }),

                new PointAndClickItem({
                    ...Wheelchair,
                    scale: { x: -1, y: 1 }
                }),
                new PointAndClickItem({
                    ...CarlosInChair,
                    scale: { x: -1, y: 1 },
                    interactable: true,
                    itemType: 'action',
                    hoverType: 'talk',
                    action: 'talk_with_recipient',
                    name: 'Praat met Andreas en Carlos'
                }),
                new PointAndClickItem({
                    ...AndreasInRoom,
                    pos: { left: 202, top: 285 },
                    interactable: true,
                    itemType: 'action',
                    hoverType: 'talk',
                    action: 'talk_with_recipient',
                    name: 'Praat met Andreas en Carlos'
                }),
            ]
        },
        'hallway': {
            items: [
                BackgroundHallway,

                Plant3Hallway,
                PosterMenu,

                StickerDoor,
                new PointAndClickItem({
                    ...HallwayDoorRight,
                    sound: undefined,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'room-carlos',
                    name: 'Ga naar kamer',
                    hoverName: 'Ga naar kamer'
                }),
                PosterPlanner,
                Railing,
                Rollator,
                EstherInHallway,

                new PointAndClickItem({
                    ...MoveHitboxLeft,
                    id: 'to-nurse-station',
                    moveLocation: 'nurse-station',
                    name: 'Ga naar verpleegpost',
                    hoverName: 'Ga naar verpleegpost',
                }),
                new PointAndClickItem({
                    ...MoveHitboxRight,
                    id: 'to-recreation-room',
                    moveLocation: 'recreation-room',
                    name: 'Ga naar leefruimte',
                    hoverName: 'Ga naar leefruimte',
                })
            ]
        },
        'nurse-station': {
            items: [
                BackgroundNurseStation,

                Cup2,
                ClockWallModern,
                PaperTowelDispenser,

                new PointAndClickItem({
                    ...NurseStationRoomDoor,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang'
                }),

                FilingCabinet,
                FlowersGift,
                Cup1,
                CartMedication,

                AminaInNurseStation
            ]
        },
        'recreation-room': {
            items: [
                BackgroundRecreationRoom,

                TVRecreationRoom,
                Plant3,
                TableChairs,
                ClockWallOld,
                CalenderRip,
                MagazineStack,

                PhotoMiners,
                Bingo,
                Aquarium,

                BasInRecreationRoom,

                new PointAndClickItem({
                    ...MoveHitboxLeft,
                    id: 'to-hallway',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang',
                })
            ]
        }
    }
});