import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";
import {
    AlarmSystem, Bathrobe, BookRack, BrochureDiabetes,
    Chair, Clock, Computer, Deodorant, FireAlarm,
    FootballScarf,
    LukasCellphone, LukasMom, LukasWheelchair, MealCart,
    Pizzabox, Poster, PosterHyperglycemia, PosterHypoglycemia, WallDispenser
} from "@/assets/data/items/collection_items-ZORO-002";
import { Blink } from "@/assets/data/animations/animations";

const bgPosDeltaX = 180;
const bgPosDeltaY = 180;

export const Zoro002IntroCollectItemsScene = new PointAndClickScene({
    id: 'zoro-002-intro-collect_items',
    type: "collect_items",
    showPhoneButton: true,
    startLocation: 'hospitalroom',
    locations: {
        'hallway': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro002/bg_corridor.png'),
                    pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'computer-cart',
                    img: require('@/assets/images/scenes/zoro002/objects/_general/monitorcart.png'),
                    pos: { left: 364 - bgPosDeltaX, top: 611 - bgPosDeltaY },
                    layer: 2
                }),
                new PointAndClickItem({
                    id: WallDispenser.id,
                    img: WallDispenser.img,
                    pos: { left: 904 - bgPosDeltaX, top: 612 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: FireAlarm.id,
                    img: FireAlarm.img,
                    pos: { left: 1317 - bgPosDeltaX, top: 690 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'door-to-hospitalroom',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_c.png'),
                    pos: { left: 483 - bgPosDeltaX, top: 358 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hospitalroom',
                    name: 'Ga naar kamer',
                    hoverName: 'Ga naar kamer',
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_o.png'),
                    dontAnimateOnHover: true
                }),
                new PointAndClickItem({
                    id: 'door-to-waitroom',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_dbl_c.png'),
                    pos: { left: 1462 - bgPosDeltaX, top: 376 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'waitroom',
                    name: 'Ga naar wachtkamer',
                    hoverName: 'Ga naar wachtkamer',
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_dbl_o.png'),
                    dontAnimateOnHover: true
                }),
                new PointAndClickItem({
                    id: Poster.id,
                    img: Poster.img,
                    pos: { left: 1066 - bgPosDeltaX, top: 464 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: false,
                    itemData: Poster
                }),
                new PointAndClickItem({
                    id: MealCart.id,
                    img: MealCart.img,
                    pos: { left: 975 - bgPosDeltaX, top: 753 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: true,
                    itemData: MealCart
                }),
                new PointAndClickItem({
                    id: LukasWheelchair.id,
                    img: LukasWheelchair.img,
                    pos: { left: 1925 - bgPosDeltaX, top: 709 - bgPosDeltaY },
                    scale: { x: -1, y: 1 },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: false,
                    itemData: LukasWheelchair
                }),
                new PointAndClickItem({
                    id: LukasMom.id,
                    img: LukasMom.img,
                    imgElements: [
                        {
                            id: 'eyes',
                            pos: { left: 81, top: 84 },
                            layer: 2,
                            img: require('@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_full_eo.png'),
                            frames: [
                                {
                                    id: 'open',
                                    img: require('@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_full_eo.png')
                                },
                                {
                                    id: 'closed',
                                    img: require('@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_full_ec.png')
                                }
                            ],
                            timing: Blink.timing
                        }
                    ],
                    pos: { left: 1360 - bgPosDeltaX, top: 485 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: true,
                    itemData: LukasMom
                })
            ]
        },
        'waitroom': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro002/bg_waitingroom.png'),
                    pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: PosterHypoglycemia.id,
                    img: PosterHypoglycemia.img,
                    pos: { left: 1170 - bgPosDeltaX, top: 414 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: PosterHyperglycemia.id,
                    img: PosterHyperglycemia.img,
                    pos: { left: 1407 - bgPosDeltaX, top: 408 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: BrochureDiabetes.id,
                    img: BrochureDiabetes.img,
                    pos: { left: 1125 - bgPosDeltaX, top: 882 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'to_living_room',
                    img: require('@/assets/images/scenes/zoro002/move_hitbox.png'),
                    pos: { left: 25, top: 480 },
                    layer: 4,
                    interactable: true,
                    itemType: "move",
                    moveLocation: "hallway",
                    name: "Ga naar gang",
                    hoverName: "Ga naar gang",
                }),
                new PointAndClickItem({
                    id: Clock.id,
                    img: Clock.img,
                    pos: { left: 1378 - bgPosDeltaX, top: 223 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: false,
                    itemData: Clock
                }),
                new PointAndClickItem({
                    id: Computer.id,
                    img: Computer.img,
                    pos: { left: 692 - bgPosDeltaX, top: 600 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: false,
                    itemData: Computer
                }),
                new PointAndClickItem({
                    id: BookRack.id,
                    img: BookRack.img,
                    pos: { left: 320 - bgPosDeltaX, top: 505 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: true,
                    itemData: BookRack
                }),
                new PointAndClickItem({
                    id: 'amina-eyes',
                    imgElements: [
                        {
                            id: 'eyes',
                            pos: { left: 0, top: 0 },
                            layer: 2,
                            img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_bg_eo.png'),
                            frames: [
                                {
                                    id: 'open',
                                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_bg_eo.png')
                                },
                                {
                                    id: 'closed',
                                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_bg_ec.png')
                                }
                            ],
                            timing: Blink.timing
                        }
                    ],
                    pos: { left: 404, top: 429 },
                    layer: 2
                })
            ],
        },
        'hospitalroom': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro002/bg_hospitalroom.png'),
                    pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'tv',
                    img: require('@/assets/images/scenes/zoro002/objects/_general/tv_footbal.png'),
                    pos: { left: 1812 - bgPosDeltaX, top: 223 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'bed',
                    img: require('@/assets/images/scenes/zoro002/objects/bed_hospital/bed_hospital.png'),
                    pos: { left: 979 - bgPosDeltaX, top: 623 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'soda',
                    img: require('@/assets/images/scenes/zoro002/objects/_general/can_soda.png'),
                    pos: { left: 899 - bgPosDeltaX, top: 785 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'roomcart',
                    img: require('@/assets/images/scenes/zoro002/objects/_general/roomcart.png'),
                    pos: { left: 742 - bgPosDeltaX, top: 827 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'door-to-hallway',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_c.png'),
                    pos: { left: 206 - bgPosDeltaX, top: 358 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang',
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_o.png'),
                    dontAnimateOnHover: true
                }),
                new PointAndClickItem({
                    id: 'door-to-bathroom',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_c.png'),
                    pos: { left: 1538 - bgPosDeltaX, top: 397 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'bathroom',
                    name: 'Ga naar badkamer',
                    hoverName: 'Ga naar badkamer',
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_o.png'),
                    dontAnimateOnHover: true
                }),
                new PointAndClickItem({
                    id: AlarmSystem.id,
                    img: AlarmSystem.img,
                    pos: { left: 977 - bgPosDeltaX, top: 718 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: false,
                    itemData: AlarmSystem
                }),
                new PointAndClickItem({
                    id: LukasCellphone.id,
                    img: LukasCellphone.img,
                    pos: { left: 1300 - bgPosDeltaX, top: 815 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: true,
                    itemData: LukasCellphone
                }),
                new PointAndClickItem({
                    id: FootballScarf.id,
                    img: FootballScarf.img,
                    pos: { left: 971 - bgPosDeltaX, top: 883 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: true,
                    itemData: FootballScarf
                }),
                new PointAndClickItem({
                    id: Chair.id,
                    img: Chair.img,
                    pos: { left: 86 - bgPosDeltaX, top: 942 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: false,
                    itemData: Chair
                })
            ]
        },
        'bathroom': {
            items: [
                new PointAndClickItem({
                    id: 'bg_bathroom',
                    img: require('@/assets/images/scenes/zoro002/bg_bathroom.png'),
                    pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'poster-wash',
                    img: require('@/assets/images/scenes/zoro002/objects/poster_washing_hands/poster_washing_hands.png'),
                    pos: { left: 1101 - bgPosDeltaX, top: 432 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'alarm-button',
                    img: require('@/assets/images/scenes/zoro002/objects/alarm_button/alarm_button.png'),
                    pos: { left: 522 - bgPosDeltaX, top: 715 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'toiletries',
                    img: require('@/assets/images/scenes/zoro002/objects/toiletries/toiletries.png'),
                    pos: { left: 1024 - bgPosDeltaX, top: 727 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'backpack',
                    img: require('@/assets/images/scenes/zoro002/objects/backpack/backpack.png'),
                    pos: { left: 917 - bgPosDeltaX, top: 1016 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'door-to-hospitalroom',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_c.png'),
                    pos: { left: 1225 - bgPosDeltaX, top: 358 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hospitalroom',
                    name: 'Ga naar kamer',
                    hoverName: 'Ga naar kamer',
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_o.png'),
                    dontAnimateOnHover: true
                }),
                new PointAndClickItem({
                    id: Pizzabox.id,
                    img: Pizzabox.img,
                    pos: { left: 1013 - bgPosDeltaX, top: 854 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: true,
                    itemData: Pizzabox
                }),
                new PointAndClickItem({
                    id: Deodorant.id,
                    img: Deodorant.img,
                    pos: { left: 796 - bgPosDeltaX, top: 712 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: false,
                    itemData: Deodorant
                }),
                new PointAndClickItem({
                    id: Bathrobe.id,
                    img: Bathrobe.img,
                    pos: { left: 1655 - bgPosDeltaX, top: 515 - bgPosDeltaY },
                    layer: 2,
                    interactable: true,
                    itemType: "item",
                    collectable: false,
                    itemData: Bathrobe
                })
            ]
        }
    },
});