<template>
  <button class="zoro-button"
          :class="[styleType || 'default', hasIconClass ? 'has-icon' : '', singleColumnIcon ? 'single-column-icon' : '']"
          :disabled="disabled"
          @click="onClick">
    <div class="icon-container" v-if="hasIconClass">
      <slot name="icon"></slot>
    </div>
    <slot><span class="button-text">{{ text }}</span></slot>
  </button>
</template>

<script>
import AudioMixin from "@/mixins/AudioMixin";

export default {
  name: "ZOROButton",
  mixins: [ AudioMixin ],
  props: {
    disabled: {
      type: Boolean,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    styleType: {
      type: String,
      required: false,
      validator: value =>
      {
        return [
          'default',
          'primary',
          'secondary',
          'positive',
          'negative',
          'pink',
          'none'
        ].includes(value)
      }
    },
    playTapSound: {
      type: Boolean,
      required: false,
      default: true
    },
    singleColumnIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    hasIconClass()
    {
      return this.$slots['icon'];
    }
  },
  methods: {
    onClick()
    {
      if (this.playTapSound)
      {
        this.playButtonTapGeneric();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zoro-button
{
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 44px;
  border-radius: 8px;
  border: none;

  font-size: 16px;
  line-height: 23px;
  font-weight: 800;
  text-transform: uppercase;

  padding: 10px 12px;

  &.has-icon
  {
    grid-template-columns: 33px 1fr 33px;
  }

  &.single-column-icon
  {
    grid-template-columns: 33px 1fr;
  }

  &.large
  {
    min-height: 60px;
    font-size: 26px;
    line-height: 33px;

    .icon-container
    {
      width: 33px;
      height: 40px;

      :deep(> *)
      {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.default,
  &.primary
  {
    background-color: $primary;
    color: $white;

    :deep(path)
    {
      fill: $white;
    }

    &:hover
    {
      background-color: $primary-hover;
    }

    &:active,
    &:focus
    {
      background-color: $primary-down;
    }

    &:disabled
    {
      background-color: $primary-light;
    }
  }

  &.white
  {
    background-color: $white;
    color: $primary;

    :deep(path)
    {
      fill: $primary;
    }

    &:hover
    {
      background-color: $white;
    }

    &:active,
    &:focus
    {
      background-color: $white;
    }

    &:disabled
    {
      background-color: $white;
    }
  }

  &.secondary
  {
    background-color: $secondary;
    color: $white;

    :deep(path)
    {
      fill: $white;
    }

    &:hover
    {
      background-color: $secondary-hover;
    }

    &:active,
    &:focus
    {
      background-color: $secondary-down;
    }

    &:disabled
    {
      background-color: $secondary-light;
    }
  }

  &.positive
  {
    background-color: $ui-semantic-positive;
    color: $white;

    :deep(path)
    {
      fill: $white;
    }

    &:hover
    {
      background-color: $ui-semantic-positive-hover;
    }

    &:active,
    &:focus
    {
      background-color: $ui-semantic-positive-down;
    }

    &:disabled
    {
      background-color: $ui-semantic-positive-disabled;
    }
  }

  &.negative
  {
    background-color: $ui-semantic-negative;
    color: $white;

    :deep(path)
    {
      fill: $white;
    }

    &:hover
    {
      background-color: $ui-semantic-negative-hover;
    }

    &:active,
    &:focus
    {
      background-color: $ui-semantic-negative-down;
    }

    &:disabled
    {
      background-color: $ui-semantic-negative-disabled;
    }
  }

  &.pink
  {
    background-color: $pink;
    color: $white;

    :deep(path)
    {
      fill: $white;
    }

    &:hover
    {
      background-color: $pink-hover;
    }

    &:active,
    &:focus
    {
      background-color: $pink-down;
    }

    &:disabled
    {
      background-color: $pink-light;
    }
  }

  &.none
  {
    background-color: transparent;
    color: $grey-2;

    :deep(path)
    {
      fill: $grey-2;
    }

    padding: 0;
    margin: 0;

    width: min-content;
    min-height: min-content;

    &:hover
    {
      background-color: transparent;
    }

    &:active,
    &:focus
    {
      background-color: transparent;
    }

    &:disabled
    {
      background-color: transparent;
    }
  }

  &:hover
  {
    cursor: pointer;
  }

  &:disabled
  {
    cursor: default;
  }

  .icon-container
  {
    width: 33px;
    height: 24px;

    :deep(> *)
    {
      width: 100%;
      height: 100%;
    }
  }

  .zoro-button-icon
  {
    font-size: 24px;
    visibility: hidden;

    &.visible
    {
      visibility: visible;
    }
  }

  .button-text
  {
    flex: 1;
  }
}
</style>