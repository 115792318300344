import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";

const bgPosDeltaX = 180;
const bgPosDeltaY = 180;

export const Zoro002InitialHomeScene = new PointAndClickScene({
    id: 'zoro-002-intro-initial_home',
    startLocation: 'hallway',
    showPhoneButton: true,
    locations: {
        'hallway': {
            items: [
                new PointAndClickItem({
                    id: 'bg-exterior',
                    img: require('@/assets/images/scenes/zoro002/bg_corridor.png'),

                    pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'poster',
                    img: require('@/assets/images/scenes/zoro002/objects/poster_diabetes/poster_diabetes.png'),
                    pos: { left: 1066 - bgPosDeltaX, top: 464 - bgPosDeltaY },
                    layer: 2
                }),
                new PointAndClickItem({
                    id: 'computer-cart',
                    img: require('@/assets/images/scenes/zoro002/objects/_general/monitorcart.png'),
                    pos: { left: 1980 - bgPosDeltaX, top: 550 - bgPosDeltaY },
                    layer: 2
                }),
                new PointAndClickItem({
                    id: 'food-card',
                    img: require('@/assets/images/scenes/zoro002/objects/foodcart/foodcart.png'),
                    pos: { left: 100 - bgPosDeltaX, top: 720 - bgPosDeltaY },
                    layer: 2
                }),
                new PointAndClickItem({
                    id: 'dispenser',
                    img: require('@/assets/images/scenes/zoro002/objects/dispenser_soap/dispenser_soap.png'),
                    pos: { left: 904 - bgPosDeltaX, top: 612 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'alarm-fire',
                    img: require('@/assets/images/scenes/zoro002/objects/alarm_fire/alarm_fire.png'),
                    pos: { left: 1317 - bgPosDeltaX, top: 690 - bgPosDeltaY },
                    layer: 2
                }),
                new PointAndClickItem({
                    id: 'room-door',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_c.png'),
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_o.png'),
                    sound: require('@/assets/sounds/door_knock.mp3'),
                    pos: { left: 483 - bgPosDeltaX, top: 358 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: "action",
                    moveLocation: "",
                    hoverType: "use",
                    action: "knock_room_door",
                    trigger: null,
                    name: "Klop op de deur",
                    dontAnimateOnHover: true
                })
            ]
        }
    }
});