<template>
  <!--  <div class="app-version" v-show="false"> {{ appVersion }}</div>-->
  <router-view></router-view>
  <transition name="slide">
    <div class="banner-container" v-if="showBanner">
      <p>
        De groep Provincie Antwerpen heeft haar cookieverklaring aangepast. Door op “Accepteren” te klikken of door
        verder te surfen, erken je onze aangepaste cookieverklaring gelezen te hebben.
        <router-link to="cookie-policy">Meer informatie</router-link>
      </p>
      <div style="display: flex; gap: 1em; align-content: flex-end;">
        <ZOROButton @click="acceptBannerClicked" style-type="white">Accepteren</ZOROButton>
        <ZOROButton @click="rejectBannerClicked" style-type="negative">Weigeren</ZOROButton>
      </div>
    </div>
  </transition>
</template>

<script>
import store from "@/store";
import useCookies from "@/composables/useCookies";
import { inject } from "vue";
import ZOROButton from "@/components/UI/ZOROButton";

export default {
  store,
  name: "App",
  components: { ZOROButton },
  setup()
  {
    const gtag = inject('gtag');
    const { showBanner, acceptClicked, rejectClicked } = useCookies(gtag);
    const acceptBannerClicked = () => acceptClicked();
    const rejectBannerClicked = () => rejectClicked();

    return {
      appVersion: process.env.VUE_APP_VERSION,
      showBanner,
      acceptBannerClicked,
      rejectBannerClicked
    }
  }
}
</script>

<style lang="scss">
@import "./styles/main";

#app
{
  background-color: $background-neutral;
  color: $black;
  height: 100%;
}

.slide-enter-active,
.slide-leave-active
{
  transition: opacity 0.5s;
}

.slide-enter,
.slide-leave-to
{
  opacity: 0;
}

.banner-container
{
  color: white;
  background: $primary;
  border: 3px solid white;
  position: fixed;
  inset: auto 0px 0px 0px;
  margin: 20px;
  padding: 0.5em 1em;
  display: flex;
  gap: 1em;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1025px)
  {
    flex-wrap: wrap;
  }

  p
  {
    margin: 0;
  }
}
</style>
