import TechnologicalAid from "@/models/TechnologicalAid";

export const Lamp = new TechnologicalAid({
    id: "lamp",
    name: "Lamp met bewegingsdetectie",
    description: "De lamp gaat branden bij beweging, bijvoorbeeld wanneer de zorgontvanger uit bed stapt.",

    imageIcon: require("@/assets/images/technological_aides/bewegingsdetectie-lamp/bewegingsdetectie-lamp_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/bewegingsdetectie-lamp/bewegingsdetectie-lamp_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/bewegingsdetectie-lamp/bewegingsdetectie-lamp_375x218.png"),
});

export const Phone = new TechnologicalAid({
    id: "phone",
    name: "Vaste telefoon",
    description: "Deze telefoon heeft grote toetsen en vier fototoetsen. Dit ondersteunt een zorgontvanger met visuele beperkingen.",

    imageIcon: require("@/assets/images/technological_aides/vaste-telefoon/vaste-telefoon_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/vaste-telefoon/vaste-telefoon_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/vaste-telefoon/vaste-telefoon_375x218.png"),
});

export const Airbag = new TechnologicalAid({
    id: "airbag",
    name: "De bekken airbag",
    description: "De zorgontvanger draagt de bekken airbag ter hoogte van de heupen, onder de kledij. Bij een valbeweging wordt de airbag onmiddellijk opgeblazen. En breekt zo de val.",

    imageIcon: require("@/assets/images/technological_aides/bekken-airbag/bekken-airbag_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/bekken-airbag/bekken-airbag_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/bekken-airbag/bekken-airbag_375x218.png"),
});

export const Smartclip = new TechnologicalAid({
    id: "smartclip",
    name: "De smartclip",
    description: "De smartclip is een activiteitenmonitor, valrisicometer én valdetector ineen. De zorgontvanger brengt de clip aan op de kledij. De smartclip werkt met een app.",

    imageIcon: require("@/assets/images/technological_aides/smart-clip/smart-clip_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/smart-clip/smart-clip_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/smart-clip/smart-clip_375x218.png"),
});

export const LifestyleDetector = new TechnologicalAid({
    id: "lifestyle",
    name: "De leefpatroon-detector",
    description: "De leefpatroon-detector analyseert het leefpatroon van de zorgontvanger, door middel van bewegingssensoren en alarmknoppen, discreet aangebracht in de woning. Wanneer het systeem iets vreemd opmerkt, worden de contactpersonen verwittigd.",

    imageIcon: require("@/assets/images/technological_aides/leefpatroon-detector/leefpatroon-detector_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/leefpatroon-detector/leefpatroon-detector_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/leefpatroon-detector/leefpatroon-detector_375x218.png"),
});

export const Stairgate = new TechnologicalAid({
    id: "stairgate",
    name: "Traphekje",
    description: "Een traphekje kan voorkomen dat de zorgontvanger van de trap valt.",

    imageIcon: require("@/assets/images/technological_aides/traphekje/traphekje_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/traphekje/traphekje_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/traphekje/traphekje_375x218.png"),
});

export const FallDetectionShoes = new TechnologicalAid({
    id: "falldetectionshoes",
    name: "Valdetectieschoenen",
    description: "Deze schoenen detecteren een val en verwittigen een contactpersoon. De schoenen staan in verbinding met een app.",

    imageIcon: require("@/assets/images/technological_aides/valdetectie-schoenen/valdetectie-schoenen_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/valdetectie-schoenen/valdetectie-schoenen_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/valdetectie-schoenen/valdetectie-schoenen_375x218.png"),
});

export const EWheelChair = new TechnologicalAid({
    id: "ewheelchair",
    name: "Elektrische rolstoel",
    description: "Met een elektrische rolstoel kan een zorgontvanger zich zelfstandig verplaatsen.",

    imageIcon: require("@/assets/images/technological_aides/elektrische-rolstoel/elektrische-rolstoel_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/elektrische-rolstoel/elektrische-rolstoel_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/elektrische-rolstoel/elektrische-rolstoel_375x218.png"),
});

export const Walker = new TechnologicalAid({
    id: "walker",
    name: "Rollator",
    description: "Een rollator biedt letterlijk steun bij het wandelen en helpt om het evenwicht te houden.",

    imageIcon: require("@/assets/images/technological_aides/rollator/rollator_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/rollator/rollator_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/rollator/rollator_375x218.png"),
});

export const SpeakerCamera = new TechnologicalAid({
    id: "speakercamera",
    name: "Slimme speaker/camera",
    description: "Met een slimme speaker/camera kan de zorgontvanger alarmen instellen en activeren door middel van spraak. De zorgontvanger kan al sprekend een contactpersoon bereiken. De camera volgt bewegingen in het huis en signaleert abnormale activiteiten.",

    imageIcon: require("@/assets/images/technological_aides/slimme-speakercamera/slimme-speakercamera_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/slimme-speakercamera/slimme-speakercamera_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/slimme-speakercamera/slimme-speakercamera_375x218.png"),
});

export const GlucoseSensor = new TechnologicalAid({
    id: "glucosesensor",
    name: "Glucosesensor (CGM)",
    description: "De glucosesensor wordt onderhuids geplaatst, ter hoogte van de buik of de arm. De sensor meet om de 5 minuten de suikerwaarde van het vocht tussen de onderhuidse cellen. De zorgontvanger krijgt de waarden toegestuurd in een app en kan evoluties opvolgen.",

    imageIcon: require("@/assets/images/technological_aides/glucosesensor/glucosesensor_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/glucosesensor/glucosesensor_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/glucosesensor/glucosesensor_375x218.png"),
});

export const CarbohydrateInsulinApp = new TechnologicalAid({
    id: "carbohydrateinsulinapp",
    name: "De koolhydraat-insuline app",
    description: "De koolhydraat-insuline app berekent hoeveel koolhydraten, vetten, eiwitten, calorieën een maaltijd bevat en hoeveel insuline er nodig is om die maaltijd te verwerken.",

    imageIcon: require("@/assets/images/technological_aides/koolhydraat-insuline-app/koolhydraat-insuline-app_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/koolhydraat-insuline-app/koolhydraat-insuline-app_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/koolhydraat-insuline-app/koolhydraat-insuline-app_375x218.png"),
});

export const SubcutaneousInjection = new TechnologicalAid({
    id: "subcutaneousinjection",
    name: "subcutane injectiepoort",
    description: "De subcutane injectiepoort wordt onderhuids geprikt in de buik en om de drie dagen gewisseld. Eens geplaatst kan je drie dagen injecties toedienen, zonder telkens geprikt te worden.",

    imageIcon: require("@/assets/images/technological_aides/subcutane-injectiepoort/subcutane-injectiepoort_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/subcutane-injectiepoort/subcutane-injectiepoort_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/subcutane-injectiepoort/subcutane-injectiepoort_375x218.png"),
});

export const CookBook = new TechnologicalAid({
    id: "cookbook",
    name: "kookboek",
    description: "In dit kookboek vindt de zorgontvanger gezonde recepten die bijdragen tot een gezonde levensstijl, wat belangrijk is om diabetes onder controle te houden.",

    imageIcon: require("@/assets/images/technological_aides/kookboek/kookboek_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/kookboek/kookboek_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/kookboek/kookboek_375x218.png"),
});

export const SmartWaterBottle = new TechnologicalAid({
    id: "smartwaterbottle",
    name: "Slimme waterfles",
    description: "De slimme waterfles houdt bij hoeveel water iemand die dag al gedronken heeft en hoeveel water er dagelijks moet gedronken worden. Iemand die te weinig drinkt, krijgt een melding in z’n app.",

    imageIcon: require("@/assets/images/technological_aides/slimme-waterfles/slimme-waterfles_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/slimme-waterfles/slimme-waterfles_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/slimme-waterfles/slimme-waterfles_375x218.png"),
});

export const SmartOven = new TechnologicalAid({
    id: "smartoven",
    name: "Slimme oven",
    description: "Met een slimme oven kan de zorgontvanger via een app voorverwarmen, de gaartijd en temperatuur aanpassen en het gerecht bekijken, alles van op afstand.",

    imageIcon: require("@/assets/images/technological_aides/slimme-oven/slimme-oven_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/slimme-oven/slimme-oven_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/slimme-oven/slimme-oven_375x218.png"),
});

export const MedicationSmartWatch = new TechnologicalAid({
    id: "medicationsmartwatch",
    name: "Uurwerk met medicatieherinnering",
    description: "Dit uurwerk geeft met een timer aan wanneer de zorgontvanger medicatie moet innemen.",

    imageIcon: require("@/assets/images/technological_aides/medicatieherinnering-uurwerk/medicatieherinnering-uurwerk_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/medicatieherinnering-uurwerk/medicatieherinnering-uurwerk_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/medicatieherinnering-uurwerk/medicatieherinnering-uurwerk_375x218.png"),
});

export const MedicationVendingMachine = new TechnologicalAid({
    id: "medicationvendingmachine",
    name: "Medicatie-automaat",
    description: "De medicatie-automaat is een robot die medicatie vrijgeeft op het moment dat deze moet worden ingenomen.  ",

    imageIcon: require("@/assets/images/technological_aides/medicatie-automaat/medicatie-automaat_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/medicatie-automaat/medicatie-automaat_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/medicatie-automaat/medicatie-automaat_375x218.png"),
});

export const TreadMill = new TechnologicalAid({
    id: "treadmill",
    name: "loopband",
    description: "Met een loopband kan de zorgontvanger z’n conditie verbeteren. Fysieke fitheid en voldoende bewegen zijn belangrijk bij diabetes.",

    imageIcon: require("@/assets/images/technological_aides/loopband/loopband_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/loopband/loopband_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/loopband/loopband_375x218.png"),
});

export const DigitalKitchenScale = new TechnologicalAid({
    id: "digitalkitchenscale",
    name: "Vouwbare digitale keukenweegschaal",
    description: "Deze weegschaal kan de zorgontvanger met zich meenemen om porties nauwkeurig af te wegen en het aantal calorieën te berekenen.",

    imageIcon: require("@/assets/images/technological_aides/vouwbare-keukenweegschaal/vouwbare-keukenweegschaal_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/vouwbare-keukenweegschaal/vouwbare-keukenweegschaal_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/vouwbare-keukenweegschaal/vouwbare-keukenweegschaal_375x218.png"),
});

export const RobotCat = new TechnologicalAid({
    id: "robotcat",
    name: "Snoezelkat",
    description: "De snoezelkat zorgt voor warmte en gezelschap en geeft plezier aan de zorgontvanger. Deze kat is uitgerust met sensoren en maakt levensechte geluiden.",

    imageIcon: require("@/assets/images/technological_aides/snoezelkat/snoezelkat_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/snoezelkat/snoezelkat_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/snoezelkat/snoezelkat_375x218.png")
});

export const MagicTable = new TechnologicalAid({
    id: "magictable",
    name: "Sensorische snoezeltafel",
    description: "De snoezeltafel helpt bij het verbeteren van de levenskwaliteit van de zorgontvanger. De tafel prikkelt de zintuigen en triggert positieve emoties om apathie te doorbreken.",

    imageIcon: require("@/assets/images/technological_aides/snoezeltafel/snoezeltafel_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/snoezeltafel/snoezeltafel_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/snoezeltafel/snoezeltafel_375x218.png")
});

export const RobotFlowerPot = new TechnologicalAid({
    id: "robotflowerpot",
    name: "Robot-bloempot",
    description: "Deze multifunctionele bloempot maakt jouw leven gemakkelijker! Dankzij bluetoothverbinding koppel je de bloempot aan jouw persoonlijke agenda en geeft hij persoonlijke informatie. Daarnaast geeft deze tool je meer informatie over temperatuur, lichtinval en luchtvochtigheid van de kamer.",

    imageIcon: require("@/assets/images/technological_aides/robot-bloempot/robot-bloempot_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/robot-bloempot/robot-bloempot_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/robot-bloempot/robot-bloempot_375x218.png")
});

export const SleepHeadset = new TechnologicalAid({
    id: "sleepheadset",
    name: "Slaap relaxatie headset",
    description: "De koptelefoon en app gebruik je om muziek of natuurgeluiden af te spelen. De app geeft ook persoonlijke tips om de zorgontvanger beter te helpen slapen en stress te verminderen. Valt de cliënt in slaap, dan detecteren de sensoren dit en stopt de audio.",

    imageIcon: require("@/assets/images/technological_aides/headset/headset_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/headset/headset_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/headset/headset_375x218.png")
});

export const MeditationHeadband = new TechnologicalAid({
    id: "meditationheadband",
    name: "Meditatie muziek hoofdband",
    description: "Deze hoofdband meet via sensoren de hersenactiviteit tijdens een meditatieoefening. De hoofdband staat via bluetooth in verbinding met een app die reageert op de drukte en je uiteindelijk helemaal tot rust brengt.",

    imageIcon: require("@/assets/images/technological_aides/meditatie-hoofdband/meditatie-hoofdband_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/meditatie-hoofdband/meditatie-hoofdband_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/meditatie-hoofdband/meditatie-hoofdband_375x218.png")
});

export const SmartSpeaker = new TechnologicalAid({
    id: "smartspeaker",
    name: "Domotica speaker",
    description: "Met een slimme speaker kan de zorgontvanger door middel van spraak muziek aanzetten of selecteren, podcasts of radio beluisteren. Je kan er ook alarmen mee instellen of contact maken met anderen.",

    imageIcon: require("@/assets/images/technological_aides/domotica-speaker/domotica-speaker_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/domotica-speaker/domotica-speaker_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/domotica-speaker/domotica-speaker_375x218.png")
});

export const FrontDoorSticker = new TechnologicalAid({
    id: "frontdoorsticker",
    name: "Voordeursticker",
    description: "De voordeursticker kan de ingang van de kamer in het woonzorgcentrum doen lijken op de voordeur van de oorspronkelijke woning. Dit kan de bewoner het idee geven van in hun oorspronkelijke woning binnen te stappen.",

    imageIcon: require("@/assets/images/technological_aides/voordeursticker/voordeursticker_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/voordeursticker/voordeursticker_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/voordeursticker/voordeursticker_375x218.png")
});

export const Radio = new TechnologicalAid({
    id: "radio",
    name: "Radio",
    description: "Via een radio kan muziek gespeeld worden en geluisterd worden naar praatprogramma’s. Met deze technologie is het mogelijk om radioprogramma’s van vroeger of uit andere landen te beluisteren.",

    imageIcon: require("@/assets/images/technological_aides/radio/radio_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/radio/radio_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/radio/radio_375x218.png")
});

export const Hometrainer = new TechnologicalAid({
    id: "hometrainer",
    name: "Reminiscentie-hometrainer",
    description: "De reminiscentie-hometrainer heeft twee functies: de zorgontvanger activeren en laten fietsen langs bekende routes of langs exotische bestemmingen. De reminiscentie-hometrainer wil de levenskwaliteit verbeteren door mensen fysiek, cognitief en sociaal actief te houden.",

    imageIcon: require("@/assets/images/technological_aides/hometrainer/hometrainer_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/hometrainer/hometrainer_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/hometrainer/hometrainer_375x218.png")
});

export const VRGlasses = new TechnologicalAid({
    id: "vrglasses",
    name: "Virtuele wereld - ervaring",
    description: "Virtual Reality kan helpen als onderdeel van therapie, ontspanning, simulatie of afleiding. Met een VR bril en een app kan je je verplaatsen naar een virtuele omgeving.",

    imageIcon: require("@/assets/images/technological_aides/vr-bril/vr-bril_80x80.png"),
    imageDashboard: require("@/assets/images/technological_aides/vr-bril/vr-bril_167x167.png"),
    imageWebshop: require("@/assets/images/technological_aides/vr-bril/vr-bril_375x218.png")
});