export default class CaseFile
{
    constructor(options)
    {
        options = options ?? {};

        this.id = options.id;
        this.enabled = options.enabled;
        this.version = options.version;
        this.caseInfo = options.caseInfo;
        this.caseSetting = options.caseSetting;
        this.recipient = new Recipient(options.recipient);
        this.locations = options.locations;
        this.items = options.items;
        this.technologicalAids = options.technologicalAids;
        this.team = options.team;
        this.steps = options.steps;
        this.documents = options.documents;
        this.document = new Document(options.document);
        this.pointandclick = options.pointandclick;
    }
}

class Recipient
{
    constructor(options)
    {
        options = options ?? {};
        options.misc = options.misc ?? {};

        this.id = options.id;
        this.firstname = options.firstname;
        this.lastname = options.lastname;
        this.age = options.age;
        this.backstory = options.backstory;

        this.misc = {
            profession: options.misc.profession,
            partner: {
                name: options.misc.partner?.name
            },
            childCount: options.misc.childCount,
            grandchildCount: options.misc.grandchildCount,
            siblingCount: options.misc.siblingCount
        };
    }
}

class Document
{
    constructor(options)
    {
        options = options ?? {};

        this.situationInstructions = options.situationInstructions;
        this.careInstructions = options.careInstructions;
        this.techAidInstructions = options.techAidInstructions;
        this.ethicalInstructions = options.ethicalInstructions;

        this.situation = options.situation;
        this.careTeam = options.careTeam;
        this.collectionItems = options.collectionItems;
        this.careDescription = options.careDescription;
        this.careQuestion = options.careQuestion;
        this.technologicalAid = options.technologicalAid;
        this.ethical = options.ethical;
    }
}