export default class PointAndClickCharacter
{
    constructor(options)
    {
        options = options ?? {};

        this.id = options.id;
        this.imgElements = options.imgElements;
        this.pos = options.pos || { left: 0, top: 0 };
        this.scale = options.scale || { x: 1, y: 1 };
        this.rotation = options.rotation || 0;
        this.layer = options.layer || 0;
        this.animation = options.animation || false;
        this.dontAnimateOnHover = options.dontAnimateOnHover || false;
        this.interactable = options.interactable || false;
        this.sound = options.sound || null;
        this.itemType = options.itemType || "character";
        this.hoverType = options.hoverType || "character";
        this.action = options.action || null;
        this.trigger = options.trigger || null;
        this.name = options.name || "";
        this.description = options.description || "";
    }
}