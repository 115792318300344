export default class CollectionItem
{
    constructor(options)
    {
        options = options ?? {};

        this.id = options.id;
        this.name = options.name;
        this.description = options.description;
        this.hoverName = options.hoverName;
        
        this.img = options.img;
        this.icon = options.icon;
        this.descriptionImage = options.descriptionImage;
    }
}