import PointAndClickScene from "@/models/PointAndClickScene";
import {
    AndreasInRoom,
    ArtFlamenco,
    BackgroundRoomCarlos, BedAssistance, Blanket, CarlosInChair, ClockDigitalDate1,
    MiningHelmet, MiningLamp, PhotoCat,
    PhotoWedding,
    Plant4, Wheelchair
} from "@/assets/data/objects/ZORO-003-objects";
import PointAndClickItem from "@/models/PointAndClickItem";

export const Zoro003IntroFirstTalkRecipientScene = new PointAndClickScene({
    id: 'zoro-002-intro-first_talk_recipient',
    startLocation: 'room-carlos',
    showPhoneButton: true,
    locations: {
        'room-carlos': {
            items: [
                BackgroundRoomCarlos,

                ArtFlamenco,
                PhotoCat,
                PhotoWedding,
                MiningHelmet,
                MiningLamp,
                Plant4,
                ClockDigitalDate1,
                BedAssistance,
                Blanket,

                new PointAndClickItem({
                    ...CarlosInChair,
                    scale: { x: -1, y: 1 },
                    interactable: true,
                    itemType: 'action',
                    hoverType: 'talk',
                    action: 'talk_with_recipient',
                    name: 'Praat met Andreas en Carlos'
                }),
                new PointAndClickItem({
                    ...AndreasInRoom,
                    pos: { left: 202, top: 285 },
                    interactable: true,
                    itemType: 'action',
                    hoverType: 'talk',
                    action: 'talk_with_recipient',
                    name: 'Praat met Andreas en Carlos'
                }),
                new PointAndClickItem({
                    ...Wheelchair,
                    scale: { x: -1, y: 1 }
                })
            ]
        }
    }
});