export default class TechnologicalAid
{
    constructor(options)
    {
        options = options ?? {};

        this.id = options.id;
        this.name = options.name;
        this.description = options.description;
        this.imageIcon = options.imageIcon;
        this.imageWebshop = options.imageWebshop;
        this.imageDashboard = options.imageDashboard;
    }
}