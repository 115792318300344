import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";
import { Blink } from "@/assets/data/animations/animations";
import {
    AlarmSystem,
    Chair,
    FootballScarf,
    HospitalBed,
    LukasCellphone
} from "@/assets/data/items/collection_items-ZORO-002";

const bgPosDeltaX = 180;
const bgPosDeltaY = 180;

export const Zoro002IntroFirstTalkRecipientScene = new PointAndClickScene({

    id: 'zoro-002-intro-first_talk_recipient',
    startLocation: 'hospitalroom',
    showPhoneButton: true,
    locations: {
        'hospitalroom': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro002/bg_hospitalroom.png'),
                    pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'tv',
                    img: require('@/assets/images/scenes/zoro002/objects/_general/tv_footbal.png'),
                    pos: { left: 1812 - bgPosDeltaX, top: 223 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: HospitalBed.id,
                    img: HospitalBed.img,
                    pos: { left: 979 - bgPosDeltaX, top: 623 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'soda',
                    img: require('@/assets/images/scenes/zoro002/objects/_general/can_soda.png'),
                    pos: { left: 899 - bgPosDeltaX, top: 785 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'room-cart',
                    img: require('@/assets/images/scenes/zoro002/objects/_general/roomcart.png'),
                    pos: { left: 742 - bgPosDeltaX, top: 827 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'recipient',
                    img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_full_body.png'),
                    imgElements: [
                        {
                            id: 'eyes',
                            pos: { left: 114, top: 86 },
                            layer: 2,
                            img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_full_eo.png'),
                            frames: [
                                {
                                    id: 'open',
                                    img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_full_eo.png')
                                },
                                {
                                    id: 'closed',
                                    img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_full_ec.png')
                                }
                            ],
                            timing: Blink.timing
                        },
                        {
                            id: 'mouth',
                            pos: { left: 125, top: 116 },
                            layer: 2,
                            img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_full_mc.png')
                        }
                    ],
                    pos: { left: 1441 - bgPosDeltaX, top: 620 - bgPosDeltaY },
                    scale: { x: -1, y: 1 },
                    layer: 2,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    action: "talk_with_recipient",
                    name: "Praat met Lukas"
                }),
                new PointAndClickItem({
                    id: AlarmSystem.id,
                    img: AlarmSystem.img,
                    pos: { left: 977 - bgPosDeltaX, top: 718 - bgPosDeltaY },
                    layer: 2
                }),
                new PointAndClickItem({
                    id: LukasCellphone.id,
                    img: LukasCellphone.img,
                    pos: { left: 1300 - bgPosDeltaX, top: 815 - bgPosDeltaY },
                    layer: 2
                }),
                new PointAndClickItem({
                    id: FootballScarf.id,
                    img: FootballScarf.img,
                    pos: { left: 971 - bgPosDeltaX, top: 883 - bgPosDeltaY },
                    layer: 2
                }),
                new PointAndClickItem({
                    id: Chair.id,
                    img: Chair.img,
                    pos: { left: 86 - bgPosDeltaX, top: 942 - bgPosDeltaY },
                    layer: 2
                })
            ],
        },
    },
});