export default {
    methods: {
        playButtonTap(type)
        {
            switch (type)
            {
                case 'phone' :
                    return this.playPhoneTap();
                default:
                    return this.playButtonTapGeneric();
            }
        },
        playButtonTapGeneric()
        {
            return this.play(require("@/assets/sounds/button_tap_generic.mp3"));
        },
        playAddItemSound()
        {
            return this.play(require("@/assets/sounds/item_add.mp3"));
        },
        playRemoveItemSound()
        {
            return this.play(require("@/assets/sounds/item_remove.mp3"));
        },
        playFeedback(type)
        {
            switch (type)
            {
                case 'positive':
                    return this.playFeedbackPositive();
                case 'negative':
                    return this.playFeedbackNegative();
            }
        },
        playFeedbackNegative()
        {
            return this.play(require("@/assets/sounds/feedback_negative.mp3"));
        },
        playFeedbackPositive()
        {
            return this.play(require("@/assets/sounds/feedback_positive.mp3"));
        },
        playMessageFeedback(type)
        {
            switch (type)
            {
                case 'positive':
                    return this.playMessageFeedbackPositive();
                case 'negative':
                    return this.playMessageFeedbackNegative();
            }
        },
        playMessageFeedbackNegative()
        {
            return this.play(require("@/assets/sounds/message_feedback_negative.mp3"));
        },
        playMessageFeedbackPositive()
        {
            return this.play(require("@/assets/sounds/message_feedback_positive.mp3"));
        },
        playMessageIncoming()
        {
            return this.play(require("@/assets/sounds/message_incoming.mp3"));
        },
        playPointAndClickClueFound()
        {
            return this.play(require("@/assets/sounds/p_c_clue_found.mp3"));
        },
        playPointAndClickHover()
        {
            return undefined;
            // return this.play(require("@/assets/sounds/p_c_hover.mp3"));
        },
        playPhoneSound(type)
        {
            switch (type)
            {
                case 'alert':
                    return this.playPhoneAlert();
                case 'reminder':
                    return this.playPhoneReminder();
                case 'tap':
                    return this.playPhoneTap();
                case 'vibrate':
                    return this.playPhoneVibration();
            }
        },
        playPhoneAlert()
        {
            return this.play(require("@/assets/sounds/phone_alert.mp3"));
        },
        playPhoneReminder()
        {
            return this.play(require("@/assets/sounds/phone_reminder.mp3"));
        },
        playPhoneTap()
        {
            return this.play(require("@/assets/sounds/phone_tap.mp3"));
        },
        playPhoneVibration()
        {
            return this.play(require("@/assets/sounds/phone_vibration.mp3"));
        },
        playCallEndSound()
        {
            return this.play(require("@/assets/sounds/call_end.mp3"));
        },
        playPopupSound(type)
        {
            switch (type)
            {
                case 'conclusion':
                    return this.playPopupConclusion();
            }
        },
        playPopupConclusion()
        {
            return this.play(require("@/assets/sounds/popup_conclusion.mp3"));
        },
        playTransitionSound(type)
        {
            switch (type)
            {
                case 'area':
                    return this.playTransitionArea();
                case 'call':
                    return this.playTransitionCall();
                case 'gps':
                    return this.playTransitionGPS();
            }
        },
        playTransitionArea()
        {
            return this.play(require("@/assets/sounds/transition_area.mp3"));
        },
        playTransitionCall()
        {
            return this.play(require("@/assets/sounds/transition_call.mp3"));
        },
        playTransitionGPS()
        {
            return this.play(require("@/assets/sounds/transition_gps.mp3"));
        },
        play(audioSource)
        {
            let audio = new Audio(audioSource);
            audio.play();
            return audio;
        }
    }
}