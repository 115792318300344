import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";

export const Zoro001IntroGoalSeekerFutureScene = new PointAndClickScene({

    id: 'zoro-001-intro-goal_seeker_future',
    startLocation: 'goal_seeker_future',
    showPhoneButton: false,
    locations: {
        'goal_seeker_future': {
            items: [
                new PointAndClickItem({
                    id: 'future_bg',
                    img: require('@/assets/images/scenes/zoro001/bg_c1_doelzoeker.png'),

                    pos: { left: -180, top: -180 },
                    layer: 2,

                }),

                //objects
                new PointAndClickItem({
                    id: 'goalseeker_bikehelmet',
                    img: require('@/assets/images/scenes/zoro001/objects/bikehelmet/bikehelmet_120x120.png'),
                    pos: { left: 1500, top: 300 },
                    layer: 10,
                    subtitle: "Elektrische fiets",
                    goalSeekerTime: 'future',

                }),
                new PointAndClickItem({
                    id: 'goalseeker_flowers',
                    img: require('@/assets/images/scenes/zoro001/objects/flowers/flowers_120x120.png'),
                    pos: { left: 250, top: 300 },
                    layer: 10,
                    subtitle: "Bloemen",
                    goalSeekerTime: 'past',

                }),
                new PointAndClickItem({
                    id: 'goalseeker_dog',
                    img: require('@/assets/images/scenes/zoro001/objects/dog_2/dog_2_120x120.png'),
                    pos: { left: 700, top: 600 },
                    layer: 10,
                    subtitle: "Spence",
                    goalSeekerTime: 'present',
                }),
                new PointAndClickItem({
                    id: 'goalseeker_family_photo',
                    img: require('@/assets/images/scenes/zoro001/objects/photo_1/photo_1_120x120.png'),
                    pos: { left: 900, top: 800 },
                    layer: 10,
                    subtitle: "Familiefoto",
                    goalSeekerTime: 'present',
                }),
                new PointAndClickItem({
                    id: 'goalseeker_art',
                    img: require('@/assets/images/scenes/zoro001/objects/art_1/art_1_120x120.png'),
                    pos: { left: 350, top: 700 },
                    layer: 10,
                    subtitle: "Kunst",
                    goalSeekerTime: 'past',
                }),
                new PointAndClickItem({
                    id: 'goalseeker_wheelchair',
                    img: require('@/assets/images/scenes/zoro001/objects/wheelchair/wheelchair_120x120.png'),
                    pos: { left: 800, top: 200 },
                    layer: 10,
                    subtitle: "Rolstoel",
                    goalSeekerTime: 'present',
                }),
            ],
        }
    },
});