import CollectionItem from "@/models/CollectionItem";

/*
    INTRO
*/
export const Art = new CollectionItem({
    id: 'art_1',
    img: require('@/assets/images/scenes/zoro001/objects/art_1/art_1.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/art_1/art_1_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/art_1/art_1_400x400.png'),
    name: "Stilleven met Spence (By Stien M.)",
    description: "Stien houdt van kunst. Hier zie je een werk dat ze zelf heeft geschilderd.",
    hoverName: "Kunstwerk"
});

export const Helmet = new CollectionItem({
    id: 'bikehelmet',
    img: require('@/assets/images/scenes/zoro001/objects/bikehelmet/bikehelmet.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/bikehelmet/bikehelmet_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/bikehelmet/bikehelmet_400x400.png'),
    name: "Fietshelm van Jan",
    description: "Jan, de partner van Stien, is een fervent fietser.",
    hoverName: "Fietshelm"
});

export const Photo = new CollectionItem({
    id: 'photo_1',
    img: require('@/assets/images/scenes/zoro001/objects/photo_1/photo_1.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/photo_1/photo_1_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/photo_1/photo_1_400x400.png'),
    name: "Familiefoto",
    description: "Op deze foto zie je Stien met haar familie. Familie is heel belangrijk in haar leven.",
    hoverName: "Kader met foto aan de muur"
});

export const Dog = new CollectionItem({
    id: 'dog_2',
    img: require('@/assets/images/scenes/zoro001/objects/dog_2/dog_2.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/dog_2/dog_2_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/dog_2/dog_2_400x400.png'),
    name: "Hond",
    description: "Dit is Spence. Stien haalt heel veel energie uit het gezelschap van haar hond. Spence is een lieve en ondeugende hond. Hij is goed gezelschap als ze alleen thuis is.",
    hoverName: "Hond"
});

export const Flowers = new CollectionItem({
    id: 'flowers',
    img: require('@/assets/images/scenes/zoro001/objects/flowers/flowers.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/flowers/flowers_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/flowers/flowers_400x400.png'),
    name: "Vaas met bloemen",
    description: "Stien heeft een grote passie voor tuinieren. Door haar aandoening kan ze deze hobby niet meer uitvoeren. Dankzij haar partner staan er wel vaak bloemen uit hun eigen tuin in huis.",
    hoverName: "Bloemen"
});

export const Wheelchair = new CollectionItem({
    id: 'wheelchair',
    img: require('@/assets/images/scenes/zoro001/objects/wheelchair/wheelchair.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/wheelchair/wheelchair_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/wheelchair/wheelchair_400x400.png'),
    name: "Rolstoel",
    description: "Sinds haar beroerte gebruikt Stien deze rolstoel om verre afstanden te maken. Zo behoudt ze toch nog haar zelfstandigheid.",
    hoverName: "Rolstoel"
});

//decoys

export const Drawer = new CollectionItem({
    id: 'drawer',
    img: require('@/assets/images/scenes/zoro001/objects/drawer/drawer.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/drawer/drawer_400x400.png'),
    name: "Schuif",
    description: "Alles netjes op orde, dat is Stien haar motto.",
    hoverName: "Schuif in gangkast"
});

export const Plant2 = new CollectionItem({
    id: 'plant_2',
    img: require('@/assets/images/scenes/zoro001/objects/plant_2/plant_2.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/plant_2/plant_2_400x400.png'),
    name: "Bananenboom",
    description: "Hoe meer groen in huis, hoe beter.",
    hoverName: "Kamerplant"
})

export const Photo2 = new CollectionItem({
    id: 'photo_2',
    img: require('@/assets/images/scenes/zoro001/objects/photo_2/photo_2.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/photo_2/photo_2_400x400.png'),
    name: "Vakantieherinnering",
    description: "Wat was Jan blij toen hij de top bereikte.",
    hoverName: "Fotokader - Jan in fietsoutfit op de Mont-Ventoux"
});

export const Chair = new CollectionItem({
    id: 'chair',
    img: require('@/assets/images/scenes/zoro001/objects/chair/chair.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/chair/chair_400x400.png'),
    name: "[TEMP] chair",
    description: "[TEMP] chair",
    hoverName: "[TEMP] chair"
});

export const Fridge = new CollectionItem({
    id: 'fridge',
    img: require('@/assets/images/scenes/zoro001/objects/fridge/fridge.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/fridge/fridge_400x400.png'),
    name: "Koelkast",
    description: "Lekker en gezond eten is belangrijk. De koelkast zit steeds vol met lekkernijen.",
    hoverName: "Koelkast"
});

export const CoffeeMachine = new CollectionItem({
    id: 'coffeemachine',
    img: require('@/assets/images/scenes/zoro001/objects/coffeemachine/coffeemachine.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/coffeemachine/coffeemachine_400x400.png'),
    name: "Koffiemachine",
    description: "Genieten van een lekkere kop koffie, dat doet een mens veel deugd.",
    hoverName: "Koffiemachine"
})

export const DogBed = new CollectionItem({
    id: 'dogbed_1',
    img: require('@/assets/images/scenes/zoro001/objects/dogbed_2/dogbed_2.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/dogbed_2/dogbed_2_400x400.png'),
    name: "Spence zijn mand",
    description: "Zonder Spence zou het maar stil zijn in huis. Spence tovert een glimlach op iedereens gezicht.",
    hoverName: "Hondenmand"
});

export const ShowerStool = new CollectionItem({
    id: 'showerstool',
    img: require('@/assets/images/scenes/zoro001/objects/showerstool/showerstool.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/showerstool/showerstool_400x400.png'),
    name: "Douchestoel",
    description: "De douchestoel wordt door de kleinkinderen gebruikt als ze komen logeren. Voor hen is dit de “regentroon”. Stien moet hier altijd om lachen.",
    hoverName: "Douchestoel"
});

export const ArmRest = new CollectionItem({
    id: 'armrest',
    img: require('@/assets/images/scenes/zoro001/objects/armrest/armrest.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/armrest/armrest_400x400.png'),
    name: "Armleuning toilet",
    description: "Door kleine ingrepen in de badkamer kan Stien toch nog zelfstandig functioneren.",
    hoverName: "Armleuning toilet"
});

export const Plant = new CollectionItem({
    id: 'plant_1',
    img: require('@/assets/images/scenes/zoro001/objects/plant_1/plant_1.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/plant_1/plant_1_400x400.png'),
    name: "Hangplantje",
    description: "Jan vindt het een raar idee om een plant in de badkamer te zetten. Maar Stien vindt dat je je altijd moet omringen met mooie dingen.",
    hoverName: "Hangplantje op rekje"
});

export const Coat = new CollectionItem({
    id: 'coat',
    img: require('@/assets/images/scenes/zoro001/objects/coat/coat.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/coat/coat_400x400.png'),
    name: "Regenjas",
    description: "Stien gaat graag buiten ook als het regent.",
    hoverName: "Jas aan kapstok"
});

/*
    FOLLOWUP
*/

//collectables
export const Gift = new CollectionItem({
    id: 'gift',
    img: require('@/assets/images/scenes/zoro001/objects/gift/gift.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/gift/gift_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/gift/gift_400x400.png'),
    name: "Cadeau",
    description: "TEMP",
    hoverName: "Cadeau"
});

export const CellPhone = new CollectionItem({
    id: 'cell_phone',
    img: require('@/assets/images/characters/huishoudhulp/huishoudhulp_full_body.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/phone/phone_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/phone/phone_400x400.png'),
    name: "GSM",
    description: "TEMP",
    hoverName: "Huishoudhulp"
});

export const Datasheet = new CollectionItem({
    id: 'datasheet',
    img: require('@/assets/images/scenes/zoro001/objects/datasheet/datasheet.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/datasheet/datasheet_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/datasheet/datasheet_400x400.png'),
    name: "Blad met grafieken",
    description: "TEMP",
    hoverName: "Blad met grafieken"
});

//decoys

export const WheelchairFollowup = new CollectionItem({
    id: 'wheelchair_followup',
    img: require('@/assets/images/scenes/zoro001/objects/wheelchair/wheelchair.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/wheelchair/wheelchair_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/wheelchair/wheelchair_400x400.png'),
    name: "Rolstoel",
    description: "Dankzij deze rolstoel kan Stien zich nog vlot bewegen in huis als ze een moeilijke dag heeft.",
    hoverName: "Rolstoel"
});

export const DogFollowup = new CollectionItem({
    id: 'dog_1',
    img: require('@/assets/images/scenes/zoro001/objects/dog_1/dog_1.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/dog_1/dog_1_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/dog_1/dog_1_400x400.png'),
    name: "Hond Spence",
    description: "Spence is steeds het zonnetje in huis.",
    hoverName: "Spence"
});

export const ArtFollowup = new CollectionItem({
    id: 'art_2',
    img: require('@/assets/images/scenes/zoro001/objects/art_2/art_2.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/art_2/art_2_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/art_2/art_2_400x400.png'),
    name: "Schilderij gemaakt door Stien.",
    description: "Stien omringt zich graag met zaken die ze mooi vindt.",
    hoverName: "Kunstwerk"
});

export const ToiletFollowup = new CollectionItem({
    id: 'toilet',
    img: require('@/assets/images/scenes/zoro001/objects/toilet/toilet.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/toilet/toilet_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/toilet/toilet_400x400.png'),
    name: "Aangepast toilet",
    description: "Het huis van Stien is helemaal op haar maat ingericht.",
    hoverName: "Hangtoilet"
});

export const PhotoFollowup = new CollectionItem({
    id: 'photo_3',
    img: require('@/assets/images/scenes/zoro001/objects/photo_3/photo_3.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/photo_3/photo_3_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/photo_3/photo_3_400x400.png'),
    name: "Sfeerbeeld uit de tuin",
    description: "Omringd door haar bloemen, voelt Stien zich super.",
    hoverName: "Foto Stien - tuinieren"
});

export const LampFollowup = new CollectionItem({
    id: 'lamp',
    img: require('@/assets/images/scenes/zoro001/objects/lamp/lamp.png'),
    icon: require('@/assets/images/scenes/zoro001/objects/lamp/lamp_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro001/objects/lamp/lamp_400x400.png'),
    name: "Leeslamp",
    description: "In deze gezellige hoek is het ‘s avonds aangenaam zitten.",
    hoverName: "Leeslamp"
});



