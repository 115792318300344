export default class PersonBodyPart
{
    constructor(options)
    {
        options = options ?? {};

        this.id = options.id;
        this.frames = options.frames;
        this.position = options.position;
        this.timing = options.timing;
    }
}
