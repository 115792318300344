import PointAndClickScene from "@/models/PointAndClickScene";
import {
    AminaInNurseStation,
    Aquarium,
    ArtFlamenco,
    BackgroundHallway,
    BackgroundNurseStation,
    BackgroundRecreationRoom,
    BackgroundRoomCarlos, BasInRecreationRoom,
    BedAssistance, Bingo,
    Blanket, CalenderRip,
    CarlosRoomDoor, CartMedication,
    Chair,
    ClockDigitalDate1, ClockWallModern, ClockWallOld, Cup1, Cup2, EstherInHallway, FilingCabinet, FlowersGift,
    HallwayDoorRight, MagazineStack,
    MiningHelmet,
    MiningLamp, MoveHitboxLeft, MoveHitboxRight, NurseStationRoomDoor, PaperTowelDispenser,
    PhotoCat, PhotoMiners,
    PhotoWedding, Plant3, Plant3Hallway,
    Plant4, PosterMenu, PosterPlanner, Railing, Rollator, StickerDoor, TableChairs, TVRecreationRoom
} from "@/assets/data/objects/ZORO-003-objects";
import PointAndClickItem from "@/models/PointAndClickItem";
import {
    AquariumCollectionItem,
    BingoCollectionItem,
    BlanketCollectionItem,
    CartMedicationCollectionItem,
    Cup1CollectionItem,
    FilingCabinetCollectionItem,
    FlowersGiftCollectionItem,
    MiningLampCollectionItem,
    PhotoCatCollectionItem, PhotoMinersCollectionItem,
    PosterPlannerCollectionItem,
    RailingCollectionItem,
    RollatorCollectionItem, StickerDoorCollectionItem, TVRecreationRoomCollectionItem
} from "@/assets/data/items/collection_items-ZORO-003";

export const Zoro003IntroCollectItemsScene = new PointAndClickScene({
    id: 'zoro-003-intro-collect_items',
    type: 'collect_items',
    showPhoneButton: true,
    startLocation: 'room-carlos',
    locations: {
        'room-carlos': {
            items: [
                BackgroundRoomCarlos,

                ArtFlamenco,
                new PointAndClickItem({
                    ...PhotoCat,
                    interactable: true,
                    itemType: 'item',
                    collectable: true,
                    itemData: PhotoCatCollectionItem
                }),
                PhotoWedding,
                MiningHelmet,
                new PointAndClickItem({
                    ...MiningLamp,
                    interactable: true,
                    itemType: 'item',
                    collectable: true,
                    itemData: MiningLampCollectionItem
                }),
                Plant4,
                ClockDigitalDate1,
                BedAssistance,
                new PointAndClickItem({
                    ...Blanket,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: BlanketCollectionItem
                }),
                Chair,


                new PointAndClickItem({
                    ...CarlosRoomDoor,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang',
                }),
            ]
        },
        'hallway': {
            items: [
                BackgroundHallway,

                Plant3Hallway,
                PosterMenu,

                new PointAndClickItem({
                    ...StickerDoor,
                    interactable: true,
                    itemType: 'item',
                    collectable: true,
                    itemData: StickerDoorCollectionItem
                }),
                new PointAndClickItem({
                    ...HallwayDoorRight,
                    sound: undefined,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'room-carlos',
                    name: 'Ga naar kamer',
                    hoverName: 'Ga naar kamer'
                }),
                new PointAndClickItem({
                    ...PosterPlanner,
                    interactable: true,
                    itemType: 'item',
                    collectable: true,
                    itemData: PosterPlannerCollectionItem
                }),
                new PointAndClickItem({
                    ...Railing,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: RailingCollectionItem
                }),
                new PointAndClickItem({
                    ...Rollator,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: RollatorCollectionItem
                }),
                EstherInHallway,

                new PointAndClickItem({
                    ...MoveHitboxLeft,
                    id: 'to-nurse-station',
                    moveLocation: 'nurse-station',
                    name: 'Ga naar verpleegpost',
                    hoverName: 'Ga naar verpleegpost',
                }),
                new PointAndClickItem({
                    ...MoveHitboxRight,
                    id: 'to-recreation-room',
                    moveLocation: 'recreation-room',
                    name: 'Ga naar leefruimte',
                    hoverName: 'Ga naar leefruimte',
                })
            ]
        },
        'nurse-station': {
            items: [
                BackgroundNurseStation,

                Cup2,
                ClockWallModern,
                PaperTowelDispenser,

                new PointAndClickItem({
                    ...NurseStationRoomDoor,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang'
                }),

                new PointAndClickItem({
                    ...FilingCabinet,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: FilingCabinetCollectionItem
                }),
                new PointAndClickItem({
                    ...FlowersGift,
                    interactable: true,
                    itemType: 'item',
                    collectable: true,
                    itemData: FlowersGiftCollectionItem
                }),
                new PointAndClickItem({
                    ...Cup1,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: Cup1CollectionItem
                }),
                new PointAndClickItem({
                    ...CartMedication,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: CartMedicationCollectionItem
                }),

                AminaInNurseStation
            ]
        },
        'recreation-room': {
            items: [
                BackgroundRecreationRoom,

                new PointAndClickItem({
                    ...TVRecreationRoom,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: TVRecreationRoomCollectionItem
                }),
                Plant3,
                TableChairs,
                ClockWallOld,
                CalenderRip,
                MagazineStack,

                new PointAndClickItem({
                    ...PhotoMiners,
                    interactable: true,
                    itemType: 'item',
                    collectable: true,
                    itemData: PhotoMinersCollectionItem
                }),
                new PointAndClickItem({
                    ...Bingo,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: BingoCollectionItem
                }),
                new PointAndClickItem({
                    ...Aquarium,
                    interactable: true,
                    itemType: 'item',
                    collectable: false,
                    itemData: AquariumCollectionItem
                }),

                BasInRecreationRoom,

                new PointAndClickItem({
                    ...MoveHitboxLeft,
                    id: 'to-hallway',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang',
                })
            ]
        }
    }
});