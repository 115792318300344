import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";
import {
    AlarmSystem, Bathrobe, BathroomAlarm,
    BookRack, BrochureDiabetes, Chair,
    Clock,
    Computer, FireAlarm, HospitalBed, HospitalTV, KarimInsuline, Laptop, LukasBackpack,
    LukasToiletBag,
    Poster, PosterHandHygiene, PosterHyperglycemia, PosterHypoglycemia, WallDispenser, ZoroBrochure
} from "@/assets/data/items/collection_items-ZORO-002";
import { Blink } from "@/assets/data/animations/animations";

const bgPosDeltaX = 180;
const bgPosDeltaY = 180;

export const Zoro002FollowupFindRecipientScene = new PointAndClickScene({

    id: 'zoro-002-followup-find_recipient',
    startLocation: 'hallway',
    showPhoneButton: true,
    startInPreviousSceneLocation: true,
    locations: {
        'hallway': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro002/bg_corridor.png'),
                    pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: WallDispenser.id,
                    img: WallDispenser.img,
                    pos: { left: 904 - bgPosDeltaX, top: 612 - bgPosDeltaY },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: FireAlarm.id,
                    img: FireAlarm.img,
                    pos: { left: 1317 - bgPosDeltaX, top: 690 - bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'door-to-hospitalroom',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_c.png'),
                    pos: { left: 483 - bgPosDeltaX, top: 358 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hospitalroom',
                    name: 'Ga naar kamer',
                    hoverName: 'Ga naar kamer',
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_o.png'),
                    dontAnimateOnHover: true
                }),
                new PointAndClickItem({
                    id: 'door-to-waitroom',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_dbl_c.png'),
                    pos: { left: 1462 - bgPosDeltaX, top: 376 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'waitroom',
                    name: 'Ga naar wachtkamer',
                    hoverName: 'Ga naar wachtkamer',
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_dbl_o.png'),
                    dontAnimateOnHover: true
                }),
                new PointAndClickItem({
                    id: Poster.id,
                    img: Poster.img,
                    pos: { left: 1066 - bgPosDeltaX, top: 464 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: KarimInsuline.id,
                    img: KarimInsuline.img,
                    imgElements: [
                        {
                            id: 'eyes',
                            pos: { left: 91, top: 91 },
                            layer: 2,
                            img: require('@/assets/images/characters/dietist/dietist_full_eo.png'),
                            frames: [
                                {
                                    id: 'open',
                                    img: require('@/assets/images/characters/dietist/dietist_full_eo.png')
                                },
                                {
                                    id: 'closed',
                                    img: require('@/assets/images/characters/dietist/dietist_full_ec.png')
                                }
                            ],
                            timing: Blink.timing
                        },
                        {
                            id: 'mouth',
                            pos: { left: 99, top: 121 },
                            layer: 2,
                            img: require('@/assets/images/characters/dietist/dietist_full_mc.png')
                        }
                    ],
                    pos: { left: 1066 - bgPosDeltaX, top: 464 - bgPosDeltaY },
                    layer: 2,
                })
            ]
        },
        'waitroom': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro002/bg_waitingroom.png'),
                    pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: PosterHypoglycemia.id,
                    img: PosterHypoglycemia.img,
                    pos: { left: 1170 - bgPosDeltaX, top: 414 - bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: PosterHyperglycemia.id,
                    img: PosterHyperglycemia.img,
                    pos: { left: 1407 - bgPosDeltaX, top: 408 - bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: BrochureDiabetes.id,
                    img: BrochureDiabetes.img,
                    pos: { left: 1125 - bgPosDeltaX, top: 882 - bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: ZoroBrochure.id,
                    img: ZoroBrochure.img,
                    pos: { left: 397 - bgPosDeltaX, top: 750 - bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'to_living_room',
                    img: require('@/assets/images/scenes/zoro002/move_hitbox.png'),
                    pos: { left: 25, top: 480 },
                    layer: 4,
                    interactable: true,
                    itemType: "move",
                    moveLocation: "hallway",
                    name: "Ga naar gang",
                    hoverName: "Ga naar gang",
                }),
                new PointAndClickItem({
                    id: Clock.id,
                    img: Clock.img,
                    pos: { left: 1378 - bgPosDeltaX, top: 223 - bgPosDeltaY },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: Computer.id,
                    img: Computer.img,
                    pos: { left: 692 - bgPosDeltaX, top: 600 - bgPosDeltaY },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: BookRack.id,
                    img: BookRack.img,
                    pos: { left: 320 - bgPosDeltaX, top: 505 - bgPosDeltaY },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'amina-eyes',
                    imgElements: [
                        {
                            id: 'eyes',
                            pos: { left: 0, top: 0 },
                            layer: 2,
                            img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_bg_eo.png'),
                            frames: [
                                {
                                    id: 'open',
                                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_bg_eo.png')
                                },
                                {
                                    id: 'closed',
                                    img: require('@/assets/images/characters/verpleegkundige/verpleegkundige_bg_ec.png')
                                }
                            ],
                            timing: Blink.timing
                        }
                    ],
                    pos: { left: 404, top: 429 },
                    layer: 2
                })
            ],
        },
        'hospitalroom': {
            items: [
                new PointAndClickItem({
                    id: 'bg',
                    img: require('@/assets/images/scenes/zoro002/bg_hospitalroom.png'),
                    pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: HospitalBed.id,
                    img: HospitalBed.img,
                    pos: { left: 979 - bgPosDeltaX, top: 623 - bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'roomcart',
                    img: require('@/assets/images/scenes/zoro002/objects/_general/roomcart.png'),
                    pos: { left: 742 - bgPosDeltaX, top: 827 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'door-to-hallway',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_c.png'),
                    pos: { left: 206 - bgPosDeltaX, top: 358 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hallway',
                    name: 'Ga naar gang',
                    hoverName: 'Ga naar gang',
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_o.png'),
                    dontAnimateOnHover: true
                }),
                new PointAndClickItem({
                    id: 'door-to-bathroom',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_c.png'),
                    pos: { left: 1538 - bgPosDeltaX, top: 397 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'bathroom',
                    name: 'Ga naar badkamer',
                    hoverName: 'Ga naar badkamer',
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_o.png'),
                    dontAnimateOnHover: true
                }),
                new PointAndClickItem({
                    id: AlarmSystem.id,
                    img: AlarmSystem.img,
                    pos: { left: 977 - bgPosDeltaX, top: 718 - bgPosDeltaY },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: Chair.id,
                    img: Chair.img,
                    pos: { left: 86 - bgPosDeltaX, top: 942 - bgPosDeltaY },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: 'flowers',
                    img: require('@/assets/images/scenes/zoro002/objects/_general/flowers.png'),
                    pos: { left: 865 - bgPosDeltaX, top: 708 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: Laptop.id,
                    img: Laptop.img,
                    pos: { left: 752 - bgPosDeltaX, top: 750 - bgPosDeltaY },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: LukasBackpack.id,
                    img: LukasBackpack.img,
                    pos: { left: 829 - bgPosDeltaX, top: 1004 - bgPosDeltaY },
                    layer: 2,
                }),
                new PointAndClickItem({
                    id: HospitalTV.id,
                    img: HospitalTV.img,
                    pos: { left: 1812 - bgPosDeltaX, top: 223 - bgPosDeltaY },
                    layer: 1
                }),
                new PointAndClickItem({
                    id: 'recipient',
                    img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_full_body.png'),
                    imgElements: [
                        {
                            id: 'eyes',
                            pos: { left: 114, top: 86 },
                            layer: 2,
                            img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_full_eo.png'),
                            frames: [
                                {
                                    id: 'open',
                                    img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_full_eo.png')
                                },
                                {
                                    id: 'closed',
                                    img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_full_ec.png')
                                }
                            ],
                            timing: Blink.timing
                        },
                        {
                            id: 'mouth',
                            pos: { left: 125, top: 116 },
                            layer: 2,
                            img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_full_mc.png')
                        }
                    ],
                    pos: { left: 516 - bgPosDeltaX, top: 590 - bgPosDeltaY },
                    scale: { x: -1, y: 1 },
                    layer: 5,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    action: "talk_with_recipient",
                    name: "Praat met Lukas"
                })
            ]
        },
        'bathroom': {
            items: [
                new PointAndClickItem({
                    id: 'bg_bathroom',
                    img: require('@/assets/images/scenes/zoro002/bg_bathroom.png'),
                    pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: PosterHandHygiene.id,
                    img: PosterHandHygiene.img,
                    pos: { left: 1101 - bgPosDeltaX, top: 432 - bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: BathroomAlarm.id,
                    img: BathroomAlarm.img,
                    pos: { left: 522 - bgPosDeltaX, top: 715 - bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: LukasToiletBag.id,
                    img: LukasToiletBag.img,
                    pos: { left: 1024 - bgPosDeltaX, top: 727 - bgPosDeltaY },
                    layer: 1,
                }),
                new PointAndClickItem({
                    id: 'door-to-hospitalroom',
                    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_c.png'),
                    pos: { left: 1225 - bgPosDeltaX, top: 358 - bgPosDeltaY },
                    layer: 1,
                    interactable: true,
                    itemType: 'move',
                    moveLocation: 'hospitalroom',
                    name: 'Ga naar kamer',
                    hoverName: 'Ga naar kamer',
                    hoverImage: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_o.png'),
                    dontAnimateOnHover: true
                }),
                new PointAndClickItem({
                    id: Bathrobe.id,
                    img: Bathrobe.img,
                    pos: { left: 1655 - bgPosDeltaX, top: 515 - bgPosDeltaY },
                    layer: 2,
                })
            ]
        }
    },
});