export default class GameScore
{
    constructor(options)
    {
        options = options ?? {};
        
        this.interprofessionalCollaboration = options.interprofessionalCollaboration ?? 0;
        this.technicalManeuverability = options.technicalManeuverability ?? 0;
        this.intrapreneurship = options.intrapreneurship ?? 0;
        this.actingEthically = options.actingEthically ?? 0;
    }
}