import PointAndClickScene from "@/models/PointAndClickScene";
import {
    ArtFlamenco,
    BackgroundRoomCarlos,
    BedAssistance,
    Blanket, CarlosInChair,
    CatRobot,
    ClockDigitalDate2,
    MiningHelmet,
    MiningLamp,
    PhotoCat,
    PhotoWedding,
    Plant4, Wheelchair
} from "@/assets/data/objects/ZORO-003-objects";
import PointAndClickCharacter from "@/models/PointAndClickCharacter";
import { Blink } from "@/assets/data/animations/animations";
import PointAndClickItem from "@/models/PointAndClickItem";

export const Zoro003FollowupRoomTalkRecipientScene = new PointAndClickScene({
    id: 'zoro-002-followup-hospital_room_talk_recipient',
    startLocation: 'room-carlos',
    showPhoneButton: false,
    locations: {
        'room-carlos': {
            items: [
                BackgroundRoomCarlos,

                ArtFlamenco,
                MiningLamp,
                PhotoCat,
                Plant4,
                ClockDigitalDate2,
                PhotoWedding,
                MiningHelmet,
                BedAssistance,
                Blanket,

                new PointAndClickItem({
                    ...CarlosInChair,
                    scale: { x: -1, y: 1 },
                }),
                new PointAndClickItem({
                    ...Wheelchair,
                    scale: { x: -1, y: 1 }
                }),

                CatRobot,
            ],
            characters: [
                new PointAndClickCharacter({
                    id: 'andreas',
                    layer: 5,
                    imgElements: [
                        {
                            id: 'andreas_bg',
                            img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_dialog_body.png'),
                            pos: { left: 600, top: 160 }
                        },
                        {
                            id: 'eyes',
                            pos: { left: 964, top: 340 },
                            layer: 2,
                            img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_dialog_eo.png'),
                            frames: [
                                {
                                    id: "open",
                                    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_dialog_eo.png')
                                },
                                {
                                    id: "closed",
                                    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_dialog_ec.png')
                                }
                            ],
                            timing: Blink.timing,
                        },
                        {
                            id: 'mouth',
                            pos: { left: 1001, top: 452 },
                            layer: 2,
                            img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_dialog_mc.png'),
                            frames: [
                                {
                                    id: "closed",
                                    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_dialog_mc.png')
                                },
                                {
                                    id: "open",
                                    img: require('@/assets/images/characters/vrijwilliger/vrijwilliger_dialog_mo.png')
                                }
                            ],
                            timing: [
                                {
                                    duration: 100,
                                    frameId: "open"
                                },
                                {
                                    duration: 100,
                                    frameId: "closed"
                                },
                            ],
                        }
                    ],
                })
            ]
        }
    }
});