import CollectionItem from "@/models/CollectionItem";

/*
    INTRO
*/
export const LukasCellphone = new CollectionItem({
    id: 'lukas_cellphone',
    img: require('@/assets/images/scenes/zoro002/objects/phone/phone_bed.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/phone/phone_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/phone/phone_400x400.png'),
    name: "GSM",
    description: "Lukas heeft een grote vriendengroep. Hij hecht veel belang aan de mening van zijn vrienden.",
    hoverName: ""
});

export const MealCart = new CollectionItem({
    id: 'meal_cart',
    img: require('@/assets/images/scenes/zoro002/objects/foodcart/foodcart.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/foodcart/foodcart_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/foodcart/foodcart_400x400.png'),
    name: "Maaltijdkar",
    description: "De nieuwe eetgewoontes zijn voor Lukas een grote aanpassing. Hij moet bij elke inname van drank of voedsel nadenken over de impact op zijn lichaam.",
    hoverName: ""
});

export const FootballScarf = new CollectionItem({
    id: 'football_scarf',
    img: require('@/assets/images/scenes/zoro002/objects/scarf_footbal/scarf_footbal.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/scarf_footbal/scarf_footbal_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/scarf_footbal/scarf_footbal_400x400.png'),
    name: "Sjaal van voetbalclub",
    description: "Voetbal is Lukas zijn leven als supporter, maar ook als verdediger bij zijn club “de scheneschoppers”.",
    hoverName: ""
});

export const LukasMom = new CollectionItem({
    id: 'lukas_mom',
    img: require('@/assets/images/characters/mantelzorger_c2/mantelzorger_c2_full_body_2.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/mom_worried/mom_worried_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/mom_worried/mom_worried_400x400.png'),
    name: "Christien (mama van Lukas)",
    description: "De mama van Lukas is heel bezorgd over haar zoon. Ze denkt dat hij zonder haar hulp niet met zijn ziekte kan omgaan.",
    hoverName: ""
});

export const Pizzabox = new CollectionItem({
    id: 'pizzabox',
    img: require('@/assets/images/scenes/zoro002/objects/pizzabox/pizzabox.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/pizzabox/pizzabox_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/pizzabox/pizzabox_400x400.png'),
    name: "Pizzadoos op vuilbak",
    description: "Gisteren kwam er een vriend van Lukas langs met pizza. Nu Lukas de diagnose diabetes heeft moet hij meer verantwoordelijkheid nemen over zijn gezondheid.",
    hoverName: ""
});

export const BookRack = new CollectionItem({
    id: 'book_rack',
    img: require('@/assets/images/scenes/zoro002/objects/brochure_stand/brochure_stand.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/brochure_stand/brochure_stand_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/brochure_stand/brochure_stand_400x400.png'),
    name: "Leesrekje met info over gezonde alternatieven voor diabetici",
    description: "De eetgewoontes van Lukas zijn niet altijd gepast voor een diabetespatiënt. Lukas is verzot op chocolade en fastfood.",
    hoverName: ""
});

//decoys

export const Poster = new CollectionItem({
    id: 'poster',
    img: require('@/assets/images/scenes/zoro002/objects/poster_diabetes/poster_diabetes.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/poster_diabetes/poster_diabetes_400x400.png'),
    name: "Poster diabetes",
    description: "Informeren is belangrijk: Hoe herken je een hypo?",
    hoverName: ""
});

export const LukasWheelchair = new CollectionItem({
    id: 'lukas_wheelchair',
    img: require('@/assets/images/scenes/zoro002/objects/wheelchair/wheelchair.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/wheelchair/wheelchair_400x400.png'),
    name: "Rolstoel",
    description: "Medisch materiaal",
    hoverName: ""
});

export const OtherRoomDoor = new CollectionItem({
    id: 'other_room_door',
    img: require('@/assets/images/scenes/zoro002/objects/_doors/door_hosp_c.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/other_room/other_room_400x400.png'),
    name: "Deur",
    description: "Kamer 201",
    hoverName: ""
});

export const Deodorant = new CollectionItem({
    id: 'deodorant',
    img: require('@/assets/images/scenes/zoro002/objects/deo/deo.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/deo/deo_400x400.png'),
    name: "Lukas' Deodorant",
    description: "Fris de dag beginnen!",
    hoverName: ""
});

export const Bathrobe = new CollectionItem({
    id: 'bathrobe',
    img: require('@/assets/images/scenes/zoro002/objects/bathrobe/bathrobe.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/bathrobe/bathrobe_400x400.png'),
    name: "Badjas",
    description: "Na het douchen trek ik even mijn badjas aan.",
    hoverName: ""
});

export const Computer = new CollectionItem({
    id: 'computer',
    img: require('@/assets/images/scenes/zoro002/objects/desktop/desktop.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/desktop/desktop_400x400.png'),
    name: "Medisch dossier",
    description: "Van elke patiënt wordt een digitaal dossier bijgehouden.",
    hoverName: ""
});

export const RouteSign = new CollectionItem({
    id: 'route_sign',
    img: require('@/assets/images/scenes/zoro002/objects/sign_double/sign_double.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/sign_double/sign_double_400x400.png'),
    name: "Signalisatie ziekenhuis",
    description: "Info om op de juist plek te komen.",
    hoverName: ""
});

export const AlarmSystem = new CollectionItem({
    id: 'alarm_system',
    img: require('@/assets/images/scenes/zoro002/objects/alarm_patient/alarm_patient.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/alarm_patient/alarm_patient_400x400.png'),
    name: "Alarmsysteem",
    description: "Niet om te spelen, enkel om hulp te vragen.",
    hoverName: ""
});

export const Clock = new CollectionItem({
    id: 'clock',
    img: require('@/assets/images/scenes/zoro002/objects/clock/clock.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/clock/clock_400x400.png'),
    name: "Klok",
    description: "Klok",
    hoverName: ""
});

export const Chair = new CollectionItem({
    id: 'chair',
    img: require('@/assets/images/scenes/zoro002/objects/chair_2/chair_2.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/chair_2/chair_2_400x400.png'),
    name: "Zetel",
    description: "Om bezoek te ontvangen.",
    hoverName: ""
});

/*
    FOLLOWUP
*/

//collectables
export const KarimInsuline = new CollectionItem({
    id: 'karim_insuline',
    img: require('@/assets/images/characters/dietist/dietist_full_body_2.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/epipen/epipen_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/epipen/epipen_400x400.png'),
    name: "Diëtist met insulinepen",
    description: "",
    hoverName: "Karim met insulinepen in de hand"
});

export const BrochureDiabetes = new CollectionItem({
    id: 'brochure',
    img: require('@/assets/images/scenes/zoro002/objects/brochure_diabetes/brochure_diabetes.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/brochure_diabetes/brochure_diabetes_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/brochure_diabetes/brochure_diabetes_400x400.png'),
    name: "Brochure: mijn kind heeft diabetes",
    description: "",
    hoverName: "Brochure: mijn kind heeft diabetes"
});

export const Laptop = new CollectionItem({
    id: 'laptop',
    img: require('@/assets/images/scenes/zoro002/objects/laptop_invite/laptop_invite.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/laptop_invite/laptop_invite_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/laptop_invite/laptop_invite_400x400.png'),
    name: "Laptop met beeld: uitnodiging/event",
    description: "",
    hoverName: "Uitnodiging B-day Lukas 20"
});

//decoys

export const WallDispenser = new CollectionItem({
    id: 'wall_dispenser',
    img: require('@/assets/images/scenes/zoro002/objects/dispenser_soap/dispenser_soap.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/dispenser_soap/dispenser_soap_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/dispenser_soap/dispenser_soap_400x400.png'),
    name: "Handalcohol",
    description: "Je handen regelmatig ontsmetten is belangrijk.",
    hoverName: ""
});

export const FireAlarm = new CollectionItem({
    id: 'fire_alarm',
    img: require('@/assets/images/scenes/zoro002/objects/alarm_fire/alarm_fire.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/alarm_fire/alarm_fire_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/alarm_fire/alarm_fire_400x400.png'),
    name: "Brandalarm",
    description: "Enkel bij brand mag je hierop klikken.",
    hoverName: ""
});

export const PosterHypoglycemia = new CollectionItem({
    id: 'poster_hypoglycemia',
    img: require('@/assets/images/scenes/zoro002/objects/poster_hypo/poster_hypo.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/poster_hypo/poster_hypo_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/poster_hypo/poster_hypo_400x400.png'),
    name: "Poster symptomen hypoglycemie",
    description: "Hoe herken je een hypoglycemie?",
    hoverName: ""
});

export const PosterHyperglycemia = new CollectionItem({
    id: 'poster_hyperglycemia',
    img: require('@/assets/images/scenes/zoro002/objects/poster_hyper/poster_hyper.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/poster_hyper/poster_hyper_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/poster_hyper/poster_hyper_400x400.png'),
    name: "Poster symptomen hyperglycemie",
    description: "Hoe herken je een hyperglycemie?",
    hoverName: ""
});

export const ZoroBrochure = new CollectionItem({
    id: 'zoro_brochure',
    img: require('@/assets/images/scenes/zoro002/objects/brochure_zoro/brochure_zoro.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/brochure_zoro/brochure_zoro_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/brochure_zoro/brochure_zoro_400x400.png'),
    name: "ZORO-training",
    description: "Wil je je verder ontwikkelen als zorgverlener? Kijk in de bib voor meer informatie.",
    hoverName: ""
});

export const HospitalBed = new CollectionItem({
    id: 'hospital_bed',
    img: require('@/assets/images/scenes/zoro002/objects/bed_hospital/bed_hospital.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/bed_hospital/bed_hospital_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/bed_hospital/bed_hospital_400x400.png'),
    name: "Ziekenhuisbed",
    description: "Ik kijk ernaar uit om opnieuw in mijn eigen bed te slapen.",
    hoverName: ""
});

export const HospitalTV = new CollectionItem({
    id: 'hospital_tv',
    img: require('@/assets/images/scenes/zoro002/objects/tv_hospital/tv_hospital.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/tv_hospital/tv_hospital_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/tv_hospital/tv_hospital_400x400.png'),
    name: "Info ziekenhuis",
    description: "Via dit scherm krijgen zorgontvangers het laatste nieuws van het ziekenhuis.",
    hoverName: ""
});

export const LukasBackpack = new CollectionItem({
    id: 'lukas_backpack',
    img: require('@/assets/images/scenes/zoro002/objects/backpack/backpack.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/backpack/backpack_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/backpack/backpack_400x400.png'),
    name: "Rugzak",
    description: "Mijn rommeltas.",
    hoverName: ""
});

export const LukasToiletBag = new CollectionItem({
    id: 'lukas_toilet_bag',
    img: require('@/assets/images/scenes/zoro002/objects/toiletries/toiletries.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/toiletries/toiletries_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/toiletries/toiletries_400x400.png'),
    name: "Toiletzak",
    description: "Deze toiletzak kreeg ik van mijn zus voor mijn verjaardag. Hij heeft de kleuren van mijn favoriete voetbalploeg.",
    hoverName: ""
});

export const BathroomAlarm = new CollectionItem({
    id: 'bathroom_alarm',
    img: require('@/assets/images/scenes/zoro002/objects/alarm_button/alarm_button.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/alarm_button/alarm_button_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/alarm_button/alarm_button_400x400.png'),
    name: "Alarmknop",
    description: "Ben je in nood? Druk op de knop en de verpleging komt je helpen.",
    hoverName: ""
});

export const PosterHandHygiene = new CollectionItem({
    id: 'poster_hand_hygiene',
    img: require('@/assets/images/scenes/zoro002/objects/poster_washing_hands/poster_washing_hands.png'),
    icon: require('@/assets/images/scenes/zoro002/objects/poster_washing_hands/poster_washing_hands_120x120.png'),
    descriptionImage: require('@/assets/images/scenes/zoro002/objects/poster_washing_hands/poster_washing_hands_400x400.png'),
    name: "Instructies handhygiëne",
    description: "Hoe moet je nu weer je handen correct wassen en ontsmetten?",
    hoverName: ""
});