import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";
import {
    BookRack,
    FootballScarf,
    LukasCellphone,
    LukasMom,
    MealCart,
    Pizzabox
} from "@/assets/data/items/collection_items-ZORO-002";

export const Zoro002GoalSeekerScene = new PointAndClickScene({

    id: 'zoro-002-intro-goal_seeker',
    startLocation: 'goal_seeker',
    showPhoneButton: false,
    locations: {
        'goal_seeker': {
            items: [
                new PointAndClickItem({
                    id: 'past',
                    img: require('@/assets/images/scenes/zoro002/bg_c2_doelzoeker_01.jpg'),
                    map: "279,300 788.2,296 788.2,1139.4 492.4,1139.4 423,953.5 284.1,1001.5",
                    pos: { left: -180, top: -180 },
                    layer: 2,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    dontAnimateOnHover: true,
                    action: "click_past",
                    trigger: null,
                    name: "Verleden",
                }),
                new PointAndClickItem({
                    id: 'present',
                    img: require('@/assets/images/scenes/zoro002/bg_c2_doelzoeker_02.jpg'),
                    map: "0,304 606.7,301 606.7,1144.4 0.7, 1144.4",
                    pos: { left: 609, top: -180 },
                    layer: 2,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    dontAnimateOnHover: true,
                    action: "click_present",
                    trigger: null,
                    name: "Heden",
                }),
                new PointAndClickItem({
                    id: 'future',
                    img: require('@/assets/images/scenes/zoro002/bg_c2_doelzoeker_03.jpg'),
                    map: "0,290 540.2,289.6 540.2,1133 0,1133",
                    pos: { left: 1215, top: -180 },
                    layer: 2,
                    interactable: true,
                    itemType: "action",
                    hoverType: "talk",
                    dontAnimateOnHover: true,
                    action: "click_future",
                    trigger: null,
                    name: "Toekomst",
                }),

                //objects
                new PointAndClickItem({
                    id: `goal-${LukasCellphone.id}`,
                    img: LukasCellphone.icon,
                    pos: { left: 1050, top: 500 },
                    layer: 10,
                    subtitle: LukasCellphone.name,
                    goalSeekerTime: 'present',

                }),
                new PointAndClickItem({
                    id: `goal-${MealCart.id}`,
                    img: MealCart.icon,
                    pos: { left: 1500, top: 300 },
                    layer: 10,
                    subtitle: MealCart.name,
                    goalSeekerTime: 'future',

                }),
                new PointAndClickItem({
                    id: `goal-${FootballScarf.id}`,
                    img: FootballScarf.icon,
                    pos: { left: 250, top: 300 },
                    layer: 10,
                    subtitle: FootballScarf.name,
                    goalSeekerTime: 'past',
                }),
                new PointAndClickItem({
                    id: `goal-${LukasMom.id}`,
                    img: LukasMom.icon,
                    pos: { left: 750, top: 250 },
                    layer: 10,
                    subtitle: LukasMom.name,
                    goalSeekerTime: 'present',
                }),
                new PointAndClickItem({
                    id: `goal-${Pizzabox.id}`,
                    img: Pizzabox.icon,
                    pos: { left: 1300, top: 700 },
                    layer: 10,
                    subtitle: Pizzabox.name,
                    goalSeekerTime: 'future',
                }),
                new PointAndClickItem({
                    id: `goal-${BookRack.id}`,
                    img: BookRack.icon,
                    pos: { left: 350, top: 600 },
                    layer: 10,
                    subtitle: BookRack.name,
                    goalSeekerTime: 'past',
                }),
            ],
        }
    },
});