import { ref, watch, computed } from "vue";
import { useCookie } from "vue-cookie-next";

const allowCookies = ref(false);
const COOKIES_CONSENT_NAME = "cookies_consent";

export default function useCookies(gtag)
{
    const cookie = useCookie();

    if (cookie.isCookieAvailable(COOKIES_CONSENT_NAME))
    {
        allowCookies.value = cookie.getCookie(COOKIES_CONSENT_NAME) === 'true';
        gtag.optIn();
    }
    else
    {
        allowCookies.value = undefined;
    }

    watch(allowCookies, () =>
    {
        console.log(`Watching allowCookies ${allowCookies.value}`);
        if (allowCookies.value != undefined)
        {
            const expirationDate = new Date();
            expirationDate.setMonth(expirationDate.getMonth() + 6);

            cookie.setCookie(COOKIES_CONSENT_NAME, allowCookies.value.toString(), {
                expire: expirationDate
            });
            if (allowCookies.value)
            {
                gtag.optIn();
            }
            else
            {
                gtag.optOut();
            }
        }
    });

    const showBanner = computed(() =>
    {
        return allowCookies.value === undefined;
    });

    const acceptClicked = () => (allowCookies.value = true);
    const rejectClicked = () => (allowCookies.value = false);

    const removeAllCookies = () =>
    {
        cookie.keys().forEach(c => cookie.removeCookie(c));
    }

    return {
        allowCookies,
        showBanner,
        acceptClicked,
        rejectClicked,
        removeAllCookies
    };
}