import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";

export const Zoro001IntroInitialHomeScene = new PointAndClickScene({

    id: 'zoro-001-intro-initial_home',
    startLocation: 'outside',
    showPhoneButton: true,
    locations: {
        'outside': {
            items: [
                new PointAndClickItem({
                    id: 'bg-exterior',
                    img: require('@/assets/images/scenes/zoro001/bg_exterior.png'),

                    pos: { left: -180, top: -180 },
                    layer: 1,

                }),
                new PointAndClickItem({
                    id: 'doorbell',
                    img: require('@/assets/images/scenes/zoro001/objects/_case1_general/doorbel.png'),
                    sound: require('@/assets/sounds/doorbell.mp3'),

                    pos: { left: 584, top: 465 },
                    layer: 2,

                    interactable: true,
                    moveLocation: "entrance",
                    itemType: "action",
                    hoverType: "use",
                    action: "press_doorbell",
                    trigger: null,
                    name: "Bel aan",
                }),
            ],
        }
    },
});