import PointAndClickScene from "@/models/PointAndClickScene";
import PointAndClickItem from "@/models/PointAndClickItem";
import PointAndClickCharacter from "@/models/PointAndClickCharacter";
import {
    AlarmSystem,
    Chair,
    HospitalBed, HospitalTV, Laptop, LukasBackpack,
} from "@/assets/data/items/collection_items-ZORO-002";
import { Blink } from "@/assets/data/animations/animations";

const bgPosDeltaX = 180;
const bgPosDeltaY = 180;

export const Zoro002FollowupHospitalRoomTalkRecipientScene = new PointAndClickScene({

        id: 'zoro-002-followup-hospital_room_talk_recipient_scene',
        startLocation: 'room',
        showPhoneButton: false,
        locations: {
            'room': {
                items: [
                    new PointAndClickItem({
                        id: 'bg',
                        img: require('@/assets/images/scenes/zoro002/bg_hospitalroom.png'),
                        pos: { left: -bgPosDeltaX, top: -bgPosDeltaY },
                        layer: 1,
                    }),
                    new PointAndClickItem({
                        id: HospitalBed.id,
                        img: HospitalBed.img,
                        pos: { left: 979 - bgPosDeltaX, top: 623 - bgPosDeltaY },
                        layer: 1,
                    }),
                    new PointAndClickItem({
                        id: 'roomcart',
                        img: require('@/assets/images/scenes/zoro002/objects/_general/roomcart.png'),
                        pos: { left: 742 - bgPosDeltaX, top: 827 - bgPosDeltaY },
                        layer: 1
                    }),
                    new PointAndClickItem({
                        id: AlarmSystem.id,
                        img: AlarmSystem.img,
                        pos: { left: 977 - bgPosDeltaX, top: 718 - bgPosDeltaY },
                        layer: 2,
                    }),
                    new PointAndClickItem({
                        id: Chair.id,
                        img: Chair.img,
                        pos: { left: 86 - bgPosDeltaX, top: 942 - bgPosDeltaY },
                        layer: 2,
                    }),
                    new PointAndClickItem({
                        id: 'flowers',
                        img: require('@/assets/images/scenes/zoro002/objects/_general/flowers.png'),
                        pos: { left: 865 - bgPosDeltaX, top: 708 - bgPosDeltaY },
                        layer: 1
                    }),
                    new PointAndClickItem({
                        id: Laptop.id,
                        img: Laptop.img,
                        pos: { left: 752 - bgPosDeltaX, top: 750 - bgPosDeltaY },
                        layer: 2,
                    }),
                    new PointAndClickItem({
                        id: LukasBackpack.id,
                        img: LukasBackpack.img,
                        pos: { left: 829 - bgPosDeltaX, top: 1004 - bgPosDeltaY },
                        layer: 2,
                    }),
                    new PointAndClickItem({
                        id: HospitalTV.id,
                        img: HospitalTV.img,
                        pos: { left: 1812 - bgPosDeltaX, top: 223 - bgPosDeltaY },
                        layer: 1
                    }),
                ],
                characters: [
                    new PointAndClickCharacter({
                        id: 'lukas',
                        layer: 5,
                        imgElements: [
                            {
                                id: 'lukas_bg',
                                img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_dialog_body.png'),
                                pos: { left: 600, top: 160 },
                                frames: [
                                    {
                                        id: 'lukas',
                                        img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_dialog_body.png')
                                    }
                                ],
                                timing: {
                                    "0": "lukas",
                                }
                            },
                            {
                                id: 'eyes',
                                pos: { left: 998, top: 358 },
                                layer: 2,
                                img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_dialog_eo.png'),
                                frames: [
                                    {
                                        id: "open",
                                        img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_dialog_eo.png')
                                    },
                                    {
                                        id: "closed",
                                        img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_dialog_ec.png')
                                    }
                                ],
                                timing: Blink.timing,
                            },
                            {
                                id: 'mouth',
                                pos: { left: 1031, top: 482 },
                                layer: 2,
                                img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_dialog_mc.png'),
                                frames: [
                                    {
                                        id: "closed",
                                        img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_dialog_mc.png')
                                    },
                                    {
                                        id: "open",
                                        img: require('@/assets/images/characters/zorgontvanger_c2/zorgontvanger_c2_dialog_mo.png')
                                    }
                                ],
                                timing: [
                                    {
                                        duration: 100,
                                        frameId: "open"
                                    },
                                    {
                                        duration: 100,
                                        frameId: "closed"
                                    },
                                ],
                            }
                        ],
                    }),
                ],
            },

        },

    })
;