// import CaseFile from "@/models/CaseFile";

const cases = [];

function importAll(r)
{
    // r.keys().forEach((key) => (cases[key] = new CaseFile(r(key))));
    r.keys().forEach((key) => (cases[key] = r(key).casefile));
}

console.log(`Importing all case files`);

// importAll(require.context("@/assets/data/cases", false, /ZORO-[0-9]+\.json$/));
importAll(require.context("@/assets/data/cases", false, /ZORO-[0-9]+\.js$/));

const state = () => ({
    cases: Object.values(cases)
});

const getters = {
    listAll(state)
    {
        return state.cases;
    },
    getCaseById: (state) => (caseId) =>
    {
        return state.cases.find(c => c.id === caseId);
    }
};

const actions = {};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};