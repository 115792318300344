import GameScore from "@/models/GameScore";

export default class SaveState
{
    constructor(options)
    {
        options = options ?? {};

        this.playerName = options.playerName;

        this.score = options.score;

        this.savedCaseInfo = options.savedCaseInfo;
        this.savedStepInfo = options.savedStepInfo;
        this.savedPhaseInfo = options.savedPhaseInfo;

        this.savedCareTeam = options.savedCareTeam || [];
        this.savedAttemptedCareTeam = options.savedAttemptedCareTeam || [];

        this.savedTechnologicalAids = options.savedTechnologicalAids || [];
        this.savedAttemptedTechnologicalAids = options.savedAttemptedTechnologicalAids || [];

        this.finished = options.finished;
    }

    get score()
    {
        return this._score;
    }

    set score(gameScore)
    {
        this._score = new GameScore(gameScore);
    }

    stringify()
    {
        return JSON.stringify(this._export());
    }
    _export()
    {
        return {
            playerName: this.playerName,
            score: this.score,
            savedCaseInfo: this.savedCaseInfo,
            savedStepInfo: this.savedStepInfo,
            savedPhaseInfo: this.savedPhaseInfo,
            savedCareTeam: this.savedCareTeam,
            savedAttemptedCareTeam: this.savedAttemptedCareTeam,
            savedTechnologicalAids: this.savedTechnologicalAids,
            savedAttemptedTechnologicalAids: this.savedAttemptedTechnologicalAids,
            finished: this.finished
        };
    }

    static _constructFromObject(object)
    {
        return new SaveState(object);
    }
}